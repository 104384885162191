import React from "react";
import "./styles.css";
import { Button, Modal } from "react-bootstrap";
import { commonStr } from "../../utils/constants/commonStrings";
import CommonInput from "../commonInput";
function CommonShareWhatsApp({
  show,
  onSubmit,
  closeShareModal,
  formData,
  handleInputChange,
}) {
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      {" "}
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title id="contained-modal-title-vcenter">
            {commonStr.share}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonInput
            className="w-100 sendNumberInput"
            placeholder={commonStr.number}
            onChange={handleInputChange}
            value={formData.number}
            name="number"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeShareModal}>
            {commonStr.cancel}
          </Button>
          <button className="btn btn-success">{commonStr.send}</button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default CommonShareWhatsApp;
