import React from "react";
import { Comment } from "react-loader-spinner";
import "./styles.css";
import { commonStr } from "../../utils/constants/commonStrings";
function CommonGenerateBtn({ title = commonStr.generating }) {
  return (
    <div className="generateBtnContainer">
      {title}
      <Comment
        visible={true}
        height="30"
        width="30"
        ariaLabel="comment-loading"
        wrapperStyle={{}}
        wrapperClass="comment-wrapper"
        color="white"
        backgroundColor="#4C85F7"
      />
    </div>
  );
}

export default CommonGenerateBtn;
