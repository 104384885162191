import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  feedBack,
  sendEmail,
  sendDetailInWhatsApp,
  dataDectectivePromptApi,
} from "../../api/Postaction";
import { postUrl } from "../../api/Endpoint";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../context/ContextApi";

import { showToast } from "../../utils/helpers/helpers";
import {
  ChatBot,
  CommonFeedback,
  CommonGenerateBtn,
  CommonLoader,
  CommonPromChat,
  CommonSendMail,
  CommonShareWhatsApp,
  SearchInputArrowBtn,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import axiosApi from "../../interceptors/axiosinterceptor";
import { errorStr } from "../../utils/constants/errorStrings";
import uuid from "react-uuid";

const ExcistingDocument = () => {
  const { detectivePersonaVal, detectivePersonaTab } = useContext(MyContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [shareModal, setShareModal] = useState(false);
  const [numberModal, setNumberModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let state = location.state;
  const [RejectModal, setRejectModal] = useState(false);
  const [RejectAnswer, setRejectAnswer] = useState();
  const [inputValue, setInputValue] = useState("");
  const [generating, setGenerating] = useState(false);
  const [shareDetails, setShareDetails] = useState();
  const [sendNumber, setSendNumber] = useState();
  const [data, setData] = useState([]);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [currentIdx, setCurrentIdx] = useState("");
  const [audio, setAudio] = useState([]);
  const [formData, setFormData] = useState({
    notes: "",
    emails: "",
  });
  const [formNumberData, setFormNumberData] = useState({
    number: "",
    text: "",
  });
  let userEmail = sessionStorage.getItem("email");

  let currentPath = location.pathname.split("/")[2];

  if (currentPath.includes("%20")) {
    currentPath = currentPath.replace("%20", " ");
  }

  const share = async (e) => {
    setShareModal(false);
    e.preventDefault();
    let res = new FormData(e.target);
    let value = [...res.entries()];
    let recipientsemail = value[1][1];
    let body = {
      Note: value[0][1],
      recipients: recipientsemail.split(","),
      insight: shareDetails.AI,
      file_path: audio[currentIdx].audio,
      sender: userEmail,
    };
    await sendEmail(body);
    setShareDetails();
  };
  const closeShareModal = () => {
    setShareModal(false);
    setShareDetails();
  };
  useEffect(() => {
    if (shareDetails !== undefined) {
      setFormData({
        notes: "",
        emails: "",
      });
      setShareModal(true);
    }
  }, [shareDetails]);

  // send response in whatsApp
  const shareToWhatsApp = async (e) => {
    setNumberModal(false);
    e.preventDefault();
    let body = {
      recipient_id: formNumberData.number,
      text: sendNumber.user + "\n" + sendNumber.AI,
    };
    await sendDetailInWhatsApp(body);
    setSendNumber();
  };
  const closePhoneNumberModal = () => {
    setNumberModal(false);
    setSendNumber();
  };
  useEffect(() => {
    if (sendNumber !== undefined) {
      setFormNumberData({
        number: "",
        text: "",
      });
      setNumberModal(true);
    }
  }, [sendNumber]);

  const history = async () => {
    setLoading(true);
    try {
      setData([]);
      if (state === null) {
        let chat = await axiosApi.post(`${postUrl.dataDetectiveChatHistory}`, {
          email_id: userEmail,
          session_id: currentPath,
          persona_name: detectivePersonaVal,
        });
        setLoading(false);
        let a = chat.data;
        let b = a.map((e, index) => ({
          id: index + 1,
          user: e.user,
          AI: e.AI,
          thumbsUp: e.Feedback === "thumbs-up" ? true : false,
          thumbsDown: e.Feedback === "thumbs-down" ? true : false,
        }));

        setData(b);
      } else {
        setData([]);
        setLoading(false);
        if (state.documentsessionslist.includes(state.sessionId)) {
          showToast(errorStr.sessionNameAlready);
          navigate("/dataDetective");
        }
        setData([]);
      }
    } catch (err) {
      console.log("Error in getting Accessable Table", err);
    }
  };
  useEffect(() => {
    history();
  }, [currentPath, state]);

  const generatePrompt = async () => {
    if (inputValue === "") {
      showToast(errorStr.pleaseEnterQuery);
    } else {
      setInputValue("");
      setGenerating(true);
      let body = {
        question: inputValue,
        email_id: userEmail,
        session_id: currentPath,
        persona_name: detectivePersonaVal,
        persona_tables: detectivePersonaTab,
      };

      try {
        let a = await dataDectectivePromptApi(body);
        if (a !== undefined) {
          setData((prev) => [
            ...prev,
            {
              id: data.length + 1,
              user: inputValue,
              AI: a?.output,
              thumbsUp: false,
              thumbsDown: false,
            },
          ]);
        }

        setGenerating(false);
      } catch (err) {
        console.log("error on generating prompt", err);
      }
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };

  const handleThumbsUpClick = async (index) => {
    setRejectAnswer();
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsUp = !newThumbsStates[index].thumbsUp;
    newThumbsStates[index].thumbsDown = false; // Reset thumbs down
    setData(newThumbsStates);
    let a = data[index];
    let body = {
      message_id: uuid(),
      question: a.user,
      response: a.AI,
      feedback: "thumbs-up",
      email_id: userEmail,
      session_id: currentPath,
    };
    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };

  const handleThumbsDownClick = (e, index) => {
    let msgId = uuid();
    let mergeData = {
      ...e,
      Message_Id: msgId,
    };
    setRejectModal(true);
    setRejectAnswer(mergeData);
    setFeedbackInput("");
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsDown = !newThumbsStates[index].thumbsDown;
    newThumbsStates[index].thumbsUp = false; // Reset thumbs up
    setData(newThumbsStates);
  };
  const closeRejectModal = () => {
    let a = data.filter((e) => e.user === RejectAnswer.user)[0];
    const indexOfObjectToUpdate = data.findIndex(
      (item) => item.user === a.user
    );
    if (indexOfObjectToUpdate !== -1) {
      data[indexOfObjectToUpdate].thumbsUp = false;
      data[indexOfObjectToUpdate].thumbsDown = false;
    }
    setRejectAnswer();
    setRejectModal(false);
  };
  const submitRejectModal = async (e) => {
    e.preventDefault();
    let body = {
      message_id: RejectAnswer.Message_Id,
      question: RejectAnswer.user,
      response: RejectAnswer.AI,
      feedback: "thumbs-down",
      comment: feedbackInput,
      email_id: userEmail,
      session_id: currentPath,
    };
    setRejectAnswer();
    setRejectModal(false);
    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };

  //handleToChangeTextArea
  const handleChangeTextArea = (e) => {
    setFeedbackInput(e.target.value);
  };
  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    setFormNumberData({
      ...formNumberData,
      [name]: value,
    });
  };

  return (
    <div className="d-flex justify-content-between postionRelative">
      <div className="mainContent w-100">
        {data?.length > 0 ? (
          <div className="prompt">
            {data &&
              data?.length > 0 &&
              data?.map((e, i) => (
                <CommonPromChat
                  indexValue={i}
                  key={i + 1}
                  user={e.user}
                  AI={e.AI}
                  thumbsUp={data[i].thumbsUp}
                  thumbsDown={data[i].thumbsDown}
                  onThumbsUpClick={() => handleThumbsUpClick(i)}
                  onThumbsDownClick={() => handleThumbsDownClick(e, i)}
                  onShareClick={() => {
                    setShareDetails(e);
                    setCurrentIdx(i);
                  }}
                  onShareNumberClick={() => {
                    setSendNumber(e);
                  }}
                  audioVisble={false}
                  isDashboardSplit
                />
              ))}
          </div>
        ) : (
          <>
            {loading ? (
              <CommonLoader />
            ) : (
              <ChatBot userName={userEmail?.split("@")[0]} />
            )}
          </>
        )}
        {generating && <CommonGenerateBtn title={commonStr.generating} />}

        <SearchInputArrowBtn
          value={inputValue}
          placeholder={commonStr.typeYourQuery}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          inputValue={inputValue}
          onClick={generatePrompt}
          audioHide={false}
        />

        {shareModal && (
          <CommonSendMail
            show={shareModal}
            onSubmit={share}
            closeShareModal={closeShareModal}
            handleInputChange={handleInputChange}
            formData={formData}
          />
        )}
        {numberModal && (
          <CommonShareWhatsApp
            show={numberModal}
            onSubmit={shareToWhatsApp}
            closeShareModal={closePhoneNumberModal}
            handleInputChange={handleInputNumberChange}
            formData={formNumberData}
          />
        )}

        {RejectModal && (
          <CommonFeedback
            show={RejectModal}
            onSubmit={submitRejectModal}
            onchangeTextArea={handleChangeTextArea}
            onchangeTextAreaValue={feedbackInput}
            closeRejectModal={closeRejectModal}
          />
        )}

        {/* For Reject Message */}
      </div>
    </div>
  );
};

export default ExcistingDocument;
