import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./styles.css";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: "200px",
    cursor: "pointer",
    ":hover": {
      cursor: "pointer",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer", // Set cursor to pointer on hover
    backgroundColor: "red",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
function CommonSelectDrop({
  className,
  placeholder = "",
  onchangeGetValue,
  getData,
}) {
  const [selectValue, setSelectValue] = useState("");
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    if (getData) {
      let mappedData = getData?.list_of_diseases?.map((item) => ({
        value: item,
        label: item,
      }));
      setSelectOptions(mappedData);
    }
  }, [getData]);

  const handleDropdownChange = (selected) => {
    setSelectValue(selected);
    // onchangeGetValue(selected.value);
  };
  return (
    <Select
      className={`${className} diseaseDropContainer iconHover`}
      placeholder={placeholder}
      value={selectValue}
      onChange={handleDropdownChange}
      options={selectOptions}
      styles={customStyles}
      autoFocus
    />
  );
}

export default CommonSelectDrop;
