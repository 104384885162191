import React, { useContext, useEffect, useState } from "react";
import "./GenInspector.scss";
import { MyContext } from "../../context/ContextApi";
import CommonLoader from "../../common/commonLoader";
import { ChatBot, CommonBtn, CommonExportBtn } from "../../common";
import exportFromJSON from "export-from-json";
import { saveAs } from "file-saver";
import { getGeneData } from "../../api/GetActions";
import { toast } from "react-toastify";

//geneinspector
const GenInspector = () => {
  const {
    filterContent,
    commonLoading,
    isGeneTableClear,
    isSubmitGeneIns,
    stopLoading,
    isApiCallLength,
    setIsSubmitGeneIns,
  } = useContext(MyContext);
  const [Loading, setLoading] = useState();
  const [tableData, setTableData] = useState([]);
  const [showFullTextMap, setShowFullTextMap] = useState({});

  const fileName = "gene_info";
  const exportType = exportFromJSON.types.csv;

  const geneSessionId = sessionStorage.getItem("geneSessionId");

  const handleClickCsv = () => {
    const newData = tableData.map(({ id, session_id, ...rest }) => rest);
    exportFromJSON({ data: newData, fileName, exportType });
  };

  const downloadAsTsv = () => {
    const newData = tableData.map(({ id, session_id, ...rest }) => rest);
    const columnHeaders = Object.keys(newData[0]);
    const tsvContent = [
      columnHeaders.join("\t"), // First line with column headers
      ...newData.map((item) => Object.values(item).join("\t")), // Data lines
    ].join("\n");
    const blob = new Blob([tsvContent], {
      type: "text/tab-separated-values;charset=utf-8",
    });
    saveAs(blob, `${"gene_info"}.tsv`);
  };

  useEffect(() => {
    const maxRetries = 10;
    let retryCount = 0;
    let intervalId;

    const fetchGeneDataWithRetry = async () => {
      // startLoading(true);
      let retryData = await getGeneData(geneSessionId);

      if (!retryData) {
        retryCount++;
        if (retryCount < maxRetries) {
        } else {
          clearInterval(intervalId); // Clear the interval if maxRetries is reached
          stopLoading(false);
        }
      } else {
        const updateIdList = retryData?.TableList.map((gene) => ({
          ...gene,
          id: gene.Gene_Name,
        }));
        setTableData(updateIdList);
        stopLoading(false);

        if (isApiCallLength === retryData?.TableList?.length) {
          toast.success(`Data Fetch Successfully!!!`);

          retryData?.TableList.sort((a, b) => {
            if (a.Summary === "N/A" && b.Summary !== "N/A") {
              return 1;
            } else if (a.Summary !== "N/A" && b.Summary === "N/A") {
              return -1;
            } else {
              // If both entries have the same type of Summary or both are "N/A",
              // sort first by the number of publications
              if (b.No_of_publications !== a.No_of_publications) {
                return b.No_of_publications - a.No_of_publications;
              } else {
                // If the number of publications is the same, sort by gene name
                return a.Gene_Name.localeCompare(b.Gene_Name);
              }
            }
          });
          const rankedGenes = retryData?.TableList.map((gene, index) => ({
            ...gene,
            rank: gene.Summary === "N/A" ? "-" : index + 1,
            id: gene.Gene_Name,
          }));
          setTableData(rankedGenes);

          clearInterval(intervalId);
        }
      }
    };

    if (isSubmitGeneIns && geneSessionId) {
      // Start the interval outside of the retry logic
      intervalId = setInterval(fetchGeneDataWithRetry, 15000);
    }

    // Cleanup function for componentWillUnmount
    return () => {
      clearInterval(intervalId);
    };
  }, [isSubmitGeneIns, geneSessionId]);

  useEffect(() => {
    // Cleanup function
    return () => {
      setIsSubmitGeneIns(false);
      sessionStorage.setItem("geneSessionId", "");
      stopLoading(false);
    };
  }, []);

  const handleRefresh = async () => {
    let retryData = await getGeneData(geneSessionId);
    if (retryData) {
      setTableData(retryData?.TableList);
    }
  };

  useEffect(() => {
    if (tableData && tableData.length > 0) {
      setTableData([]);
    }
  }, [isGeneTableClear]);

  useEffect(() => {
    setLoading(commonLoading);
  }, [commonLoading]);

  const toggleShowMore = (itemId) => {
    setShowFullTextMap((prev) => ({
      ...prev,
      [itemId]: !prev[itemId] || false,
    }));
  };

  // Function to replace array elements with hyperlinks
  function replaceWithLinks(match) {
    let array = match.slice(1, -1).split(/\s*,\s*/); // Remove brackets and split the string
    let replacedArray = array.map((element) => {
      if (element.startsWith("PMID: ")) {
        // Remove "PMID: " from the display text while keeping it in the URL
        let pmid = element.replace("PMID: ", "");
        return `<a href="https://pubmed.ncbi.nlm.nih.gov/${pmid}/" target="_blank" rel="noopener noreferrer" >PMID: ${pmid}</a>`;
      } else {
        return `<a href=https://pubmed.ncbi.nlm.nih.gov/${element}/ target="_blank" rel="noopener noreferrer">${element}</a>`;
      }
    });
    return "[" + replacedArray.join(",") + "]";
  }

  return (
    <div className="genewrap">
      <div className="genewrap__container">
        {Loading ? (
          <CommonLoader
            visible={Loading}
            customLoaderClass="loaderCenter bgwhite"
          />
        ) : (
          <>
            {tableData && tableData?.length > 0 ? (
              <div className="genewrap__prompt margin15px">
                <div className="genewrap__prompt__btnwrap mt-2 mr-5">
                  {isApiCallLength !== tableData?.length && (
                    <CommonBtn
                      title={"Refresh"}
                      onClick={handleRefresh}
                      className="mr-2 genewrap__prompt__btnwrap__refresh iconHover"
                    />
                  )}

                  <CommonExportBtn
                    handleClickCsv={handleClickCsv}
                    downloadAsTsv={downloadAsTsv}
                  />
                </div>
                {tableData?.length > 0 && (
                  <div className="genewrap__prompt__table">
                    <div
                      className={`row genewrap__prompt__table__table-header top50px`}
                    >
                      <div className="col-2 text-center">
                        <div className="row">
                          <div className="col-12 pl-0">
                            <p>Gene Name</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-1 text-center paddingLeft3px">
                        <p>Rank</p>
                      </div>
                      <div className="col-6 text-center">
                        <p>Summary</p>
                      </div>
                      <div className="col-3 text-center">
                        <p>No. of publications</p>
                      </div>
                    </div>

                    {tableData?.map((item) => {
                      return (
                        <div
                          key={item?.id}
                          className="genewrap__prompt__table__table-list"
                        >
                          <div className="row padding10">
                            <div className="col-12">
                              <div className="row  ">
                                <div className="col-2 text-center">
                                  <div className="row">
                                    <div className="col-12 marginTop2px">
                                      <p
                                        data-tooltip-content={item.Gene_Name}
                                        data-tooltip-id="article"
                                        className="genewrap__font-bold"
                                      >
                                        {item?.Gene_Name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-1 text-center">
                                  <p
                                    data-tooltip-content={item?.rank}
                                    data-tooltip-id="article"
                                    className="genewrap__font-bold"
                                  >
                                    {item?.rank ? item?.rank : "-"}
                                  </p>
                                </div>
                                <div className="col-6">
                                  <p
                                    className={`fontSize0-9 genewrap__desc-color ${
                                      item?.Summary &&
                                      item?.Summary === "N/A" &&
                                      "text-center"
                                    }`}
                                  >
                                    {item?.Summary &&
                                    item?.Summary?.length > 0 ? (
                                      showFullTextMap[item.id] ? (
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: item?.Summary.replace(
                                              /\[.*?\]/g,
                                              replaceWithLinks
                                            ),
                                          }}
                                        />
                                      ) : (
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item?.Summary.slice(
                                                0,
                                                280
                                              ).replace(
                                                /\[.*?\]/g,
                                                replaceWithLinks
                                              ) + "...",
                                          }}
                                        />
                                      )
                                    ) : (
                                      "No Abstract Found"
                                    )}
                                  </p>

                                  {item?.Summary &&
                                    item?.Summary?.length > 120 && (
                                      <p
                                        className="genewrap__showmore"
                                        onClick={() => toggleShowMore(item.id)}
                                      >
                                        {showFullTextMap[item.id]
                                          ? "Show Less"
                                          : "Show More"}
                                      </p>
                                    )}
                                </div>
                                <div className="col-3 text-center">
                                  <p
                                    data-tooltip-content={
                                      item.No_of_publications
                                    }
                                    data-tooltip-id="my-tooltip"
                                  >
                                    {item.No_of_publications}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <ChatBot userName={filterContent?.userName} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GenInspector;
