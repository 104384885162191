import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./Files.css";
import { bucketList } from "../../api/Postaction";
import { Table, Pagination } from "rsuite";
import { MyContext } from "../../context/ContextApi";
import delete1 from "../../assets/svg/delete.svg";
import { deleteSingleFile } from "../../api/DeleteAction";
import { toast } from "react-toastify";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { CommonInput } from "../../common";

const ListFiles = () => {
  const { allValues, isFileUploadStatus } = useContext(MyContext);
  const location = useLocation();
  let currentPath = location.pathname.substring(1);
  let bucket = currentPath.split("/")[1];
  let folder = currentPath.split("/")[2];
  const [Loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState([]);
  const { Column, HeaderCell, Cell } = Table;

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
    if (search !== "") {
      setSearch("");
    }
  };
  const getBucket = async () => {
    let body = {
      bucket_name: allValues?.bucket,
      folder_name: allValues?.folder,
    };

    if (folder === "folder") {
      delete body.folder_name;
    }
    let a = await bucketList(body);
    if (a === "The bucket is empty or doesn't exist.") {
      setData([]);
    } else {
      setData(a);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (currentPath === "seeFiles") {
      setData([]);
      setLoading(true);
      setPage(1);
      getBucket();
    }
  }, [allValues, isFileUploadStatus]);

  useEffect(() => {
    if (search !== "") {
      // const start = limit * (page - 1);
      // const end = start + limit;
      const result = data?.filter((data) => {
        return data?.Key?.toLowerCase().indexOf(search?.toLowerCase()) >= 0;
      });
      setRecords(result);
    } else {
      const res = data?.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
      setRecords(res);
      // setLimit(result.length);
    }
  }, [search]);
  useEffect(() => {
    const res = data?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    setRecords(res);
    // }
  }, [data, page, limit]);

  const handleToClickDelete = async (data) => {
    setLoading(true);
    let payload = {
      file_name: data.Key,
      bucket_name: allValues?.bucket,
      collection_name: allValues?.collection,
    };

    let deleteResult = await deleteSingleFile(payload);
    if (deleteResult?.status === 200) {
      getBucket();
      setLoading(false);
      toast.success(`${data.Key} "Deleted Successfully!!!"`);
    } else {
      setLoading(false);
    }

    // alert(JSON.stringify(deleteResult));
  };

  return (
    <div className="list">
      <div className="bucket ml-2">
        <span>Bucket Name :</span>
        <div className="bucketTitle">{allValues?.bucket}</div>
      </div>
      <div className="adminTable1 mt-3">
        <div className="d-flex margin10px">
          <CommonInput
            type="text"
            className="ml-2 adminSearch width40"
            placeholder="🔎  Search Files"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="mt-3">
          <Table
            loading={Loading ? true : false}
            wordWrap="break-word"
            className="custom-table"
            virtualized
            hover={false}
            height={414}
            data={records}
            style={{
              scrollbarWidth: "0",
            }}
            align="center"
          >
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">File Name</HeaderCell>
              <Cell dataKey="Key" id="content" />
            </Column>

            <Column flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header">Updated On</HeaderCell>
              <Cell dataKey="Updated On" id="content" />
            </Column>

            <Column flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header">Delete</HeaderCell>
              <Cell style={{ padding: "6px", marginLeft: "6px" }} id="content">
                {(rowData) => (
                  <>
                    <img
                      src={delete1}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleToClickDelete(rowData)}
                    />
                  </>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: "20px 0px 10px 45px" }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={search === "" ? data?.length : records?.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListFiles;
