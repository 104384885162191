import React from "react";
import "./styles.css";
const CommonTextArea = ({
  type = "text",
  name = "notes",
  placeholder = "Note",
  rows = 5,
  cols = 48,
  value,
  onChange,
  required = true,
  className = "",
  disabled = false,
  id = "",
}) => {
  return (
    <textarea
      type={type}
      name={name}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      value={value}
      onChange={onChange}
      required={required}
      className={` ${className} textAreaInput`}
      disabled={disabled}
      id={id}
    />
  );
};

export default CommonTextArea;
