// CommonLoader.js

import React from "react";
import { RotatingLines } from "react-loader-spinner";

const CommonLoader = ({
  customLoaderClass,
  visible = true,
  strokeColor = "grey",
  width = "25",
  strokeWidth = "5",
  animationDuration = "0.5",
}) => {
  return (
    <div
      className={`loaderContainer displayFlexCenter h-100  ${customLoaderClass} `}
    >
      <RotatingLines
        strokeColor={strokeColor}
        strokeWidth={strokeWidth}
        animationDuration={animationDuration}
        width={width}
        visible={visible}
      />
    </div>
  );
};

export default CommonLoader;
