import React, {useContext} from "react";
import "./productExpiry.css";
import {ChatBot, SearchInputArrowBtn } from "../../common";
import { MyContext } from "../../context/ContextApi";

function ProductExpiry() {
    const { filterContent } = useContext(MyContext)
  return (
    <div className="mainContent w-100">
      {/* <div className="line-product-expiry" /> */}
      <ChatBot userName={filterContent?.userName} />
      <SearchInputArrowBtn />
    </div>
  );
}

export default ProductExpiry;
