import React from "react";
import "./styles.scss";
import { IoIosSearch } from "react-icons/io";

function CommonInput({
  className = "",
  type = "text",
  placeholder = "",
  onChange = () => {},
  required = true,
  value = null,
  accept,
  fileUpload = false,
  name = "",
  ref,
  key,
  searchInput = false,
  customInputClass = "",
}) {
  return (
    <>
      {!fileUpload ? (
        <div className={`input-search-btn ${customInputClass}`}>
          <input
            className={className}
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            value={value}
            accept={accept}
            name={name}
            multiple={fileUpload}
          />
          {searchInput && (
            <div className="input-search-btn__icon">
              <IoIosSearch color="white" />
            </div>
          )}
        </div>
      ) : (
        <input
          className={className}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          required={required}
          accept={accept}
          value={value}
          name={name}
          multiple={fileUpload}
          ref={ref}
          key={key}
        />
      )}
    </>
  );
}

export default CommonInput;
