import React from "react";
import { commonStr } from "../../utils/constants/commonStrings";
import "./styles.scss";
const CommonClearBtn = ({ isVisible, customClass = "", onClick }) => {
  return (
    isVisible && (
      <div className={`clear-btn iconHover ${customClass}`} onClick={onClick}>
        {commonStr.clear}
      </div>
    )
  );
};

export default CommonClearBtn;
