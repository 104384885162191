import React, { useContext, useEffect, useState } from "react";
import { Table, Pagination } from "rsuite";
import Modal from "react-bootstrap/Modal";
import edit from "../../assets/svg/edit.svg";
import delete1 from "../../assets/svg/delete.svg";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createCollectionValidaton } from "../../validation";
import { getUrl } from "../../api/Endpoint";
import {
  createCollections,
  deleteCollections,
  deleteDocumentAll,
} from "../../api/Postaction";
import EditCollection from "./editCollection";
import { MyContext } from "../../context/ContextApi";
import axiosApi from "../../interceptors/axiosinterceptor";
import { CommonBtn, CommonModal, SearchInputBtn } from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";

const Collection = () => {
  const { Column, HeaderCell, Cell } = Table;
  const { setCollectionfetch } = useContext(MyContext);
  const [modalShow, setModalShow] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(true);
  const [records, setRecords] = useState();
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);

  const email = sessionStorage.getItem("email");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(createCollectionValidaton),
    mode: "all",
  });

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
    if (search !== "") {
      setSearch("");
    }
  };
  const CollectionList = async () => {
    setLoading(true);
    try {
      let result = await axiosApi.get(`${getUrl.listCollection}`);
      setData(result.data);
      setLoading(false);
    } catch (err) {
      console.log("Error in CollectionList", err);
    }
  };

  useEffect(() => {
    CollectionList();
  }, [fetch]);

  useEffect(() => {
    if (editData !== undefined) {
      setEditModalShow(true);
    }
  }, [editData]);

  // useEffect(() => {
  //   if (deleteData !== undefined) {
  //     setDeleteModal(true);
  //   }
  // }, [deleteData]);

  const closePerson = async () => {
    setDeleteModal(false);
    try {
      let payload = {
        collection_name: deleteData.collection_name,
      };

      let body = {
        email: email,
        collection_name: deleteData.collection_name,
      };

      await deleteCollections(payload, setFetch);
      await deleteDocumentAll(body);
      setDeleteModal(false);
    } catch (err) {
      console.error("Error in closePerson:", err);
    }
  };

  useEffect(() => {
    if (search !== "") {
      // const start = limit * (page - 1);
      // const end = start + limit;
      const result = data?.filter((data) => {
        return (
          data?.collection_name?.toLowerCase().indexOf(search?.toLowerCase()) >=
          0
        );
      });
      setRecords(result);
    } else {
      const res = data?.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
      setRecords(res);
      // setLimit(result.length);
    }
  }, [search]);

  useEffect(() => {
    const res = data?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    setRecords(res);
    // }
  }, [data, page, limit]);

  const createCollection = async (formValues) => {
    // e.preventDefault();/
    reset();
    setModalShow(false);
    // let res = new FormData(e.target);
    // let value = [...res.entries()];
    let body = {
      collection_name: formValues.collectionname,
      bucket: formValues.bucketname,
      folder: formValues.collectionname,
    };

    // if (formValues.folder === "") {
    //   delete body.folder;
    // }

    setLoading(true);
    await createCollections(body, setFetch);
    setCollectionfetch((e) => !e);
    setLoading(false);
  };

  const handleClickAdd = () => {
    reset();
    setModalShow(true);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  const handleClickDeleteIcon = (rowData) => {
    setDeleteData(rowData);
    setDeleteModal(true);
  };

  return (
    <div>
      <SearchInputBtn
        placeholder={`${commonStr?.search}  ${commonStr?.collection}`}
        btnTitle={`${commonStr?.add}  ${commonStr?.collection}`}
        btnOnClick={handleClickAdd}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="mt-4">
        <div>
          <Table
            loading={Loading ? true : false}
            wordWrap="break-word"
            className="custom-table"
            virtualized
            hover={false}
            height={434}
            data={records}
            // sortColumn={sortColumn}
            // sortType={sortType}
            // onSortColumn={handleSortColumn}
            style={{
              // marginTop: "30px",
              // borderRadius: "10px ",
              scrollbarWidth: "0",
              // minHeight: "67vh",
              maxHeight: "58vh",
            }}
            align="center"

            // locale={{
            //   emptyMessage: fetching === "true" ? "Loading..." : "No data found",
            // }}
          >
            <Column width={330} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Collection Name</HeaderCell>
              <Cell dataKey="collection_name" id="content" />
            </Column>

            <Column width={410} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Bucket</HeaderCell>
              <Cell dataKey="bucket" id="content" />
            </Column>
            <Column width={410} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Folder</HeaderCell>
              <Cell dataKey="folder" id="content">
                {(rowData) => (
                  <>{rowData.folder === null ? "NULL" : rowData.folder}</>
                )}
              </Cell>
            </Column>
            {/* <Column width={510} verticalAlign="middle">
              <HeaderCell id="header">KPI</HeaderCell>
              <Cell dataKey="kpi" id="content" />
            </Column> */}

            <Column width={240} flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header" style={{ minWidth: "160px" }}>
                Action
              </HeaderCell>
              <Cell style={{ padding: "6px", marginLeft: "6px" }} id="content">
                {(rowData) => (
                  <>
                    <img
                      src={edit}
                      alt="edit"
                      onClick={() => setEditData(rowData)}
                      style={{ cursor: "pointer" }}
                    />
                    {/* <FaEdit
                      size={"1.25em"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setEditData(rowData)}
                    /> */}
                    &ensp;
                    <img
                      src={delete1}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickDeleteIcon(rowData)}
                    />
                    {/* <RiDeleteBin5Fill
                      size={"1.25em"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setDeleteData(rowData)}
                    /> */}
                  </>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ paddingTop: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={search === "" ? data.length : records.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
      <div>
        {" "}
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          {" "}
          <form onSubmit={handleSubmit(createCollection)}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add Collection
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                className="w-100 form-control"
                {...register("collectionname")}
                style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
                placeholder="Collection Name"
              />
              <div style={{ color: "red", height: "10px" }}>
                {errors.collectionname?.message}
              </div>
              <input
                type="text"
                className="w-100 mt-3 form-control"
                {...register("bucketname")}
                style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
                placeholder="Bucket Name"
              />
              <div style={{ color: "red", height: "10px" }}>
                {errors.bucketname?.message}
              </div>
              {/* <input
                type="text"
                className="w-100 mt-3 form-control"
                {...register("folder")}
                style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
                placeholder="Folder Name (optional)"
              />
              <div style={{ color: "red", height: "10px" }}>
                {errors.folder?.message}
              </div> */}
            </Modal.Body>
            <Modal.Footer>
              <CommonBtn
                title={commonStr.cancel}
                onClick={() => setModalShow(false)}
                className="mr-2"
              />
              <CommonBtn type="submit" title={commonStr.add} />
            </Modal.Footer>
          </form>
        </Modal>
      </div>
      {/* 
   Edit Modal */}
      <EditCollection
        editmodalShow={editmodalShow}
        setEditModalShow={setEditModalShow}
        editData={editData}
        setEditData={setEditData}
        setCollectionfetch={setCollectionfetch}
        setFetch={setFetch}
      />

      {deleteModal && (
        <CommonModal
          showModal={deleteModal}
          title={"Delete"}
          content={`Are you sure want to delete this  "${deleteData?.collection_name}"  Collection ?`}
          onCancel={handleDeleteCloseModal}
          onConfirm={closePerson}
        />
      )}
    </div>
  );
};

export default Collection;
