import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { editCollection } from "../../api/Postaction";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { editCollectionValidaton } from "../../validation";
import { CommonBtn } from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
const EditCollection = ({
  editmodalShow,
  setEditModalShow,
  editData,
  setFetch,
  setEditData,
  setCollectionfetch,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: zodResolver(editCollectionValidaton),
    mode: "all",
  });
  useEffect(() => {
    setValue("collectionname", editData ? editData.collection_name : "");
    setValue("bucketname", editData ? editData.bucket : "");
    setValue(
      "folder",
      editData ? (editData.folder === null ? "" : editData.folder) : ""
    );
  }, [editData]);
  const closeeditModal = () => {
    setEditModalShow(false);
    setEditData();
  };
  const handleSubmit1 = async (formValues) => {
    setEditModalShow(false);
    reset();
    let body = {
      collection_id: editData.collection_id,
      collection_name: formValues.collectionname,
      bucket: formValues.bucketname,
      folder: formValues.collectionname,
    };
    // if (formValues.folder === "") {
    //   body.folder = null;
    // }
    await editCollection(body, editData.collection_name);
    setCollectionfetch((e) => !e);
    setFetch((e) => !e);
  };
  return (
    <Modal
      show={editmodalShow}
      onHide={() => setEditModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      {" "}
      <form onSubmit={handleSubmit(handleSubmit1)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Collection
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-1">
            CollectionName
            <span className="" style={{ color: "red" }}>
              *
            </span>{" "}
            :
          </div>
          <input
            type="text"
            className="w-100 form-control"
            {...register("collectionname")}
            defaultValue={editData ? editData.collection_name : ""}
            style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
            placeholder="Collection Name"
          />
          <div className="mb-2" style={{ color: "red" }}>
            {errors.collectionname?.message}
          </div>
          <div className="mt-2 mb-1">
            Bucket
            <span className="" style={{ color: "red" }}>
              *
            </span>{" "}
            :
          </div>
          <input
            type="text"
            className="w-100 form-control"
            {...register("bucketname")}
            defaultValue={editData ? editData.bucket : ""}
            style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
            placeholder="Bucket Name"
          />
          <div className="mb-2" style={{ color: "red" }}>
            {errors.bucketname?.message}
          </div>
          {/* <div className="mt-2 mb-1">Folder :</div>
          <input
            type="text"
            className="w-100 form-control"
            {...register("folder")}
            defaultValue={editData ? editData.folder : ""}
            style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
            placeholder="Folder Name (optional)"
          />
          <div style={{ color: "red", height: "10px" }}>
            {errors.folder?.message}
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <CommonBtn
            title={commonStr.cancel}
            onClick={closeeditModal}
            className="mr-2"
          />
          <CommonBtn type="submit" title={commonStr.update} />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditCollection;
