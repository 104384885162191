// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `



.generateBtnContainer
{
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 40%;
  top: 82%;
  z-index: 1000;
  border: 1px solid rgb(204, 204, 204);
  background-color: whitesmoke;
  border-radius: 5px;
  min-width: 25px;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/common/commonGenerateBtn/styles.css"],"names":[],"mappings":";;;;AAIA;;EAEE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,uBAAuB;EACvB,SAAS;EACT,QAAQ;EACR,aAAa;EACb,oCAAoC;EACpC,4BAA4B;EAC5B,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf","sourcesContent":["\n\n\n\n.generateBtnContainer\n{\n  display: flex;\n  position: absolute;\n  align-items: center;\n  justify-content: center;\n  left: 40%;\n  top: 82%;\n  z-index: 1000;\n  border: 1px solid rgb(204, 204, 204);\n  background-color: whitesmoke;\n  border-radius: 5px;\n  min-width: 25px;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
