import React from "react";

const WaterMarkDetection = ({ watermarkData, currentPage }) => {
  // console.log(watermarkData, "watermarkData");
  // console.log(currentPage, "currentPageWater");

  const filteredWatermarkData = watermarkData?.filter(
    (item) => item["Page No"] === currentPage?.toString()
  );
  // console.log(filteredWatermarkData, "filteredWatermarkData");

  const path = window.location.pathname;
  // console.log(path, "path");

  return (
    <div className="image-gallery d-flex flex-wrap mt-4">
      <h6
        style={
          path && { fontSize: "14px", color: "black", paddingLeft: "10px" }
        }
        className="mt-3 d-flex w-100"
      >
        Watermark Detection
      </h6>
      <div className="row">
        {filteredWatermarkData?.length > 0 ? (
          filteredWatermarkData?.map((image, index) => (
            <div className="col-6 tabBG">
              <div className="tabImgCard">
                <div className="imgContainer">
                  <img
                    key={index}
                    src={image["Image URL"]}
                    alt={`${index + 1}`}
                  />
                </div>
                <div className="img-text" style={{color: 'black'}}>
                  <div className="img-flex">
                    <span>Page No : </span>
                    {image["Page No"]}
                  </div>
                  <div className="img-flex">
                    <span>Image No : </span>
                    {image["Image No"]}
                  </div>
                  <div className="img-flex">
                    <span>Location : </span>
                    {image["location"]}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div style={{ color: "red", paddingLeft: "25px", fontSize: "14px" }}>
            <p className="text-center">No data</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WaterMarkDetection;
