import React, { useEffect, useState, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./ContentChange.css";
import Select from "react-select";

import { toast } from "react-toastify";
import { MyContext } from "../../context/ContextApi";

import {
  CommonBtn,
  CommonInput,
  CommonLoader,
  SearchInputArrowBtn,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import {
  contentTagingSearch,
  contentDataBaseList,
  contentTableList,
  contentTagIntoTable,
  uploadLocalDocumetGt,
  uploadDocumentGt,
} from "../../api/Postaction";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import exportFromJSON from "export-from-json";
import Modal from "react-bootstrap/Modal";
import { MdCancel } from "react-icons/md";
import { errorStr } from "../../utils/constants/errorStrings";
import { showToast } from "../../utils/helpers/helpers";
import { deleteCollectionCt } from "../../api/DeleteAction";

const steps = ["Upload Document", "Insert Tags", "Load Table"];

const ContentChange = () => {
  const { newQuery, filterContent, setIsFileUploadStatus } =
    useContext(MyContext);

  const location = useLocation();
  const currentPath = location.pathname.substring(1);
  const divRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const [createPipelineBtn, setCretePipelineBtn] = useState(true);

  const [documentModal, setDocumentModal] = useState(false);
  const [link, setlink] = useState("");
  const [localPath, setLocalpath] = useState();
  const [uploadedFileList, setUploadedFileList] = useState([]);
  const [tableTagsResponse, setTableTagsResponse] = useState([]);

  const [selectRdbms, setSelectRdbms] = useState([
    {
      value: "Postgres",
      label: "PostgreSql",
    },
    {
      value: "MySql",
      label: "MySql",
      isDisabled: true,
    },
  ]);

  const [selectRdbmsValue, setSelectRdbmsValue] = useState(null);
  const [dbDataList, setDbDataList] = useState([]);
  const [tabDataList, setTabDataList] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");

  const [selectedRadio, setSelectedRadio] = useState(null);
  const [isFileUploadBtnDis, setIsFileUploadBtnDis] = useState(true);
  const [isTagNextBtnDis, setIsTagNxtBtnDis] = useState(false);

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const handleClickCsv = () => {
    const fileName = "content_data";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: tableTagsResponse, fileName, exportType });
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    setlink(""); // Clear the document upload state
    setLocalpath(null); // Clear the local path state
    setInputValue(""); // Clear the text state
    setSelectedOption(""); // Clear the dropdown state
    setSelectedDropdownValue(""); // Clear the selected dropdown value state
    setIsFileUploadBtnDis(true);
  };

  // drop down database api call
  const handleSelectRdbms = async (selectedValue) => {
    setSelectRdbmsValue(selectedValue);
    if (selectedValue) {
      try {
        let payload = {
          host: "mlpractice2.cabfbspytumf.us-west-2.rds.amazonaws.com",
          port: 5432,
          username: "postgres",
          password: "al_labs_1234",
        };

        let apiRes = await contentDataBaseList(payload);
        if (apiRes && apiRes.message) {
          setDbDataList(apiRes.message);
        }
      } catch (err) {
        toast.error("Apologies, could not get the database list", err);
      }
    }
  };

  const handleSelectDataBase = async (selectedValue) => {
    if (selectedValue) {
      try {
        let payload = {
          host: "mlpractice2.cabfbspytumf.us-west-2.rds.amazonaws.com",
          port: 5432,
          username: "postgres",
          password: "al_labs_1234",
          database: selectedValue.value,
        };

        let res = await contentTableList(payload);
        if (res && res.message) {
          setTabDataList(res.message);
        }
      } catch (err) {
        toast.error("Apologies, could not get the table list", err);
      }
    }
  };

  const handleTableSelect = (selectedOption) => {
    setSelectedDropdownValue(selectedOption.value);
  };

  // click new query btn
  useEffect(() => {
    if (currentPath === "contentChange") {
      setData([]);
      setInputValue("");
    }
  }, [newQuery]);

  const generatePromptTag = async () => {
    if (inputValue !== "") {
      setLoading(true);
      setIsTagNxtBtnDis(true);
      let cleanedInputValue = inputValue.replace(/\s*,\s*/g, ",");
      let convertSplit = cleanedInputValue.split(",").map((tag) => tag.trim());

      let payload = {
        tags: convertSplit,
        collection: filterContent.userName + "_ct",
      };
      const result = await contentTagingSearch(payload);

      if (result?.status === 200) {
        const responseData = result?.data?.table_tags;
        setData(responseData);
        setTableTagsResponse(responseData);
        handleNext();
        setLoading(false);
        setInputValue("");
        setIsTagNxtBtnDis(false);
      } else {
        setLoading(false);
        setIsTagNxtBtnDis(false);
      }
    } else {
      toast.error("Please Enter Input");
    }
  };

  const hadleCreatePipeline = () => {
    setCretePipelineBtn((val) => !val);
  };

  const handleUploadBtn = () => {
    setDocumentModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDocumentModal(false);
    try {
      await userPreference(); // Process user preference
    } catch (error) {
      toast.error("Error in file upload", error);
    }
  };

  const handleCancelUpload = () => {
    setDocumentModal(false);
    setlink("");
    setLocalpath(null);
  };

  const userPreference = async () => {
    if (localPath || link) {
      let deletePayload = {
        collection: filterContent.userName + "_ct",
      };
      await deleteCollectionCt(deletePayload);
    }

    if (localPath === null) {
      let payload = {
        file_url: [link],
        collection: filterContent.userName + "_ct",
      };
      try {
        const res = await uploadDocumentGt(payload);

        if (res?.status_code === 200) {
          // setDocumentModal(true);
          setlink("");
          setIsFileUploadBtnDis(false);
          setIsFileUploadStatus((e) => !e);
        } else {
          setDocumentModal(true);
        }
      } catch (error) {
        toast.error("Error in file upload", error);
        setlink("");
      }
    } else {
      let body = {
        collection: filterContent.userName + "_ct",
        data: [],
      };

      const isFileSizeValid = uploadedFileList.every(
        (file) => file.encoded_content.length <= 50 * 1024 * 1024
      );

      if (isFileSizeValid) {
        uploadedFileList.forEach((file) => {
          let format = "";

          if (file.name.endsWith(".pdf")) {
            format = "pdf";
          } else if (file.name.endsWith(".docx")) {
            format = "docx";
          } else if (file.name.endsWith(".pptx")) {
            format = "pptx";
          }
          body.data.push({
            encoded_content: file.encoded_content,
            name: file.name,
            format: format,
          });
        });
        try {
          const res = await uploadLocalDocumetGt(body);
          if (res?.status_code === 200) {
            setLocalpath(null);
            setIsFileUploadBtnDis(false);
          } else {
            setDocumentModal(true);
          }
        } catch (error) {
          toast.error("Error in file upload", error);
          setLocalpath(null);
        }
      } else {
        toast.error("File size exceeds the maximum allowed size (10MB)");
      }
    }
  };

  const handleChange1 = (e) => {
    let a = e.target.value;
    if (a.endsWith(".ppt")) {
      showToast(errorStr.fileFormat);
    } else {
      setlink(e.target.value);
      if (localPath !== null) {
        setLocalpath(null);
      }
    }
  };
  const handleChange2 = async (e) => {
    const files = e.target.files;

    setLocalpath(e.target.files[0]);
    const readFile = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({
            name: file.name,
            format: file.type,
            encoded_content: reader.result.split(",")[1],
          });
        };
        reader.readAsDataURL(file);
      });
    };

    const uploadedFiles = [];
    for (const file of files) {
      const result = await readFile(file);
      uploadedFiles.push(result);
    }
    setUploadedFileList(uploadedFiles);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      generatePromptTag();
    }
  };

  const handleFinish = async () => {
    try {
      const payload = {
        final_out: tableTagsResponse,
        table_name: selectedDropdownValue,
      };
      const response = await contentTagIntoTable(payload);

      if (response) {
        toast.success("Tag content updated in table successfully.");
        handleNext();
      } else {
        toast.error("Failed to tag content into table.");
      }
    } catch {
      toast.error("Failed to perform actions.");
    }
  };

  const handleToMoveStepTwo = () => {
    generatePromptTag();
  };

  return (
    <div className="mainContent " ref={divRef}>
      {createPipelineBtn ? (
        <div className="displayFlexCenter">
          <div
            className="btn ml-3 mr-2 kpiGenerateBtn "
            onClick={hadleCreatePipeline}
          >
            {commonStr.createPipeline}
          </div>
        </div>
      ) : (
        <div className="content-stepper">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};

                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <div className="pipeline-text">
                  <Typography
                    sx={{
                      mt: 2,
                      mb: 1,
                      dispaly: "inline-block",
                      border: "none",
                      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                      padding: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    All steps completed - Data updated sucessfully in database.
                  </Typography>
                </div>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleReset}>Reset</Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  {activeStep === 0 && (
                    <div className="d-flex justify-content-center pt-3">
                      <div
                        className="btn ml-3 mr-2 kpiGenerateBtn "
                        onClick={handleUploadBtn}
                      >
                        {commonStr.uploadNewDoc}
                      </div>
                    </div>
                  )}

                  {activeStep === 1 && (
                    <div>
                      <div className="content-search">
                        {loading ? (
                          <CommonLoader />
                        ) : (
                          <SearchInputArrowBtn
                            value={inputValue}
                            placeholder={commonStr.typeTags}
                            onKeyPress={handleKeyPress}
                            onChange={(e) => setInputValue(e.target.value)}
                            inputValue={inputValue}
                            audioHide={false}
                            customSerchInputWrap="contentChangeInput"
                            iconVisible={false}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {activeStep === 2 && (
                    <>
                      <div className="col-12">
                        <div className="row mt-5">
                          <div className="col-1 content-table-radiobtn">
                            <Form.Check
                              type="radio"
                              aria-label="radio 1"
                              name="group1"
                              className="custom-checkbox"
                              value="selectOption"
                              onChange={handleRadioChange}
                            />
                          </div>
                          <div className="col-3">
                            <Select
                              placeholder="Choose RDBMS"
                              options={selectRdbms}
                              onChange={handleSelectRdbms}
                              isDisabled={selectedRadio !== "selectOption"}
                            />
                          </div>
                          <div className="col-3">
                            <Select
                              placeholder="Choose Database"
                              options={dbDataList.map((option) => ({
                                value: option,
                                label: option,
                              }))}
                              onChange={handleSelectDataBase}
                              isDisabled={selectedRadio !== "selectOption"}
                            />
                          </div>
                          <div className="col-3">
                            <Select
                              placeholder="Choose File"
                              options={tabDataList.map((option) => ({
                                value: option,
                                label: option,
                              }))}
                              onChange={handleTableSelect}
                              isDisabled={selectedRadio !== "selectOption"}
                            />
                          </div>
                        </div>

                        <div className="row mt-5">
                          <div className="col-1 content-table-radiobtn">
                            <Form.Check
                              type="radio"
                              aria-label="radio 2"
                              name="group1"
                              className="custom-checkbox"
                              value="exportOption"
                              onChange={handleRadioChange}
                            />
                          </div>

                          <div className="col-4">
                            <Button
                              className="export-btn-width"
                              variant="outlined"
                              disabled={selectedRadio !== "exportOption"}
                              onClick={handleClickCsv}
                            >
                              Export CSV
                            </Button>
                          </div>
                        </div>
                        <br />
                        <div>
                          <span className="text-below-button">
                            Click the button to download the result
                          </span>
                        </div>
                      </div>
                    </>
                  )}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  {activeStep === steps.length - 1 ? (
                    selectedDropdownValue ? (
                      <Button onClick={handleFinish}>Finish</Button>
                    ) : (
                      // If the dropdown value is not selected, disable the "Finish" button
                      <Button disabled>Finish</Button>
                    )
                  ) : (
                    <>
                      {activeStep === 0 && (
                        // Only render the "Next" button on step 0 if document is uploaded
                        <Button
                          onClick={handleNext}
                          disabled={isFileUploadBtnDis} // Disable if neither link nor local path is set
                        >
                          Next
                        </Button>
                      )}
                      {activeStep === 1 && (
                        // Only render the "Next" button on step 1 if input value is set
                        <Button
                          onClick={handleToMoveStepTwo}
                          disabled={!inputValue || isTagNextBtnDis}
                        >
                          Next
                        </Button>
                      )}
                      {activeStep === 2 && (
                        // Render the "Next" button on step 2
                        <Button onClick={handleNext}>Next</Button>
                      )}
                    </>
                  )}
                </Box>
              </React.Fragment>
            )}
          </Box>
        </div>
      )}

      {documentModal && (
        <Modal
          show={documentModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <form onSubmit={handleSubmit}>
            <div className="modalHeader">
              <div className="d-flex align-items-center justify-content-between w-100">
                <span>{commonStr.uploadDoc}</span>&ensp;
                <MdCancel
                  color="#A5A5A5"
                  onClick={handleCancelUpload}
                  className="iconHover"
                />
              </div>
            </div>
            <Modal.Body>
              <div className="modalconent modalUploadBox">
                <CommonInput
                  className="w-100 moadalUploadInput"
                  placeholder={commonStr.link}
                  onChange={handleChange1}
                  value={link}
                  required={localPath === "" ? true : false}
                />

                <div className="mt-3 d-flex">
                  <p>{commonStr.or}</p>
                </div>
                <CommonInput
                  type={`file`}
                  className="w-100 mt-2 iconHover"
                  placeholder={commonStr.localPath}
                  onChange={handleChange2}
                  required={link === "" ? true : false}
                  accept={".pdf, .pptx, .docx"}
                  fileUpload
                />
              </div>
              <div className="context">
                <div className="text">
                  <span className="red">*</span>Maximum File size allowed is 10
                  MB
                </div>
                <div className="text">
                  <span className="red">*</span>Only PDF files are supported
                </div>
              </div>
            </Modal.Body>
            <div className="modalButton">
              <CommonBtn
                title={commonStr.cancel}
                onClick={handleCancelUpload}
                className="mr-1 iconHover"
              />

              <CommonBtn title={commonStr.upload} type="submit" />
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default ContentChange;
