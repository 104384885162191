import React, { useEffect, useState } from "react";
import { Table, Pagination } from "rsuite";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Modal from "react-bootstrap/Modal";
import "rsuite/dist/rsuite.css";
import edit from "../../assets/svg/edit.svg";
import delete1 from "../../assets/svg/delete.svg";
import "./Persona.css";
import Select from "react-select";
import { personaValidation } from "../../validation";
import { getUrl } from "../../api/Endpoint";
import { creatPersona, deletePersona } from "../../api/Postaction";
import EditPersona from "./editPersona";
import axiosApi from "../../interceptors/axiosinterceptor";
import { CommonBtn, CommonModal, SearchInputBtn } from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";

const PersonaComponent = () => {
  const { Column, HeaderCell, Cell } = Table;
  const [modalShow, setModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [records, setRecords] = useState();
  const [Loading, setLoading] = useState(true);
  const [fetch, setFetch] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [tableOptions, setTableOptions] = useState([]);
  const [search, setSearch] = useState("");

  const { register, control, handleSubmit, formState, reset } = useForm({
    resolver: zodResolver(personaValidation),
    mode: "all",
  });

  const { errors } = formState;
  const TableAccess = useController({
    name: "TableAccess",
    control,
  });
  const handletableAcess = (option) => {
    TableAccess.field.onChange(option);
  };
  const personaData = async (formValues) => {
    reset();
    formValues.TableAccess = formValues.TableAccess.map((e) => e.value);
    formValues.kpi = formValues.kpi.split(",");
    setModalShow(false);
    await creatPersona(formValues, setFetch);
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
    if (search !== "") {
      setSearch("");
    }
  };

  const closePerson = () => {
    deletePersona(deleteData, setFetch);
    setDeleteModal(false);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        let res = await axiosApi.get(`${getUrl.persona}`);
        let res1 = await axiosApi.get(`${getUrl.tables}`);
        let a = res1.data.tables;
        let tables = a.map((e) => ({
          value: e,
          label: e,
        }));

        setTableOptions(tables);
        let options = res.data.map((ele) => ({
          Persona: ele.persona_name,
          Tables: ele.tables.join(","),
          kpi: ele.kpi !== null ? ele.kpi.join(",") : "Kpi not Assigned",
        }));
        setData(options);
        setLoading(false);
      } catch (err) {
        console.log("Error in fetching Persona", err);
      }
    };

    fetchData();
  }, [fetch]);
  useEffect(() => {
    if (search !== "") {
      // const start = limit * (page - 1);
      // const end = start + limit;
      // data.slice(start, end).filter
      const result = data?.filter((data) => {
        return data.Persona?.toLowerCase().indexOf(search?.toLowerCase()) >= 0;
      });
      setRecords(result);
      // setLimit(result.length);
    } else {
      const res = data?.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
      setRecords(res);
    }
  }, [search]);
  useEffect(() => {
    // if (search === "") {
    const res = data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    setRecords(res);
    // }
  }, [data, page, limit]);

  const handleDeleteIcon = (rowData) => {
    setDeleteData(rowData);
    setDeleteModal(true);
  };

  const handleEditIcon = (rowData) => {
    setEditData(rowData);
    setEditModalShow(true);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  const handleClickAdd = () => {
    reset();
    setModalShow(true);
  };

  return (
    <div>
      <SearchInputBtn
        placeholder={`${commonStr?.search}  ${commonStr?.persona}`}
        btnTitle={`${commonStr?.add}  ${commonStr?.persona}`}
        btnOnClick={handleClickAdd}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="mt-4">
        <div>
          <Table
            loading={Loading ? true : false}
            wordWrap="break-word"
            className="custom-table"
            virtualized
            hover={false}
            height={434}
            data={records}
            style={{
              scrollbarWidth: "0",

              maxHeight: "58vh",
            }}
            align="center"
          >
            <Column flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header">Persona</HeaderCell>
              <Cell dataKey="Persona" id="content" />
            </Column>

            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Data Access</HeaderCell>
              <Cell dataKey="Tables" id="content" />
            </Column>
            <Column flexGrow={3} verticalAlign="middle">
              <HeaderCell id="header">KPI</HeaderCell>
              <Cell dataKey="kpi" id="content" />
            </Column>

            <Column flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header">Action</HeaderCell>
              <Cell style={{ padding: "6px", marginLeft: "6px" }} id="content">
                {(rowData) => (
                  <>
                    <img
                      src={edit}
                      alt="edit"
                      className="iconHover"
                      onClick={() => handleEditIcon(rowData)}
                    />
                    &ensp;
                    <img
                      src={delete1}
                      alt="edit"
                      className="iconHover"
                      onClick={() => handleDeleteIcon(rowData)}
                    />
                  </>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ paddingTop: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={search === "" ? data.length : records.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          {" "}
          <form onSubmit={handleSubmit(personaData)}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add Persona
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                className="w-10 form-control"
                {...register("Role")}
                style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
                placeholder="Persona Name"
              />
              <div style={{ color: "red", height: "10px" }}>
                {errors.Role?.message}
              </div>
              <Select
                isMulti
                className="mt-3"
                placeholder="Select Table"
                onChange={handletableAcess}
                options={tableOptions}
              />
              <div style={{ color: "red" }}>
                {errors.TableAccess?.message === "Required"
                  ? "Select Table"
                  : errors.TableAccess?.message}
              </div>
              <textarea
                type="text"
                className="mt-4 w-100 form-control"
                {...register("kpi")}
                id="exampleFormControlTextarea1"
                rows="5"
                style={{
                  padding: "10px",
                }}
                placeholder="Add Kpi(separated by comma)"
              />
            </Modal.Body>
            <Modal.Footer>
              <CommonBtn
                title={commonStr.cancel}
                onClick={() => setModalShow(false)}
                className="mr-2"
              />
              <CommonBtn type="submit" title={commonStr.add} />
            </Modal.Footer>
          </form>
        </Modal>
      )}

      <EditPersona
        editmodalShow={editmodalShow}
        setEditModalShow={setEditModalShow}
        editData={editData}
        tableOptions={tableOptions}
        setFetch={setFetch}
      />

      {deleteModal && (
        <CommonModal
          showModal={deleteModal}
          title={"Delete"}
          content={`Are you sure want to delete this  "${deleteData?.Persona}"  Persona ?`}
          onCancel={handleDeleteCloseModal}
          onConfirm={closePerson}
        />
      )}
    </div>
  );
};

export default PersonaComponent;
