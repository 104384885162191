import React from "react";

import "./styles.css";
function LabelWithInput({
  id,
  labelTitle,
  type = "text",
  labelClass,
  errors,
  onInput,
  value,
  register,
  accountId = "AccountID",
  inputClass = "",
}) {
  return (
    <div className="d-flex">
      <label for={id} className={labelClass}>
        {labelTitle}
      </label>
      &ensp;&ensp;
      <div>
        <input
          type={type}
          id={id}
          value={value}
          placeholder={labelTitle}
          {...register(accountId)}
          className={inputClass}
          onInput={onInput}
          readOnly
        />
        {errors?.AccountID?.message && (
          <div className="lableInputError">{errors?.AccountID?.message}</div>
        )}
      </div>
    </div>
  );
}

export default LabelWithInput;
