import React, { useContext } from "react";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { profileIcon } from "../../utils/images";
import { MyContext } from "../../context/ContextApi";
import "./styles.scss";

const SidebarItem = ({ icon, text, onClick }) => {
  return (
    <div
      className="logout-details__iconwrap iconHover text-capitalize ml-1"
      onClick={onClick}
    >
      {icon}
      <p className="ml-2"> {text}</p>
    </div>
  );
};

function SideBarLogout({ isOpen, path, commonStr, navigate, setLogoutModal }) {
  const { filterContent } = useContext(MyContext);

  return (
    <div className={`logout-details ${isOpen ? "d-block" : "d-none"}`}>
      <div className="logout-details__firstitem">
        <SidebarItem
          icon={<img src={profileIcon} alt="sidebar" />}
          text={filterContent?.userName}
        />
      </div>

      <div className="logout-details__seconditem">
        <SidebarItem
          icon={<IoMdHelpCircleOutline size={22} />}
          text={commonStr.help}
          onClick={() => navigate(`/${path}/help`)}
        />
      </div>

      <div className="logout-details__seconditem">
        <SidebarItem
          icon={<RiLogoutCircleRLine size={23} />}
          text={commonStr.logout}
          onClick={setLogoutModal}
        />
      </div>
    </div>
  );
}

export default SideBarLogout;
