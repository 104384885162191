import React from "react";
import "./styles.scss";

import CommonLoader from "../commonLoader";

function CommonBtn({
  className = "",
  type = "button",
  disabled = false,
  title,
  onClick = () => {},
  loaderStatus = false,
  loaderStrokeColor = "white",
  outlineBtn = false,
}) {
  return (
    <button
      className={`${outlineBtn ? "" : "btns"} ${className}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {loaderStatus ? (
        <CommonLoader
          width="18"
          strokeColor={loaderStrokeColor}
          visible={true}
        />
      ) : (
        title
      )}
    </button>
  );
}

export default CommonBtn;
