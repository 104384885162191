// import axios from "axios";

import axiosApi from "../interceptors/axiosinterceptor";

import { putUrl } from "./Endpoint";
import { toast } from "react-toastify";

export const editWorkBook = async (body, setFetch) => {
  let a = toast.warning("WorkBook Updating...", { autoClose: false });
  try {
    await axiosApi.put(`${putUrl.editWorkBook}${body.workbook_name}/`, body);
    toast.dismiss(a);
    toast.success(`${body.workbook_name}"Updated Successfully!!!"`);
    setFetch((current) => !current);
  } catch (err) {
    console.log("Error in Updating Persona", err);
    toast.dismiss(a);
  }
};
