export const errorStr = {
  userName: "Username",
  password: "Password",
  isRequired: "is Required",
  mustBeString: "must be a string",
  choose: "Choose",
  collection: "Collection",
  persona: "Persona",
  pleaseChoose: "Please select the collection",
  selectPersona: "Please Select Persona",
  selectKpi: "Select KPI",
  fileFormat: "File Format not accepted",
  noSeesion: "No Session Available",
  userAuth: "User authenticated",
  accessToken: "Access token not available in login response",
  userDoesnt: "User does not exist. Please check your credentials.",
  anError: "An error occurred. Please try again.",
  loginFail: "Login failed. Please try again",
  anErrorOccurred: "An error occurred during login. Please try again.",
  pleaseEnterQuery: "Please enter the query",
  pleaseSelectCollection: "Please Select an Collection",
  errorGeneratePrompt: "Error on generating prompt",
  sessionNameAlready: "Session Name Already Exists",
};
