import React, { useEffect, useState } from "react";
import { Table, Pagination } from "rsuite";
import Modal from "react-bootstrap/Modal";
import "rsuite/dist/rsuite.css";
import edit from "../../assets/svg/edit.svg";
import delete1 from "../../assets/svg/delete.svg";
import "./Persona.css";
import { toast } from "react-toastify";

import { createWorkBookKpi } from "../../api/Postaction";
import {
  getWorkBookList,
  tableauLstProject,
  tableau_list_workbook,
} from "../../api/GetActions";
import { deleteWorkBookApi } from "../../api/DeleteAction";
import { editWorkBook } from "../../api/PutActions";
import {
  CommonBtn,
  CommonModal,
  CommonTextArea,
  LabelWithSelect,
  SearchInputBtn,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";

const WorkBookCmp = () => {
  const { Column, HeaderCell, Cell } = Table;
  const [modalShow, setModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [records, setRecords] = useState();
  const [Loading, setLoading] = useState(true);
  const [fetch, setFetch] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [search, setSearch] = useState("");

  const [isProjectList, setIsProjectList] = useState([]);
  const [isProjectValue, setIsProjectValue] = useState("");

  const [isWorkBookList, setIsWorkBookList] = useState([]);
  const [isWorkBookValue, setIsWorkBookValue] = useState("");

  const [editableStatus, setEditableStatus] = useState(false);
  const [isKpiValue, setIsKpiValue] = useState("");
  const [errorMsg, setErrorMsg] = useState({
    project: "",
    workBook: "",
  });

  const handleChangeProject = (option) => {
    setIsProjectValue({ label: option.value, value: option.value });
    setErrorMsg((prevErrorMsg) => ({
      ...prevErrorMsg,
      project: "",
    }));
    if (option && option.value) {
      fetchDasboard(option.value);
    }
  };

  const handleToFocus = () => {
    if (editableStatus && isProjectValue !== "") {
      fetchDasboard(isProjectValue.value);
    }

    if (isProjectValue === "") {
      toast.error(
        commonStr?.project + " " + commonStr?.is + " " + commonStr?.required
      );
    }
  };

  const handleChangeWorkBook = (option) => {
    setIsWorkBookValue({ label: option.value, value: option.value });
    setErrorMsg((prevErrorMsg) => ({
      ...prevErrorMsg,
      workBook: "",
    }));
  };
  const fetchDasboard = async (getValue) => {
    try {
      let res = await tableau_list_workbook(getValue);
      let a1 = res;
      let options = a1.map((e) => ({
        value: e,
        label: e,
      }));

      setIsWorkBookList(options);
    } catch (err) {
      setIsWorkBookList("No Data");
    }
    // }
  };

  const handleSubmitWorkBook = async () => {
    let statusCheck = handleValidation();
    if (statusCheck) {
      let convertKpiValue = isKpiValue.split(",");
      let addPayload = {
        project_name: isProjectValue?.value,
        workbook_name: isWorkBookValue?.value,
        kpi: convertKpiValue,
      };

      if (editableStatus) {
        await editWorkBook(addPayload, setFetch);
      } else {
        await createWorkBookKpi(addPayload, setFetch);
      }
      handleToResetValue();
      setModalShow(false);
    }
  };

  const handleToResetValue = () => {
    setIsProjectValue("");
    setIsWorkBookValue("");
    setIsKpiValue("");
    setIsWorkBookList([]);
    setIsProjectList([]);
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
    if (search !== "") {
      setSearch("");
    }
  };

  const deleteWorkBook = () => {
    deleteWorkBookApi(deleteData, setFetch);
    setDeleteModal(false);
  };

  const handleClickDelete = (data) => {
    setDeleteModal(true);
    setDeleteData(data);
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const getWorkBookTable = await getWorkBookList();
        let options = getWorkBookTable?.map((ele) => ({
          Project: ele.project_name,
          WorkBook: ele.workbook_name,
          kpi: ele.kpi !== null ? ele.kpi.join(",") : "Kpi not Assigned",
        }));
        setData(options);
        setLoading(false);
      } catch (err) {
        console.log("Error in fetching Persona", err);
      }
    };

    fetchData();
  }, [fetch]);

  const getProjectList = async () => {
    let getAcccountId = "919490798061";
    try {
      let res = await tableauLstProject(getAcccountId);
      if (res && res?.length > 0) {
        let options = res.map((e) => ({
          value: e,
          label: e,
        }));
        setIsProjectList(options);
      }
    } catch (err) {
      setIsProjectList("No Data");
    }
  };

  useEffect(() => {
    if (search !== "") {
      const result = data?.filter((data) => {
        return data.Project?.toLowerCase().indexOf(search?.toLowerCase()) >= 0;
      });
      setRecords(result);
    } else {
      const res = data?.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
      setRecords(res);
    }
  }, [search]);
  useEffect(() => {
    // if (search === "") {
    const res = data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    setRecords(res);
    // }
  }, [data, page, limit]);

  // handleHideModal

  const handleHideModal = () => {
    setErrorMsg(() => ({
      project: "",
      workBook: "",
    }));
    setModalShow(false);
    setEditableStatus(false);
  };

  const handleEditTable = ({ Project, WorkBook, kpi }) => {
    setEditableStatus(true);
    setIsProjectValue({ label: Project, value: Project });
    setIsWorkBookValue({ label: WorkBook, value: WorkBook });
    setIsKpiValue(kpi);
    setModalShow(true);
  };

  const handleKpiChange = (e) => {
    setIsKpiValue(e.target.value);
  };

  const handleClickAddWork = () => {
    handleToResetValue();
    setModalShow(true);
  };

  const handleValidation = () => {
    let errorMsgStatus = true;
    let errorMsg = {};

    if (isProjectValue.length === 0) {
      errorMsgStatus = false;
      errorMsg.project =
        commonStr?.project + " " + commonStr?.is + " " + commonStr?.required;
    }
    if (isWorkBookValue.length === 0) {
      errorMsgStatus = false;
      errorMsg.workBook =
        commonStr?.workbook + " " + commonStr?.is + " " + commonStr?.required;
    }

    setErrorMsg((prevErrorMsg) => ({ ...prevErrorMsg, ...errorMsg }));
    return errorMsgStatus;
  };

  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  return (
    <div>
      <SearchInputBtn
        placeholder={`${commonStr?.search}  ${commonStr?.project}`}
        btnTitle={`${commonStr?.add}  ${commonStr?.workbook}`}
        btnOnClick={handleClickAddWork}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="mt-4">
        <div>
          <Table
            loading={Loading ? true : false}
            wordWrap="break-word"
            className="custom-table"
            virtualized
            hover={false}
            height={434}
            data={records}
            style={{
              scrollbarWidth: "0",
              maxHeight: "58vh",
            }}
            align="center"
          >
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">{commonStr.project}</HeaderCell>
              <Cell dataKey="Project" id="content" />
            </Column>

            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">{commonStr.workbook}</HeaderCell>
              <Cell dataKey="WorkBook" id="content" />
            </Column>
            <Column flexGrow={3} verticalAlign="middle">
              <HeaderCell id="header">{commonStr.kpi}</HeaderCell>
              <Cell dataKey="kpi" id="content" />
            </Column>

            <Column flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header">{commonStr.action}</HeaderCell>
              <Cell style={{ padding: "6px", marginLeft: "6px" }} id="content">
                {(rowData) => (
                  <>
                    <img
                      src={edit}
                      alt="edit"
                      onClick={() => handleEditTable(rowData)}
                      style={{ cursor: "pointer" }}
                    />
                    &ensp;
                    <img
                      src={delete1}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickDelete(rowData)}
                    />
                  </>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ paddingTop: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={search === "" ? data.length : records.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
      <div>
        {" "}
        <Modal
          show={modalShow}
          onHide={handleHideModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {`${editableStatus ? commonStr.edit : commonStr.add}  ${
                commonStr.workbook
              }`}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <LabelWithSelect
              placeholder={commonStr.select + " " + commonStr.project}
              value={isProjectValue}
              options={isProjectList}
              onChange={handleChangeProject}
              onFocus={getProjectList}
              errors={
                errorMsg && errorMsg.project.length > 0 && errorMsg.project
              }
              labelHide={false}
              customSelectClass="mt-3"
            />

            <LabelWithSelect
              placeholder={commonStr.select + " " + commonStr.workbook}
              value={isWorkBookValue}
              options={isWorkBookList}
              onChange={handleChangeWorkBook}
              onFocus={() => handleToFocus()}
              errors={
                errorMsg && errorMsg.workBook.length > 0 && errorMsg.workBook
              }
              labelHide={false}
              customSelectClass="mt-3"
            />

            <CommonTextArea
              name="notes"
              className="mt-4 w-100 form-control p-2"
              placeholder="Add Kpi(separated by comma)"
              onChange={handleKpiChange}
              value={isKpiValue}
            />
          </Modal.Body>
          <Modal.Footer>
            <CommonBtn
              title={commonStr.cancel}
              onClick={() => handleHideModal()}
              className="mr-2"
            />
            <CommonBtn
              onClick={() => handleSubmitWorkBook()}
              title={`${editableStatus ? commonStr.update : commonStr.add}`}
            />
          </Modal.Footer>
        </Modal>
      </div>

      {deleteModal && (
        <CommonModal
          showModal={deleteModal}
          title={"Delete"}
          content={`Are you sure want to delete this "${deleteData?.WorkBook}"  WorkBook ?`}
          onCancel={handleDeleteCloseModal}
          onConfirm={deleteWorkBook}
        />
      )}
    </div>
  );
};

export default WorkBookCmp;
