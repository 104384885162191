import React from "react";
import "./styles.css";
import { ThreeDots } from "react-loader-spinner";
import { commonStr } from "../../utils/constants/commonStrings";

function ThreeDotSpinner() {
  return (
    <div className="d-flex align-items-center fontSize1">
      {commonStr.generateAudio}&ensp;
      <ThreeDots
        height="30"
        width="30"
        radius="9"
        color="#035EB7"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </div>
  );
}

export default ThreeDotSpinner;
