import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./common/commonStyle/commonStyles.css";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./components/login/authProvider";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";

const msalInstance = new PublicClientApplication(msalConfig);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

msalInstance.addEventCallback((e) => {
  if (e.eventType === EventType.LOGIN_SUCCESS && e.payload.account) {
    const account = e.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App instance={msalInstance} />
  </Router>
);

reportWebVitals();
