// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sendMailInput
{
  height: 38px;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/common/commonSendMail/styles.css"],"names":[],"mappings":"AAAA;;EAEE,YAAY;EACZ,aAAa;AACf","sourcesContent":[".sendMailInput\n{\n  height: 38px;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
