import React, { useState, useEffect, useContext } from "react";
import "./index.css";
import { toast } from "react-toastify";
import {
  uniqueValueColumnData,
  dataListInMedInsight,
} from "../../api/Postaction";
import { FormControl, MenuItem, Select } from "@mui/material";
import Plot from "react-plotly.js";
import { CommonLoader } from "../../common";
import { MyContext } from "../../context/ContextApi";

const MedInsights = () => {
  const { drugColumn, isMedPulseData } = useContext(MyContext);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedDrug, setSelectedDrug] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [drugOptions, setDrugOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCountryColumnName, SetIsCountryColumnName] = useState(null);

  const fetchCountryOptions = async (getDta) => {
    let body = {
      column_name: selectedColumn || getDta,
      stored_file_name_sen_tagged: isMedPulseData?.stored_sen_file,
    };

    try {
      const response = await uniqueValueColumnData(body);
      if (response && response?.length === 0) {
        toast.error(response);
      } else {
        setCountryOptions(response?.countries);
        SetIsCountryColumnName(response?.country_columne);
      }
    } catch (error) {
      console.error("Error fetching data for selected option:", error);
      toast.error("Failed to fetch data for selected option", error);
    }
  };

  const fetchDrugOptions = async (getDta) => {
    let body = {
      country_name: selectedCountry,
      drug_column: selectedDrug,
      stored_file_name_sen_tagged: isMedPulseData?.stored_sen_file,
      country_column_name: isCountryColumnName,
    };

    setLoading(true);
    try {
      const response = await dataListInMedInsight(body);
      if (response && response?.length === 0) {
        toast.error(response);
      } else {
        setDrugOptions(response);
      }
    } catch (error) {
      console.error("Error fetching drug options:", error);
      toast.error("Failed to fetch drug options", error);
    } finally {
      setLoading(false);
    }
  };

  const handleColumnChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedColumn(selectedValue);
  };

  const handleColumnMenu = (data) => {
    fetchCountryOptions(data);
    setSelectedColumn(data);
  };

  const handleCountryChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCountry(selectedValue);
  };

  const handleDrugChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedDrug(selectedValue);
  };

  useEffect(() => {
    if (selectedColumn) {
      fetchCountryOptions();
    }
  }, [selectedColumn]);

  useEffect(() => {
    if (selectedCountry && selectedDrug) {
      fetchDrugOptions();
    }
  }, [selectedCountry, selectedDrug]);

  return (
    <>
      <div className="contentScroll">
        <div className="container">
          <div className="d-flex justify-content-center my-5">
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <h5 className="d-flex mt-3 mb-2">Med Insights</h5>
                  <p className="content-lingo">
                    Med Insights transforms complex medical data into insightful
                    visualizations for enhanced understanding and
                    decision-making
                  </p>
                  <div className="row">
                    <label className="medInsightLabel">
                      Select the appropriate field
                    </label>
                    <div className="col-9 mb-4">
                      <FormControl fullWidth>
                        <Select
                          value={selectedColumn}
                          onChange={handleColumnChange}
                          className="medLingo_select mt-1"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">
                            <em>Choose the value</em>
                          </MenuItem>
                          {drugColumn?.map((column, index) => (
                            <MenuItem
                              // onClick={() => handleColumnMenu(column)}
                              key={index}
                              value={column}
                            >
                              {column}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="row">
                    <label className="medInsightLabel">
                      How would you like to be connected?
                    </label>
                    <div className="col-9 mb-4">
                      <FormControl fullWidth>
                        <Select
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          className="medLingo_select mt-1"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          disabled={!selectedColumn}
                        >
                          <MenuItem value="">
                            <em>Choose the value</em>
                          </MenuItem>
                          {Array.isArray(countryOptions) ? (
                            countryOptions?.map((value, index) => (
                              <MenuItem key={index} value={value}>
                                {value}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem value={countryOptions}>
                              {countryOptions}
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="row">
                    <label className="medInsightLabel">
                      Select the appropriate field
                    </label>
                    <div className="col-9 mb-4">
                      <FormControl fullWidth>
                        <Select
                          value={selectedDrug}
                          onChange={handleDrugChange}
                          className="medLingo_select mt-1"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          disabled={!selectedCountry}
                        >
                          <MenuItem value="">
                            <em>Choose the value</em>
                          </MenuItem>
                          {drugColumn?.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div className="w-100 mt-5">
                    <CommonLoader />
                  </div>
                ) : (
                  <>
                    {selectedDrug && (
                      <h6 className="mx-3">
                        Overall Count of Sentiment Distribution on
                        <span className="countryName">{selectedCountry}</span>
                      </h6>
                    )}
                    {
                      //drugOptions.length > 0 &&
                      drugOptions &&
                        drugOptions?.length > 0 &&
                        drugOptions?.map((e, i) => (
                          <div key={i}>
                            <Plot
                              data={e.data}
                              layout={e.layout}
                              config={{
                                ...e.config,
                                displaylogo: false, // Set displaylogo to false to hide the Plotly logo
                                modeBarButtonsToRemove: ["lasso2d", "select2d"], // Remove Lasso Select and Box Select buttons
                              }}
                            />
                          </div>
                        ))
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MedInsights;
