import React from "react";
import "./styles.css";
import { Button, Modal } from "react-bootstrap";
import CommonTextArea from "../commonTextArea";
import { commonStr } from "../../utils/constants/commonStrings";
import CommonBtn from "../commonBtn";
const { feedback, cancel, send } = commonStr;
function CommonFeedback({
  show,
  onSubmit,
  onchangeTextArea,
  onchangeTextAreaValue,
  closeRejectModal,
}) {
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
      centered
    >
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title id="contained-modal-title-vcenter">
            {feedback}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonTextArea
            name="notes"
            className="padding10"
            placeholder="Note"
            onChange={onchangeTextArea}
            value={onchangeTextAreaValue}
          />
        </Modal.Body>
        <Modal.Footer>
          <CommonBtn
            title={cancel}
            onClick={closeRejectModal}
            className="mr-2"
          />

          <CommonBtn type="submit" title={send} />
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default CommonFeedback;
