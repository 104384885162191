import React, { forwardRef } from "react";

const CommonFileUploadBtn = forwardRef(
  ({ onChange, name, disabled, key }, ref) => {
    return (
      <input
        className="geneInFileInput"
        type="file"
        onChange={onChange}
        name={name}
        disabled={disabled}
        key={key}
        ref={ref}
        accept=".txt"
      />
    );
  }
);

export default CommonFileUploadBtn;
