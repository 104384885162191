import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Plot from "react-plotly.js";
import axios from "axios";
import {
  getTexttoDashboard,
  updateChartResponseInHistory,
} from "../../api/Postaction";
import { ChatBot } from "../../common";
// import bot from "../../assets/svg/bot.svg";
import { toast } from "react-toastify";
import { Comment, RotatingLines } from "react-loader-spinner";
import { errorStr } from "../../utils/constants/errorStrings";
import arrow from "../../assets/svg/arrow.svg";
import { MyContext } from "../../context/ContextApi";
import { postUrl } from "../../api/Endpoint";
import axiosApi from "../../interceptors/axiosinterceptor";

const ExicistingDynamicChart = () => {
  const { personaValueList } = useContext(MyContext);
  const location = useLocation();
  const navigate = useNavigate();

  let state = location.state;
  const [inputValue, setInputValue] = useState(
    state?.inputValue ? state?.inputValue : ""
  );

  let currentPath = location.pathname.substring(1);
  if (currentPath.includes("%20")) {
    currentPath = currentPath.replace("%20", " ");
  }
  currentPath = currentPath.split("/")[1];

  const [generating, setGenerating] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  let userEmail = sessionStorage.getItem("email");

  const chatHistory = async () => {
    setLoading(true);
    try {
      setData([]);
      if (state === null) {
        if (personaValueList) {
          let chat = await axiosApi.post(`${postUrl.chartChatHistory}`, {
            email_id: userEmail,
            session_id: currentPath,
            persona: personaValueList.label,
          });
          setLoading(false);
          let chartValue = chat.data;
          let showValue = chartValue.map((e) => ({
            user: e.user,
            chartData: e.data,
            layout: e.layout,
          }));
          setData(showValue);
        } else {
          setData([]);
          setLoading(false);
        }
      } else {
        setLoading(false);
        if (state.sessionslist.includes(state.chatsessionId)) {
          toast.error();
          navigate("/textchartquery");
        }
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    chatHistory();
  }, [currentPath]);

  const handleGenerateChart = async () => {
    if (personaValueList) {
      if (inputValue !== "") {
        setInputValue("");
        setGenerating(true);
        try {
          let persona = personaValueList.label; // Extracting label from personaValueList for persona
          let personaTables = personaValueList.value;

          let payload = {
            user_query: inputValue,
            persona_tables: [personaTables],
            persona: persona,
            email_id: userEmail,
            session_id: currentPath,
          };
          let apiRes = await getTexttoDashboard(payload);

          if (apiRes && apiRes.data) {
            setChartData(apiRes);

            // Make the chart result API call to store the response in the database
            let storePayload = {
              email_id: userEmail,
              user_query: inputValue,
              persona: persona,
              session_id: currentPath,
              full_data: apiRes?.data,
              layout: apiRes?.layout,
            };

            await updateChartResponseInHistory(storePayload);

            setData((e) => [
              ...e,
              {
                user: inputValue,
                chartData: apiRes?.data,
                layout: apiRes?.layout,
              },
            ]);
            setGenerating(false);
          } else {
            if (!apiRes) {
              setGenerating(false);
              toast.error("Apologies, Unable to process. Please try again.");
            } else if (!apiRes.data) {
              setGenerating(false);
              toast.error(
                "Apologies, your request is too long. Please try asking a shorter question or rephrase your input."
              );
            }
          }
        } catch (err) {
          setGenerating(false);
          console.log(err);
        }
      }
    } else {
      toast.error(errorStr.selectPersona);
    }
  };

  return (
    <div className="mainContent">
      {data && data?.length > 0 ? (
        <div className="prompt">
          {data &&
            data?.map((e, i) => (
              <div key={i}>
                <div className="questions">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="29.178"
                    height="29.179"
                    viewBox="0 0 33.178 33.179"
                  >
                    <g id="user" transform="translate(0 0)">
                      <path
                        id="Path_12221"
                        data-name="Path 12221"
                        d="M15.181,29l-4.95,2.7a4.448,4.448,0,0,0-.794.565,16.579,16.579,0,0,0,21.3.055,4.391,4.391,0,0,0-.873-.585l-5.3-2.65a2.021,2.021,0,0,1-1.117-1.808V25.2a8.005,8.005,0,0,0,.5-.645,12.2,12.2,0,0,0,1.648-3.32,1.665,1.665,0,0,0,1.181-1.584v-2.22a1.657,1.657,0,0,0-.555-1.23V12.995S26.883,8,20.12,8s-6.1,4.994-6.1,4.994V16.2a1.655,1.655,0,0,0-.555,1.23v2.22a1.664,1.664,0,0,0,.768,1.4A11.016,11.016,0,0,0,16.236,25.2v2.028A2.023,2.023,0,0,1,15.181,29Z"
                        transform="translate(-3.529 -2.992)"
                        fill="#e7eced"
                      />
                      <g
                        id="Group_1134"
                        data-name="Group 1134"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Path_12222"
                          data-name="Path 12222"
                          d="M16.873,0A16.579,16.579,0,0,0,5.915,29.273a4.408,4.408,0,0,1,.787-.56l4.95-2.7a2.022,2.022,0,0,0,1.054-1.775V22.21A11.006,11.006,0,0,1,10.7,18.058a1.665,1.665,0,0,1-.768-1.4v-2.22a1.657,1.657,0,0,1,.555-1.23V10s-.659-4.994,6.1-4.994S22.693,10,22.693,10v3.209a1.655,1.655,0,0,1,.555,1.23v2.22a1.665,1.665,0,0,1-1.181,1.584,12.2,12.2,0,0,1-1.648,3.32,8.005,8.005,0,0,1-.5.645v2.08A2.021,2.021,0,0,0,21.035,26.1l5.3,2.65a4.414,4.414,0,0,1,.87.583A16.586,16.586,0,0,0,16.873,0Z"
                          transform="translate(0 0)"
                          fill="#62b5b4"
                        />
                      </g>
                    </g>
                  </svg>
                  &ensp;&ensp;
                  <div>{e.user}</div>
                </div>
                <div className="answers">
                  <div className="ml-1 robot">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29.182"
                      height="29.179"
                      viewBox="0 0 33.182 33.179"
                    >
                      <g id="bot" transform="translate(0 -0.019)">
                        <g id="Page-1_16_" transform="translate(0 0.019)">
                          <g id="_x30_17---Chat-Bot" transform="translate(0 0)">
                            <path
                              id="Shape_96_"
                              d="M232.268,284.214a1.716,1.716,0,0,0,1.716-1.716h-3.432A1.716,1.716,0,0,0,232.268,284.214Z"
                              transform="translate(-215.676 -264.192)"
                              fill="#4c85f7"
                            />
                            <circle
                              id="Oval_29_"
                              cx="0.572"
                              cy="0.572"
                              r="0.572"
                              transform="translate(21.741 14.873)"
                              fill="#4c85f7"
                            />
                            <path
                              id="Shape_95_"
                              d="M128.378,167.739h-6.865a5.721,5.721,0,1,0,0,11.441h6.865a5.721,5.721,0,1,0,0-11.441Zm-9.153,6.293a1.716,1.716,0,1,1,1.716-1.716A1.716,1.716,0,0,1,119.225,174.032Zm5.721,4a2.86,2.86,0,0,1-2.86-2.86,1.148,1.148,0,0,1,1.144-1.144h3.432a1.148,1.148,0,0,1,1.144,1.144A2.86,2.86,0,0,1,124.946,178.036Zm5.721-4a1.716,1.716,0,1,1,1.716-1.716A1.716,1.716,0,0,1,130.667,174.032Z"
                              transform="translate(-108.354 -156.87)"
                              fill="#4c85f7"
                            />
                            <path
                              id="Shape_94_"
                              d="M164.834,408.372v3.089a16.389,16.389,0,0,1-6.55,1.482c-.1.006-.212.006-.315.006h-.023a16.582,16.582,0,0,1-6.842-1.487v-3.089a2.288,2.288,0,0,1,2.288-2.288h1.716v2.288a2.86,2.86,0,0,0,2.86,2.86,2.505,2.505,0,0,0,.292-.017,2.856,2.856,0,0,0,2.569-2.843v-2.288h1.716A2.288,2.288,0,0,1,164.834,408.372Z"
                              transform="translate(-141.377 -379.769)"
                              fill="#4c85f7"
                            />
                            <circle
                              id="Oval_28_"
                              cx="0.572"
                              cy="0.572"
                              r="0.572"
                              transform="translate(10.299 14.873)"
                              fill="#4c85f7"
                            />
                            <path
                              id="Shape_93_"
                              d="M230.552,408.373a1.716,1.716,0,1,0,3.432,0v-2.288h-3.432Z"
                              transform="translate(-215.676 -379.77)"
                              fill="#4c85f7"
                            />
                            <path
                              id="Shape_92_"
                              d="M34.182,16.609A16.586,16.586,0,0,1,25.6,31.139V28.622a3.432,3.432,0,0,0-2.094-3.158c4.382-1.5,6.67-4.908,6.67-10.1a13.988,13.988,0,0,0-1.144-5.623V6.312a.572.572,0,0,0-1.144,0V7.667a12.257,12.257,0,0,0-10.3-5.36,12.258,12.258,0,0,0-10.3,5.36V6.312a.572.572,0,0,0-.572-.572.585.585,0,0,0-.475.252.558.558,0,0,0-.1.32V9.738a13.987,13.987,0,0,0-1.144,5.623c0,5.194,2.288,8.6,6.67,10.1a3.432,3.432,0,0,0-2.094,3.158v2.517a16.59,16.59,0,1,1,24.6-14.53Z"
                              transform="translate(-1 -0.019)"
                              fill="#4c85f7"
                            />
                            <path
                              id="Shape_91_"
                              d="M91.924,52.981c-6.521,0-11.441,5.12-11.441,11.91,0,6.522,3.85,9.828,11.441,9.828s11.441-3.307,11.441-9.828C103.366,58.1,98.446,52.981,91.924,52.981Zm-.572,2.288H92.5a.572.572,0,1,1,0,1.144H91.352a.572.572,0,1,1,0-1.144Zm-1.144,1.716h3.432a.572.572,0,1,1,0,1.144H90.208a.572.572,0,0,1,0-1.144ZM95.357,73H88.492a6.865,6.865,0,0,1,0-13.73h6.865a6.865,6.865,0,0,1,0,13.73Z"
                              transform="translate(-75.332 -49.549)"
                              fill="#4c85f7"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  &ensp;
                  <div className="sentences">
                    <Plot
                      data={e.chartData}
                      layout={e.layout}
                      config={{
                        ...e.config,
                        displaylogo: false, // Set displaylogo to false to hide the Plotly logo
                        modeBarButtonsToRemove: ["lasso2d", "select2d"], // Remove Lasso Select and Box Select buttons
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <>
          {state === null ? (
            loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  top: "50%",
                  left: "50%",
                  height: "79.5vh",
                }}
                className="prompt"
              >
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="40"
                  visible={true}
                />
              </div>
            ) : (
              // <div className="history">
              //   <div className="defaultPlaceholder">
              //     <img src={bot} alt="bot" />
              //     <p className="mt-4">Hello {userEmail?.split("@")[0]}</p>
              //     How can I help you today?
              //   </div>
              // </div>
              <ChatBot userName={userEmail?.split("@")[0]} />
            )
          ) : (
            <div className="history">
              <div className="defaultPlaceholder">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="71"
                  height="71"
                  viewBox="0 0 71 71"
                  fill="none"
                >
                  <path
                    d="M35.0468 0C15.6901 0 0 15.6901 0 35.0467C0 54.4033 15.6901 70.0935 35.0468 70.0935C54.4034 70.0935 70.0935 54.4033 70.0935 35.0467C70.0935 15.6901 54.4034 0 35.0468 0ZM35.0468 64.6174C18.7148 64.6174 5.47606 51.3786 5.47606 35.0467C5.47606 18.7148 18.7148 5.47605 35.0468 5.47605C51.3787 5.47605 64.6175 18.7148 64.6175 35.0467C64.6175 51.3786 51.3787 64.6174 35.0468 64.6174Z"
                    fill="#1C274C"
                  />
                  <path
                    d="M52.5702 28.4525C52.5702 22.4181 46.6857 17.5239 39.4277 17.5239C32.1719 17.5239 26.2852 22.4181 26.2852 28.4525C26.2852 34.489 32.1719 39.381 39.4277 39.381C41.8213 39.381 44.6855 38.3179 44.6855 38.3179L51.9156 41.612L50.3776 34.5915C50.3776 34.5915 52.5702 31.1242 52.5702 28.4525ZM44.7753 35.95L43.9239 36.2623C43.2266 36.519 41.0554 37.1886 39.4276 37.1886C33.389 37.1886 28.4754 33.2698 28.4754 28.4526C28.4754 23.6355 33.389 19.7167 39.4276 19.7167C45.4661 19.7167 50.3775 23.6355 50.3775 28.4526C50.3775 29.9221 49.2459 32.2794 48.5271 33.4217L48.048 34.1811L48.8394 37.8026L44.7753 35.95Z"
                    fill="#1C274C"
                  />
                  <path
                    d="M30.666 48.1473C29.0381 48.1473 26.867 47.4777 26.1675 47.221L25.3226 46.9087L21.254 48.7611L22.0454 45.1397L21.5663 44.3803C20.8497 43.2402 19.7139 40.8807 19.7139 39.4112C19.7139 36.348 21.7096 33.6591 24.7108 32.0998C24.4712 31.3896 24.2916 30.6581 24.1931 29.9072C20.2144 31.7832 17.5234 35.3341 17.5234 39.4112C17.5234 42.0808 19.7139 45.5504 19.7139 45.5504L18.178 52.5708L25.408 49.2767C25.408 49.2767 28.2723 50.3399 30.6658 50.3399C37.2949 50.3399 42.7645 46.1964 43.663 40.9301C42.9785 41.1141 42.1635 41.2917 41.2992 41.4157C40.1549 45.2659 35.8382 48.1473 30.666 48.1473Z"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="33.7"
                    cy="27.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="38.7996"
                    cy="27.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="43.8992"
                    cy="27.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="25.7"
                    cy="39.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <ellipse
                    cx="30.7996"
                    cy="39.7"
                    rx="1.7"
                    ry="1.7"
                    fill="#1C274C"
                  />
                  <path
                    d="M35.8992 41.57C36.932 41.57 37.7692 40.7328 37.7692 39.7C37.7692 38.6672 36.932 37.83 35.8992 37.83C34.8664 37.83 34.0292 38.6672 34.0292 39.7C34.0292 40.7328 34.8664 41.57 35.8992 41.57Z"
                    fill="#1C274C"
                    stroke="white"
                    stroke-width="0.34"
                  />
                </svg>
                <p>Output of the query will be shown here </p>
              </div>
            </div>
          )}
        </>
      )}
      {generating && (
        <div
          style={{
            display: "flex",
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            left: "40%",
            top: "82%",
            zIndex: 1000,
            border: "1px solid #ccc",
            backgroundColor: "whitesmoke",
            borderRadius: "8px",
            padding: "5px 13px",
            minWidth: "25px",
          }}
        >
          Generating...{" "}
          <Comment
            visible={true}
            height="30"
            width="30"
            ariaLabel="comment-loading"
            wrapperStyle={{}}
            wrapperClass="comment-wrapper"
            color="white"
            backgroundColor="#4C85F7"
          />
        </div>
      )}
      <div className="query">
        <div class="input-container">
          <input
            type="text"
            className="input-field1"
            value={inputValue}
            style={{ paddingLeft: "1%" }}
            placeholder="Type your query here"
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleGenerateChart();
              }
            }}
            autoFocus
          />
          <span
            className="icon1 "
            style={{
              cursor: inputValue !== "" ? "pointer" : "not-allowed",
            }}
            onClick={handleGenerateChart}
          >
            <img src={arrow} alt="bot" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ExicistingDynamicChart;
