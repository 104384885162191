import React, { useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { commonStr } from "../../utils/constants/commonStrings";
import "./styles.scss";
import CommonBtn from "../commonBtn";

const VideModal = ({
  showModal,
  onCancel,
  onConfirm,
  onCancelTitle = commonStr.cancel,
  onConfirmTitle = commonStr.confirm,
  confirmHide = true,
  videoUrl,
  startTime,
  endTime,
  isVideoTitle,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const handlePlay = async () => {
        try {
          if (startTime || endTime) {
            const videoStartTime = startTime || endTime;
            video.currentTime = videoStartTime;
          }
          await video.play();
        } catch (error) {
          console.error("Failed to play the video:", error);
        }
      };

      handlePlay();
    }

    return () => {
      if (video) {
        video.pause();
      }
    };
  }, [startTime, endTime]);

  return (
    <Modal
      show={showModal}
      size="md"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="video">
        <Modal.Title className={`video__modal-title`}>
          {isVideoTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`video__modal-content`}>
        <video muted autoPlay ref={videoRef} controls width="100%" height="360">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Modal.Body>
      <Modal.Footer>
        <CommonBtn title={onCancelTitle} onClick={onCancel} className="mr-2" />
      </Modal.Footer>
    </Modal>
  );
};

export default VideModal;
