import React, { useContext, useState, useEffect } from "react";
import {
  ChatBot,
  SearchInputArrowBtn,
  CommonGenerateBtn,
  CommonPromChat,
} from "../../common";
import { MyContext } from "../../context/ContextApi";
import { commonStr } from "../../utils/constants/commonStrings";
import { errorStr } from "../../utils/constants/errorStrings";
import { showToast } from "../../utils/helpers/helpers";
import axios from "axios";
import uuid from "react-uuid";

function OmniLens() {
  const {
    filterContent,
    newQuery,
    setCurretSession,
    currentSession,
    setSessionUpdate,
  } = useContext(MyContext);
  const [inputValue, setInputValue] = useState("");
  const [generating, setGenerating] = useState(false);
  const [data, setData] = useState([]);
  const [sessionName, setSessionName] = useState(uuid());

  const email = sessionStorage.getItem("email");

  useEffect(() => {
    setData([]);
    setSessionName(uuid());
    setCurretSession("");
  }, [newQuery]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };

  const generatePrompt = async () => {
    if (inputValue === "") {
      showToast(errorStr.pleaseEnterQuery);
    } else {
      setInputValue("");
      setGenerating(true);
      let body = {
        question: inputValue,
        session_id: sessionName,
        email_id: email,
      };
      try {
        let res = await axios.post(
          "https://dev-geninsights.agilisiumtech.com:456/query_endpoint/main_query/",
          body
        );
        if (res.data) {
          setData((prev) => [
            ...prev,
            {
              id: data.length + 1,
              user: inputValue,
              AI: res?.data?.summary,
              assist_agent: res?.data?.assist_agent,
              source: res?.data?.source,
              table: res?.data?.table,
            },
          ]);
          if (data.length === 0) {
            setSessionUpdate((current) => !current);
          }
          if (currentSession === "") {
            setCurretSession(sessionName);
          }
        }
        setGenerating(false);
      } catch (err) {
        console.log(err);
        setGenerating(false);
      }
    }
  };

  return (
    <div className="d-flex justify-content-between postionRelative">
      <div className="mainContent w-100">
        {data.length === 0 ? (
          <ChatBot userName={filterContent?.userName} />
        ) : (
          <div className="prompt">
            {data &&
              data?.length > 0 &&
              data.map((e, i) => (
                <CommonPromChat
                  indexValue={i}
                  key={i + 1}
                  user={e.user}
                  AI={e.AI}
                  assist_agent={e.assist_agent}
                  source={e?.source}
                  table={e?.table}
                />
              ))}
          </div>
        )}
        {generating && <CommonGenerateBtn title={commonStr.searching} />}
        <SearchInputArrowBtn
          value={inputValue}
          placeholder={commonStr.typeYourQuery}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          onClick={generatePrompt}
        />
      </div>
    </div>
  );
}

export default OmniLens;
