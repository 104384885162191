import React, { useContext, useEffect, useState } from "react";
import { Table, Pagination } from "rsuite";
import { IoIosCloseCircle } from "react-icons/io";
import { PiCheckCircleFill } from "react-icons/pi";

import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { userValidation } from "../../../validation";
import edit from "../../../assets/svg/edit.svg";
import delete1 from "../../../assets/svg/delete.svg";
import { getUrl } from "../../../api/Endpoint";
import { creatUser, deleteUser } from "../../../api/Postaction";
import Edituser from "../editUser";
import axiosApi from "../../../interceptors/axiosinterceptor";
import { MyContext } from "../../../context/ContextApi";
import "./styles.scss";
import { CommonBtn, CommonModal, SearchInputBtn } from "../../../common";
import { commonStr } from "../../../utils/constants/commonStrings";

const UserComponent = () => {
  const { setIsNavMenuVisibile } = useContext(MyContext);
  const { Column, HeaderCell, Cell } = Table;
  const [modalShow, setModalShow] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [personaOptions, setPersonaOptions] = useState([]);
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState();
  const [fetch, setFetch] = useState(false);
  const [records, setRecords] = useState();
  const [deleteData, setDeleteData] = useState();
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [search, setSearch] = useState("");
  const [collection, setCollection] = useState([]);

  const [solutionList, setSolutionList] = useState([
    {
      value: "Data Lens",
      label: "Data Lens",
    },
    {
      value: "Doc Sonar",
      label: "Doc Sonar",
    },
    {
      value: "Dash Lens",
      label: "Dash Lens",
    },
    {
      value: "Literature AI",
      label: "Literature AI",
    },
    {
      value: "Gene Inspector",
      label: "Gene Inspector",
    },
    {
      value: "Content Generation",
      label: "Content Generation",
    },
    {
      value: "HCP MLT",
      label: "HCP MLT",
    },
    {
      value: "MLR Review",
      label: "MLR Review",
    },
    {
      value: "Data Detective",
      label: "Data Detective",
    },
    {
      value: "C Data Detective",
      label: "C Data Detective",
    },
    {
      value: "Call Insight",
      label: "Call Insight",
    },
  ]);

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };
  const isAdmin = [
    { value: "True", label: "True" },
    { value: "False", label: "False" },
  ];
  const { register, control, handleSubmit, formState, reset } = useForm({
    resolver: zodResolver(userValidation),
    mode: "all",
  });

  const collectionList = useController({
    name: "Collection",
    control,
  });

  const handleCollectionChange = (e) => {
    collectionList.field.onChange(e);
    // setCollection(e);
  };

  const solutionListData = useController({
    name: "Solution",
    control,
  });
  const handleSolution = (option) => {
    solutionListData.field.onChange(option);
  };

  const { errors } = formState;
  const Persona = useController({
    name: "Persona",
    control,
  });
  const handlePersona = (option) => {
    Persona.field.onChange(option);
  };
  const IsAdmin = useController({
    name: "isAdmin",
    control,
  });
  const handleAdmin = (option) => {
    IsAdmin.field.onChange(option.value);
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
    if (search !== "") {
      setSearch("");
    }
  };
  const closeUser = () => {
    deleteUser(deleteData, setFetch);
    setDeleteModal(false);
  };
  const userData = async (formValues) => {
    reset();
    setModalShow(false);

    formValues.Persona = formValues.Persona.map((e) => e.value);
    formValues.Collection = formValues.Collection.map((e) => e.value);
    formValues.Solution = formValues.Solution.map((e) => e.value);
    let createRes = await creatUser(formValues, setFetch);

    if (createRes?.status === 200) {
      setIsNavMenuVisibile((e) => !e);
    }
  };
  useEffect(() => {
    if (editData !== undefined) {
      setEditModalShow(true);
    }
  }, [editData]);

  useEffect(() => {
    if (editmodalShow === false) {
      setEditData();
    }
  }, [editmodalShow]);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const [usersResponse, personaResponse, collectionResponse] =
          await Promise.all([
            axiosApi.get(getUrl.users),
            axiosApi.get(getUrl.persona),
            axiosApi.get(getUrl.listCollection),
          ]);

        const collections = collectionResponse.data.map((e) => ({
          value: e.collection_name,
          label: e.collection_name,
        }));

        const persona = personaResponse.data.map((e) => ({
          value: e.persona_name,
          label: e.persona_name,
        }));

        const data = usersResponse.data.map((ele) => {
          const formattedData = {
            ...ele,
            persona_name:
              ele.persona_name !== null
                ? ele.persona_name.join(",")
                : "Not assigned",
            collection_name:
              ele.collection_name !== null
                ? ele.collection_name.join(",")
                : "Not assigned",
            solution_name:
              ele.solution_name !== null
                ? ele.solution_name.join(",")
                : "Not assigned",
          };
          return formattedData;
        });

        setPersonaOptions(persona);
        setData(data);
        setCollection(collections);
        setLoading(false);
      } catch (err) {
        console.log("Error in fetching data:", err);
        setLoading(false);
      }
    };

    fetchData();
  }, [fetch]);

  useEffect(() => {
    if (search !== "") {
      // const start = limit * (page - 1);
      // const end = start + limit;
      const result = data?.filter((data) => {
        return (
          data.firstname?.toLowerCase().indexOf(search?.toLowerCase()) >= 0 ||
          data.lastname?.toLowerCase().indexOf(search?.toLowerCase()) >= 0
        );
      });

      setRecords(result);
    } else {
      const res = data?.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
      setRecords(res);
    }
  }, [search]);

  useEffect(() => {
    const res = data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    setRecords(res);
  }, [data, page, limit]);

  //handleCloseModal
  const handleCancelModal = () => {
    reset();
    setModalShow(false);
  };

  const renderBooleanCell = (rowData, dataKey) => {
    const value = rowData[dataKey];
    return (
      <>
        {value ? (
          <PiCheckCircleFill color="green" size={25} />
        ) : (
          <IoIosCloseCircle color="red" size={25} />
        )}
      </>
    );
  };

  const handleDeleteIcon = (rowData) => {
    setDeleteData(rowData);
    setDeleteModal(true);
  };

  return (
    <div className="user-details">
      <SearchInputBtn
        placeholder={`${commonStr?.search}  ${commonStr?.user}`}
        btnTitle={`${commonStr?.add}  ${commonStr?.user}`}
        btnOnClick={() => setModalShow(true)}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <div className="mt-4">
        <div>
          <Table
            loading={Loading ? true : false}
            className="custom-table"
            virtualized
            wordWrap="break-word"
            hover={false}
            height={434}
            data={records}
            sortColumn={sortColumn}
            sortType={sortType}
            onSortColumn={handleSortColumn}
            style={{
              scrollbarWidth: "0",
              maxHeight: "58vh",
            }}
            align="center"
          >
            <Column flexGrow={2} fullText verticalAlign="middle">
              <HeaderCell id="header">Name</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div>{rowData.firstname + " " + rowData.lastname}</div>
                )}
              </Cell>
            </Column>

            <Column flexGrow={2} fullText verticalAlign="middle">
              <HeaderCell id="header">Email</HeaderCell>
              <Cell dataKey="email" id="content" />
            </Column>

            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Persona</HeaderCell>
              <Cell dataKey="persona_name" id="content" />
            </Column>
            <Column flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header">Collection Name</HeaderCell>
              <Cell dataKey="collection_name" id="content" />
            </Column>
            <Column flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header">isAdmin</HeaderCell>
              <Cell>{(rowData) => renderBooleanCell(rowData, "is_admin")}</Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Solution Name</HeaderCell>

              <Cell>
                {(rowData) => (
                  <div>
                    {rowData.solution_name?.length > 1
                      ? rowData.solution_name.toString()
                      : rowData.solution_name}
                  </div>
                )}
              </Cell>
            </Column>

            <Column flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header">Action</HeaderCell>
              <Cell style={{ padding: "0px", marginLeft: "0px" }} id="content">
                {(rowData) => (
                  <div className="displayFlexCenter">
                    <img
                      src={edit}
                      alt="edit"
                      className="iconHover"
                      onClick={() => setEditData(rowData)}
                    />
                    &ensp;
                    <img
                      src={delete1}
                      alt="edit"
                      className="iconHover"
                      onClick={() => handleDeleteIcon(rowData)}
                    />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ paddingTop: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={search === "" ? data.length : records.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          {" "}
          <form onSubmit={handleSubmit(userData)}>
            <Modal.Header closeButton={true}>
              <Modal.Title id="contained-modal-title-vcenter">
                Add User
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center" style={{ gap: "3%" }}>
                <div className="w-100 flex-item">
                  <div className="mb-1">
                    Persona
                    <span className="" style={{ color: "red" }}>
                      *
                    </span>{" "}
                    :
                  </div>
                  <Select
                    isMulti
                    className="w-100"
                    placeholder="Select Persona"
                    // value={allValues.Region}
                    onChange={handlePersona}
                    options={personaOptions}
                  />
                  <div style={{ color: "red" }}>{errors.Persona?.message}</div>
                </div>
                <div className="w-100 flex-item">
                  <div className="mb-1">
                    First Name
                    <span className="" style={{ color: "red" }}>
                      *
                    </span>{" "}
                    :
                  </div>
                  <input
                    type="text"
                    className="w-100"
                    {...register("Firstname")}
                    style={{ height: "38px", padding: "10px" }}
                    placeholder="First Name"
                  />
                  <div style={{ color: "red" }}>
                    {errors.Firstname?.message}
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-center mt-2"
                style={{ gap: "3%" }}
              >
                <div className="w-100 flex-item">
                  <div className="mt-2 mb-1">
                    Last Name
                    <span className="" style={{ color: "red" }}>
                      *
                    </span>{" "}
                    :
                  </div>
                  <input
                    type="text"
                    className="w-100"
                    {...register("Lastname")}
                    style={{ height: "38px", padding: "10px" }}
                    placeholder="Last Name"
                  />
                  <div style={{ color: "red" }}>{errors.Lastname?.message}</div>
                </div>
                <div className="w-100 flex-item">
                  <div className="mt-2 mb-1">
                    Email
                    <span className="" style={{ color: "red" }}>
                      *
                    </span>{" "}
                    :
                  </div>
                  <input
                    type="email"
                    className="w-100"
                    {...register("Email")}
                    style={{ height: "38px", padding: "10px" }}
                    placeholder="Email"
                  />
                  <div style={{ color: "red" }}>{errors.Email?.message}</div>
                </div>
              </div>
              <div
                className="d-flex align-items-center mt-2"
                style={{ gap: "3%" }}
              >
                <div className="w-100 flex-item">
                  <div className="mt-2 mb-1">
                    Collection
                    <span className="" style={{ color: "red" }}>
                      *
                    </span>{" "}
                    :
                  </div>
                  <Select
                    isMulti
                    // className="mt-3"
                    placeholder="Select Collection"
                    // value={allValues.Region}
                    onChange={handleCollectionChange}
                    options={collection}
                    // noOptionsMessage={() => noOptionsMessage}
                  ></Select>
                  <div style={{ color: "red" }}>
                    {errors.Collection?.message}
                  </div>
                </div>
                <div className="w-100 flex-item">
                  <div className="mt-2 mb-1">
                    Solution Name
                    <span className="" style={{ color: "red" }}>
                      *
                    </span>{" "}
                    :
                  </div>
                  <Select
                    className="w-100"
                    placeholder="Select Solution"
                    onChange={handleSolution}
                    options={solutionList}
                    isMulti
                  ></Select>
                  <div style={{ color: "red" }}>{errors.Solution?.message}</div>
                </div>
              </div>
              <div
                className="d-flex align-items-center mt-2"
                style={{ gap: "3%" }}
              >
                <div className="col-6 p-0 pr-2">
                  <div className="w-100 flex-item">
                    <div className="mt-2 mb-1">
                      Admin
                      <span className="" style={{ color: "red" }}>
                        *
                      </span>{" "}
                      :
                    </div>
                    <Select
                      className="w-100"
                      placeholder="Select Admin"
                      // value={allValues.Region}
                      onChange={handleAdmin}
                      options={isAdmin}
                      // noOptionsMessage={() => noOptionsMessage}
                    ></Select>
                    <div style={{ color: "red" }}>
                      {errors.isAdmin?.message}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <CommonBtn
                title={commonStr.cancel}
                onClick={handleCancelModal}
                className="mr-2"
              />
              <CommonBtn type="submit" title={commonStr.add} />
            </Modal.Footer>
          </form>
        </Modal>
      )}

      {editmodalShow && (
        <Edituser
          editmodalShow={editmodalShow}
          setEditModalShow={setEditModalShow}
          editData={editData}
          personaOptions={personaOptions}
          setFetch={setFetch}
          collection={collection}
        />
      )}
      {/* Delete Modal */}

      {deleteModal && (
        <CommonModal
          showModal={deleteModal}
          title={"Delete"}
          content={`Are you sure want to delete this User "${deleteData?.firstname}"   ?`}
          onCancel={() => setDeleteModal(false)}
          onConfirm={closeUser}
        />
      )}
    </div>
  );
};

export default UserComponent;
