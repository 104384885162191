import React, { useState, useEffect, useContext } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import {
  generateContentUpload,
  generateExtractInfo,
  generateFileLists,
  generateContentDocument,
  generateContentReference,
  // generateRagaScore, // hide
} from "../../api/Postaction";
import { downloadFileContent } from "../../api/GetActions";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import Markdown from "markdown-to-jsx";
// import showdown from "showdown";
// import html2pdf from "html2pdf.js";
// import htmlToDocx from "html-docx-js/dist/html-docx";
// import { saveAs } from "file-saver";
import "./contentGeneration.css";
import { MyContext } from "../../context/ContextApi";
import { CommonLoader } from "../../common";
import { Sourcebar } from "../../common";

const ContentGeneration = () => {
  const { newQuery, setFileContentList } = useContext(MyContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedTemplateFile, setSelectedTemplateFile] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [fileURL, setFileURL] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [fileExtractcontent, setFileExtractcontent] = useState("");
  const [fileList, setFileList] = useState([]);
  // const [ragaScore, setRagaScore] = useState(""); // hide
  const [editableData, setEditableData] = useState([]);
  const [documentContent, setDocumentContent] = useState("");
  const [documentContentText, setDocumentContentText] = useState("");
  const [isDownloadEnabled, setDownloadEnabled] = useState(false); // State to track the download button's enabled/disabled state
  const [showOptions, setShowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);
  const [isReferenceGenerated, setIsReferenceGenerated] = useState(false);
  const [showEditSaveButtons, setShowEditSaveButtons] = useState(false);
  const [isExtractEnabled, setIsExtractEnabled] = useState(false);

  const userName = sessionStorage.getItem("firstname");

  useEffect(() => {
    setSelectedFile(null);
    setSelectedTemplateFile(null);
    setShowButton(false);
    setFileURL("");
    setFileType("");
    setFileContent("");
    setFileExtractcontent("");
    setEditableData([]);
    setFileList([]);
    setDocumentContent("");
    setDownloadEnabled(false);
    setShowOptions(false);
    setShowEditSaveButtons(false);
    setIsExtractEnabled(false);
    const fileInput = document.getElementById("fileInput");
    fileInput.value = null;
    const fileExtractInput = document.getElementById("fileExtractInput");
    fileExtractInput.value = null;
  }, [newQuery]);

  useEffect(() => {
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setFileURL(url);
      setFileType(selectedFile.type);

      // Call the API to upload the selected file
      const uploadFile = async () => {
        setIsLoading(true);
        try {
          const result = await generateContentUpload(selectedFile, userName);
          setFileContent(result?.markdown_content);
          setDocumentContentText(result?.Extracted_fields);
        } catch (error) {
          console.error("Error uploading file:", error);
        } finally {
          setIsLoading(false); // Set loading to false after upload is complete
        }
      };

      uploadFile();

      // Cleanup function to revoke the object URL when a new file is selected
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [selectedFile]);

  useEffect(() => {
    if (selectedTemplateFile) {
      const uploadFileTemp = async () => {
        try {
          await generateExtractInfo(selectedTemplateFile, userName);

          //filelist
          const response = await generateFileLists(userName);
          console.log("File upload list", response);
          setFileList(response?.response);
          setFileContentList(response?.response);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      };

      uploadFileTemp();
    } else {
      setIsReferenceGenerated(false); // Disable the "Generate Document" button if selectedTemplateFile is null
    }
  }, [selectedTemplateFile]);

  const handleExtractClick = async () => {
    try {
      setFileExtractcontent("");
      setIsReferenceGenerated(false);
      setIsExtractEnabled(false);
      setShowEditSaveButtons(true);

      setIsExtracting(true);

      const extractedFields = documentContentText;
      let body = {
        extracted_field_list: extractedFields,
        collection_name: userName,
      };
      const res = await generateContentReference(body);
      setFileExtractcontent(res); // raga score and fileExtractcontent

      // raga score
      // let resBody = {
      //   info_dict: res,
      //   collection_name: userName,                         // hide, when client want this show
      // };
      // const scoreResult = await generateRagaScore(resBody);
      // console.log("Score range", scoreResult);
      // setRagaScore(scoreResult);

      setIsReferenceGenerated(true);
      setIsExtractEnabled(true);
    } catch (error) {
      console.error("Error in extracted file:", error);
    } finally {
      setIsExtracting(false);
    }
  };

  const handleBrowseFilesClick = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const handleFilesContentExtractClick = () => {
    const fileExtractInput = document.getElementById("fileExtractInput");
    fileExtractInput.click();
  };

  const handleTemplateFileChange = async (event) => {
    //const file = event.target.files[0];
    const files = Array.from(event.target.files);
    setSelectedTemplateFile(files);
    setShowButton(selectedFile !== null);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setShowButton(selectedTemplateFile !== null);

    if (file.type === "application/pdf") {
      const url = URL.createObjectURL(file);
      setFileURL(url);
      setFileType("application/pdf");
    }
  };

  const handleGenerateClick = async () => {
    try {
      const requestBody = {
        info_dict: fileExtractcontent,
        collection_name: userName,
      };
      const response = await generateContentDocument(requestBody);
      if (response) {
        setDocumentContent(response);
        setDownloadEnabled(true);
      } else {
        console.error("Invalid response format:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClose = () => {
    setSelectedFile(null);
    setFileURL("");
    setFileType("");
    setShowButton(false);
    setDocumentContent("");
    const fileInput = document.getElementById("fileInput");
    fileInput.value = null;
  };

  const handleResClose = () => {
    setSelectedTemplateFile(null);
    setShowButton(false);
    setDocumentContent("");
    const fileExtractInput = document.getElementById("fileExtractInput");
    fileExtractInput.value = null;
  };

  const handleFilterContentEdit = () => {
    if (typeof fileExtractcontent === "object" && fileExtractcontent !== null) {
      const newData = Object.entries(fileExtractcontent).map(
        ([key, value]) => ({ key: key, value: value })
      );
      setEditableData(newData);
    } else {
      console.error("Invalid fileExtractcontent data:", fileExtractcontent);
      // Handle the case where fileExtractcontent is not in the expected format
    }
  };

  const handleFilterContentSave = () => {
    try {
      const updatedData = editableData
        .map((item) => {
          if (
            typeof item === "object" &&
            item !== null &&
            item.key &&
            item.value
          ) {
            // Escape double quotes in the value to prevent JSON formatting issues
            const escapedValue = item.value.replace(/"/g, '\\"');
            return `"${item.key}": "${escapedValue}"`;
          } else {
            return null; // Return null for invalid items
          }
        })
        .filter((item) => item !== null); // Filter out null items

      const jsonFormat = `{${updatedData.join(", ")}}`; // Join valid items and wrap them in curly braces

      // Parse the JSON string
      const parsedJson = JSON.parse(jsonFormat);

      setFileExtractcontent(parsedJson); // Update original data with edited data
      setEditableData([]); // Clear editable data after saving
    } catch (error) {
      console.error("Error saving content:", error);
    }
  };

  const handleFilterContentChange = (index, newValue) => {
    setEditableData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, value: newValue } : item
      )
    );
  };

  const handleDownloadClick = async () => {
    setShowOptions(!showOptions);
    try {
      await downloadFileContent(userName);
    } catch (err) {
      console.error("Error fetching blob data:", err);
    }
  };

  // const downloadDOCX = async () => {
  //   try {
  //     if (!documentContent || documentContent.trim() === "") {
  //       console.error("Error: No Markdown content available");
  //       return;
  //     }

  //     // Convert Markdown to HTML
  //     const converter = new showdown.Converter();
  //     const htmlContent = converter.makeHtml(documentContent);

  //     // Add table border styles to HTML content
  //     const htmlWithBorders = htmlContent.replace(
  //       /<table/g,
  //       '<table style="border-collapse: collapse; border: 1px solid black;"'
  //     );

  //     // Add styles to table rows and header cells
  //     const styledHtmlContent = htmlWithBorders
  //       .replace(/<th/g, '<th style="border: 1px solid black;"')
  //       .replace(/<td/g, '<td style="border: 1px solid black; padding: 8px;"');

  //     // Convert HTML to DOCX
  //     const docxContent = htmlToDocx.asBlob(styledHtmlContent);

  //     // Save DOCX file
  //     saveAs(docxContent, "converted_document.docx");
  //   } catch (error) {
  //     console.error("Error converting Markdown to DOCX:", error);
  //   }
  // };

  // const downloadPDF = () => {
  //   if (!documentContent) {
  //     return;
  //   }

  //   // Convert Markdown to HTML
  //   const converter = new showdown.Converter();
  //   const htmlContent = converter.makeHtml(documentContent);

  //   // Create a PDF from the HTML content
  //   html2pdf().from(htmlContent).save("markdown_document.pdf");
  // };

  return (
    <div className="d-flex justify-content-between postionRelative">
      <div className="contentScroll mainContent">
        <div className="container">
          <div className="d-flex justify-content-center my-5">
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <h5 className="d-flex mt-3 mb-2">Upload your Template:</h5>
                </div>
                <div className="col-12">
                  <p className="d-flex uploadText">
                    Upload your template DOCX file
                  </p>
                  <div className="card contentCard">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-1">
                          <CloudUploadOutlinedIcon
                            fontSize="large"
                            color="primary"
                          />
                        </div>
                        <div className="col-8">
                          <h6 className="dragText">Drag and Drop file here</h6>
                          <p className="limitText">
                            Limit 200MB per file. DOCX
                          </p>
                        </div>
                        <div className="col-3">
                          <button
                            className="btn-sm btn-light btnLight float-right"
                            type="button"
                            onClick={handleBrowseFilesClick}
                          >
                            Browse files
                          </button>
                          <input
                            id="fileInput"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                            accept={".docx"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedFile && (
                    <div>
                      <div className="col-12 fileName">
                        <div className="row">
                          <div className="col-1">
                            <InsertDriveFileOutlinedIcon color="primary" />
                          </div>
                          <div className="col-10">
                            <p>{selectedFile.name}</p>
                          </div>
                          <div className="col-1">
                            <CloseOutlinedIcon
                              className="iconHover"
                              fontSize="small"
                              onClick={handleClose}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <h5 className="d-flex mt-3 mb-2">Template:</h5>
                      </div>
                      <div className="card contentCard uploadContentScroll">
                        <div className="col-12 markdown-content">
                          {isLoading ? (
                            <CommonLoader />
                          ) : fileType === "application/pdf" ? (
                            <DocViewer
                              pluginRenderers={DocViewerRenderers}
                              documents={[{ uri: fileURL }]}
                              config={{
                                header: {
                                  disableHeader: false,
                                  disableFileName: false,
                                  retainURLParams: false,
                                },
                              }}
                            />
                          ) : (
                            <Markdown>{fileContent}</Markdown>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12">
                  <h5 className="d-flex mb-2">Upload your Info Doc:</h5>
                </div>
                <div className="col-12">
                  <p className="d-flex uploadText">
                    Upload your source file in PDF file
                  </p>
                  <div className="card contentCard">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-1">
                          <CloudUploadOutlinedIcon
                            fontSize="large"
                            color="primary"
                          />
                        </div>
                        <div className="col-8">
                          <h6 className="dragText">Drag and Drop file here</h6>
                          <p className="limitText">Limit 200MB per file. PDF</p>
                        </div>
                        <div className="col-3">
                          <button
                            className="btn-sm btn-light btnLight float-right"
                            type="button"
                            onClick={handleFilesContentExtractClick}
                          >
                            Browse files
                          </button>
                          <input
                            id="fileExtractInput"
                            type="file"
                            style={{ display: "none" }}
                            onChange={handleTemplateFileChange}
                            accept={".pdf"}
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedTemplateFile && (
                    <div>
                      <div className="col-12 fileName">
                        <div className="row">
                          {/* <div className="col-1">
                            <InsertDriveFileOutlinedIcon color="primary" />
                          </div>
                          <div className="col-10">
                            {selectedTemplateFile.map((file, index) => (
                              <p key={index}>{file.name}</p>
                            ))}
                          </div>
                          <div className="col-1">
                            <CloseOutlinedIcon
                              className="iconHover"
                              fontSize="small"
                              onClick={handleResClose}
                            />
                          </div> */}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row">
                          {selectedFile &&
                            selectedTemplateFile &&
                            fileList.length > 0 && (
                              <div className="col-10">
                                <button
                                  className="btn-sm btn-light btnLight"
                                  type="button"
                                  onClick={handleExtractClick}
                                >
                                  Extract
                                </button>
                              </div>
                            )}
                          {showEditSaveButtons && (
                            <div className="col-2 mt-3 p-0">
                              <button
                                type="button"
                                className="sm-btn btn-primary"
                                onClick={handleFilterContentEdit}
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="sm-btn btn-success"
                                onClick={handleFilterContentSave}
                              >
                                save
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      {showEditSaveButtons && (
                        <div className="card contentCard uploadContentScroll">
                          <div
                            className={` col-12 ${
                              !fileExtractcontent && "displayFlexCenter"
                            } `}
                          >
                            {editableData.length > 0 ? (
                              <div>
                                {editableData.map((item, index) => (
                                  <div className="pb-4" key={index}>
                                    <strong>{item.key}:</strong>{" "}
                                    <input
                                      className="editContent"
                                      type="text"
                                      value={item.value}
                                      onChange={(e) =>
                                        handleFilterContentChange(
                                          index,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div>
                                {fileExtractcontent ? (
                                  <div>
                                    {Object.entries(fileExtractcontent).map(
                                      ([key, value], index) => (
                                        <div className="pb-2" key={index}>
                                          <strong>{key}:</strong>{" "}
                                          {value ? value : " "}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="">
                                    <CommonLoader />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {/* {ragaScore && (
                        //   <div className="d-flex justify-content-between uploadScoreCard">
                        //     <span className="uploadRagaScore">
                        //       <strong>Answer Relevancy:</strong>
                        //       <span>{ragaScore?.answer_relevancy}</span>
                        //     </span>
                        //     <span className="uploadRagaScore">
                        //       <strong>Faithfulness:</strong>{" "}
                        //       <span>{ragaScore?.faithfulness}</span>
                        //     </span>
                        //   </div>
                        // )} */}
                    </div>
                  )}
                </div>
              </div>
              {showButton && isExtractEnabled && (
                <div className="row">
                  <div className="col-12">
                    <h5 className="d-flex mt-3 mb-2">Generate Document:</h5>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-10">
                          <button
                            className="btn-sm btn-light btnLight"
                            type="button"
                            onClick={handleGenerateClick}
                            disabled={!isReferenceGenerated}
                          >
                            Generate
                          </button>
                        </div>
                        <div className="col-2 mt-1 p-0 download-btn-container">
                          <CloudDownloadOutlinedIcon
                            fontSize="large"
                            color={isDownloadEnabled ? "primary" : "disabled"}
                            onClick={
                              isDownloadEnabled
                                ? handleDownloadClick
                                : undefined
                            }
                            style={{
                              cursor: isDownloadEnabled
                                ? "pointer"
                                : "not-allowed",
                            }}
                          />
                          {/* {showOptions && (
                          <div className="download-btn-box">
                            <button
                              className="download-btn"
                              onClick={downloadPDF}
                            >
                              PDF
                            </button>
                            <button
                              className="download-btn"
                              onClick={downloadDOCX}
                            >
                              DOCX
                            </button>
                          </div>
                        )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="col-12">
                      {documentContent && (
                        <div className="card contentCard uploadContentScroll markdown-content">
                          <Markdown>{documentContent}</Markdown>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Sourcebar fileList={fileList} collectionName={userName} />
    </div>
  );
};

export default ContentGeneration;
