import React, { useRef, useState } from 'react';
import { FaMicrophone, FaStop } from 'react-icons/fa';

const AudioRecorder = ({onRecordingStop, handleToStopAudio}) => {
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const [isRecording, setIsRecording] = useState(false);
    
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorder.current = new MediaRecorder(stream);

            mediaRecorder.current.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    audioChunks.current.push(e.data);
                }
            };

            mediaRecorder.current.onstop = () => {
                console.log('Audio data:', audioChunks.current);
                // Send the audio data to the API
                onRecordingStop(audioChunks.current);
                // sendAudioToAPI(audioChunks.current);
                audioChunks.current = [];
                setIsRecording(false); // Recording is stopped
            };

            mediaRecorder.current.start();
            setIsRecording(true); // Recording is active
        } catch (error) {
            console.error('Error starting recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
            mediaRecorder.current.stop();
            handleToStopAudio(audioChunks.current);
            audioChunks.current = [];
            setIsRecording(false); // Recording is stopped
        }
    };

return (
        <div>
            {isRecording ? (
                <button onClick={stopRecording}>
                    <FaStop />
                </button>
            ) : (
                <button onClick={startRecording}>
                    <FaMicrophone />
                </button>
            )}
        </div>
    );
};

export default AudioRecorder;