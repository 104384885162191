import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { commonStr } from "../../utils/constants/commonStrings";

function CommonExpBtn({ handleClickCsv, downloadAsTsv }) {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="Secondary" id="dropdown-basic">
        {commonStr.export}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={handleClickCsv}>{commonStr.csv}</Dropdown.Item>
        <Dropdown.Item onClick={downloadAsTsv}>{commonStr.tsv}</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default CommonExpBtn;
