import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../context/ContextApi";
import axios from "axios";

import { showToast } from "../../utils/helpers/helpers";
import {
  ChatBot,
  CommonGenerateBtn,
  CommonLoader,
  CommonPromChat,
  SearchInputArrowBtn,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import axiosApi from "../../interceptors/axiosinterceptor";
import { errorStr } from "../../utils/constants/errorStrings";
import uuid from "react-uuid";

const ExcistingOmnilens = () => {
  const {
    // allValues,
    newQuery,
    setCurretSession,
    setSessionUpdate,
    currentSession,
  } = useContext(MyContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  let state = location.state;
  const [inputValue, setInputValue] = useState("");
  const [generating, setGenerating] = useState(false);
  const [data, setData] = useState([]);
  const [sessionName, setSessionName] = useState(uuid());

  let email = sessionStorage.getItem("email");

  let currentPath = location.pathname.split("/")[2];

  if (currentPath.includes("%20")) {
    currentPath = currentPath.replace("%20", " ");
  }

  useEffect(() => {
    setData([]);
    setSessionName(uuid());
    setCurretSession("");
  }, [newQuery]);


  useEffect(() => {
    history();
  }, [currentPath, state])

  const history = async () => {
    // if (allValues !== undefined) {
      setLoading(true);
      try {
        setData([]);
        if (state === null) {
          let chat = await axiosApi.post(
            `https://dev-geninsights.agilisiumtech.com:456/sessions_endpoint/display_doc_search_session_chat`,
            {
              email_id: email,
              session_id: currentPath,
            }
          );
          setLoading(false);
          let a = chat.data;
          console.log(a, 'a');
          let b = a.map((e, index) => ({
            id: index + 1,  
            user: e.user,   
            AI: e.AI,
            source: e.source,
        }));
        
          setData(b);
        } else {
          setData([]);
          setLoading(false);
          if (state.omnilenssessionslist.includes(state.sessionId)) {
            showToast(errorStr.sessionNameAlready);
            navigate("/omnilens");
          }
          setData([]);
        }
      } catch (err) {
        console.log("Error in getting Accessable Table", err);
      }
    // } 
    // else {
    //   navigate("/omnilens");
    // }
  };

  const generatePrompt = async () => {
    if (inputValue === "") {
      showToast(errorStr.pleaseEnterQuery);
    } else {
      setInputValue("");
      setGenerating(true);
      let body = {
        question: inputValue,
        session_id: sessionName,
        email_id: email,
      };
      try {
        let res = await axios.post(
          "https://dev-geninsights.agilisiumtech.com:456/query_endpoint/main_query/",
          body
        );
        if (res.data) {
          setData((prev) => [
            ...prev,
            {
              id: data.length + 1,
              user: inputValue,
              AI: res?.data?.summary,
              assist_agent: res?.data?.assist_agent,
              source: res?.data?.source,
              table: res?.data?.table,
            },
          ]);
          if (data.length === 0) {
            setSessionUpdate((current) => !current);
          }
          if (currentSession === "") {
            setCurretSession(sessionName);
          }
        }
        setGenerating(false);
      } catch (err) {
        console.log(err);
        setGenerating(false);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };
console.log(data, 'data');
  return (
    <div className="d-flex justify-content-between postionRelative">
      <div className="mainContent w-100">
        {data?.length > 0 ? (
          <div className="prompt">
            {data &&
              data?.length > 0 &&
              data?.map((e, i) => (
                <CommonPromChat
                  indexValue={i}
                  key={i + 1}
                  user={e.user}
                  AI={e.AI}
                  assist_agent={e.assist_agent}
                  source={e?.source}
                  table={e?.table}
                />
              ))}
          </div>
        ) : (
          <>
            {loading ? (
              <CommonLoader />
            ) : (
              <ChatBot userName={email?.split("@")[0]} />
            )}
          </>
        )}
        {generating && <CommonGenerateBtn title={commonStr.generating} />}

        <SearchInputArrowBtn
          value={inputValue}
          placeholder={commonStr.typeYourQuery}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          inputValue={inputValue}
          onClick={generatePrompt}
        />
      </div>
    </div>
  );
};

export default ExcistingOmnilens;
