import React, { useContext, useEffect, useState } from "react";
import "./DashboardQuery.css";
import {
  audioGenerate,
  dashboardAudioHistory,
  dashboardPrompt,
  feedBack,
  sendEmail,
  sendDetailInWhatsApp,
  summarizeDashboard,
  generateAudioForDash,
} from "../../api/Postaction";
import { useLocation } from "react-router-dom";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { dashboardDetails } from "../../validation";
import { BsShare } from "react-icons/bs";
import axios from "axios";
import { getUrl } from "../../api/Endpoint";
import { toast } from "react-toastify";
import { MyContext } from "../../context/ContextApi";
import uuid from "react-uuid";

import {
  ChatBot,
  CommonArrowCircle,
  CommonBtn,
  CommonFeedback,
  CommonGenerateBtn,
  CommonLoader,
  CommonPromChat,
  CommonSendMail,
  CommonShareWhatsApp,
  LabelWithInput,
  LabelWithSelect,
  SearchInputArrowBtn,
  ThreeDotSpinner,
} from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import { removeBulletPoints, showToast } from "../../utils/helpers/helpers";
import axiosApi from "../../interceptors/axiosinterceptor";

const DashboardQuery = () => {
  const {
    setSessionUpdate,
    setCurretSession,
    newQuery,
    dashboardname,
    currentSession,
    filterContent,
  } = useContext(MyContext);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "180px",
      overflow: "scroll",
    }),
  };
  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      width: "220px",
      overflow: "scroll",
    }),
  };
  const location = useLocation();

  let email = sessionStorage.getItem("email");
  const currentPath = location.pathname.substring(1);
  const [expand, setExpand] = useState(true);
  const [generating, setGenerating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [regionmessage, setRegionMessage] = useState("Loading...");
  const [dashboardmessage, setDashboardMessage] = useState("Loading...");
  const [region, setRegion] = useState([]);
  const [dashboardid, setDashboardid] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [shareModal, setShareModal] = useState(false);
  const [numberModal, setNumberModal] = useState(false);
  const [url, setUrl] = useState("");
  const [shareDetails, setShareDetails] = useState();
  const [sendNumber, setSendNumber] = useState();
  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState([]);
  const [result, setResult] = useState("");
  const [accountId, setAccountId] = useState("919490798061");
  const [sessionName, setSessionName] = useState(uuid());
  const [RejectModal, setRejectModal] = useState(false);
  const [RejectAnswer, setRejectAnswer] = useState();
  const [audioData, setAudioData] = useState(null);
  const [feedbackInput, setFeedbackInput] = useState("");
  const [currentIdx, setCurrentIdx] = useState("");
  const [audio, setAudio] = useState([]);
  const [test, setTest] = useState(false);
  const [summarizeAudio, setSummarizeAudio] = useState("");
  const [isSummarizeClick, setIsSummarizeClick] = useState(false);

  const [formData, setFormData] = useState({
    notes: "",
    emails: "",
  });

  const [formNumberData, setFormNumberData] = useState({
    number: "",
    text: "",
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    resolver: zodResolver(dashboardDetails),
    mode: "all",
  });
  const DashboardId = useController({
    name: "DashboardID",
    control,
  });
  const Region = useController({
    name: "Region",
    control,
  });
  const changeDashboardid = (option) => {
    DashboardId.field.onChange(option.value);
    setDashboardid(option);
    setSessionUpdate((current) => !current);
    dashboardname.name = option.label;
  };
  const changeRegion = (option) => {
    Region.field.onChange(option.value);
    dashboardname.region = option.label;
    setRegion(option);
    setValue("DashboardID", null);
    setDashboardid("");
    setDashboardOptions([]);
  };

  const fetchRegion = async () => {
    let a = getValues("AccountID");
    try {
      let res = await axiosApi.get(`${getUrl.dashboardRegions}${a}`);
      let a1 = res.data;
      let options = a1.map((e) => ({
        value: e,
        label: e,
      }));
      setRegionOptions(options);
    } catch (err) {
      setRegionMessage("No Data");
    }
  };
  const fetchDasboard = async () => {
    let a = getValues("AccountID");
    if (regionOptions.length === 0) {
      toast.error("Select a Region");
    } else {
      try {
        let res = await axiosApi.get(
          `${getUrl.listDashboards}${a}/${region.value}`
        );
        let a1 = res.data;
        let options = a1.map((e) => ({
          value: e,
          label: e,
        }));
        setDashboardOptions(options);
      } catch (err) {
        setDashboardMessage("No Data");
      }
    }
  };
  useEffect(() => {
    if (shareDetails !== undefined) {
      setFormData({
        notes: "",
        emails: "",
      });
      setShareModal(true);
    }
  }, [shareDetails]);

  useEffect(() => {
    if (sendNumber !== undefined) {
      setFormNumberData({
        number: "",
        text: "",
      });
      setNumberModal(true);
    }
  }, [sendNumber]);

  const handleSubmit1 = async () => {
    setTest(false);
    setLoading(true);
    setResult("");
    setSummarizeAudio("");
    let a = getValues("AccountID");

    let body = {
      account_id: a,
      dashboard_name: dashboardid.value,
      region_name: region.value,
      session_id: sessionName,
      email_id: email,
    };

    let a1 = await summarizeDashboard(body);
    setResult(a1);
    setLoading(false);
    setTest(true);
    let audioText = removeBulletPoints(a1);
    let b = await audioGenerate(audioText);
    setSummarizeAudio(b);
    if (currentSession === "") {
      setCurretSession(sessionName);
    }
    setSessionUpdate((current) => !current);
  };
  const clearInput = () => {
    setLoading(false);
    setTest(false);
    setUrl("");
    setTest(false);
    setResult("");
    setInputValue("");
    setValue("DashboardID", null);
    setValue("Region", null);
    setDashboardid(null);
    setRegion(null);
  };

  useEffect(() => {
    setCurretSession("");
  }, [currentPath]);

  useEffect(() => {
    if (currentPath === "dashboardquery") {
      setData([]);
      setAudio([]);
      setSessionName(uuid());
      setCurretSession("");
    }
  }, [newQuery]);

  useEffect(() => {
    clearInput();
    // setData([]);
  }, [sessionName]);

  const share = async (e) => {
    e.preventDefault();
    let checkFilePath = isSummarizeClick
      ? shareDetails
      : audio[currentIdx].audio;
    let checkDesc = isSummarizeClick ? result : shareDetails.AI;
    setShareModal(false);

    let body = {
      Note: formData.notes,
      recipients: [formData.emails],
      insight: checkDesc,
      file_path: checkFilePath,
      sender: email,
    };
    await sendEmail(body);
    setShareDetails();
  };

  const shareToWhatsApp = async (e) => {
    e.preventDefault();
    let checkDesc = isSummarizeClick
      ? result
      : sendNumber.user + "\n" + sendNumber.AI;
    setNumberModal(false);

    let body = {
      recipient_id: formNumberData.number,
      text: checkDesc,
    };
    await sendDetailInWhatsApp(body);
    setSendNumber();
  };

  const inputChange = () => {
    if (region.length !== 0 || dashboardid.length !== 0) {
      setRegion([]);
      setDashboardid([]);
      setValue("DashboardID", null);
      setValue("Region", null);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      generatePrompt();
    }
  };

  const generatePrompt = async () => {
    if (result === "") {
      toast.error("Please Submit the Dashboard Details");
    } else {
      setGenerating(true);
      setInputValue("");
      let body = {
        account_id: accountId,
        dashboard_name: dashboardid.value,
        region_name: region.value,
        session_id: sessionName,
        email_id: email,
        user_query: inputValue,
      };
      let a = await dashboardPrompt(body);

      let b;

      if (a !== undefined) {
        setGenerating(false);

        setData((prev) => [
          ...prev,
          {
            user: inputValue,
            AI: a,
            thumbsUp: false,
            thumbsDown: false,
          },
        ]);
        let responseForAudio = removeBulletPoints(a);
        b = await audioGenerate(responseForAudio);
        let value = {
          email_id: email,
          session_id: sessionName,
          s3_url: b,
          dashboard_name: dashboardid.value,
        };
        let id = await dashboardAudioHistory(value);

        if (currentSession === "") {
          setCurretSession(sessionName);
        }
        setAudio((prev) => [
          ...prev,
          {
            audio: b,
            Message_Id: id,
          },
        ]);
      }

      setGenerating(false);
    }
  };
  const handleThumbsUpClick = async (index) => {
    setRejectAnswer();
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsUp = !newThumbsStates[index].thumbsUp;
    newThumbsStates[index].thumbsDown = false; // Reset thumbs down
    setData(newThumbsStates);
    let a = data[index];
    let body = {
      message_id: audio[index].Message_Id,
      question: a.user,
      response: a.AI,
      feedback: "thumbs-up",
      email_id: email,
      session_id:
        typeof sessionName !== "string"
          ? sessionName["session name"]
          : sessionName,
    };
    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
    }
  };

  const handleThumbsDownClick = (e, index) => {
    let msgId = audio[index].Message_Id;
    let mergeData = {
      ...e,
      Message_Id: msgId,
    };
    setRejectModal(true);
    setRejectAnswer(mergeData);
    setFeedbackInput("");
    const newThumbsStates = [...data];
    newThumbsStates[index].thumbsDown = !newThumbsStates[index].thumbsDown;
    newThumbsStates[index].thumbsUp = false; // Reset thumbs up
    setData(newThumbsStates);
  };
  const closeRejectModal = () => {
    let a = data.filter((e) => e.user === RejectAnswer.user)[0];
    const indexOfObjectToUpdate = data.findIndex(
      (item) => item.user === a.user
    );
    if (indexOfObjectToUpdate !== -1) {
      data[indexOfObjectToUpdate].thumbsUp = false;
      data[indexOfObjectToUpdate].thumbsDown = false;
    }
    setRejectAnswer();
    setRejectModal(false);
  };
  const submitRejectModal = async (e) => {
    e.preventDefault();
    let body = {
      message_id: RejectAnswer.Message_Id,
      question: RejectAnswer.user,
      response: RejectAnswer.AI,
      feedback: "thumbs-down",
      comment: feedbackInput,
    };
    setRejectAnswer();
    setRejectModal(false);
    let feedbackStatus = await feedBack(body);
    if (feedbackStatus?.status === 200) {
      showToast(feedbackStatus?.data?.message, 1);
      setFeedbackInput("");
    }
  };

  //handleRecordData
  const handleRecordingData = (data) => {
    if (data) {
      setAudioData(data);
    }
  };

  const handleToStopAudio = async () => {
    if (result !== "") {
      if (audioData) {
        setAudioData("");
        setGenerating(true);

        try {
          let convertedData = await generateAudioForDash(audioData);

          let body = {
            account_id: accountId,
            dashboard_name: dashboardid.value,
            region_name: region.value,
            session_id: sessionName,
            email_id: email,
            user_query: convertedData,
          };

          let audioRes = await dashboardPrompt(body);
          let responseForAudio = removeBulletPoints(audioRes);
          let sectionData;

          if (responseForAudio !== undefined) {
            setData((prev) => [
              ...prev,
              {
                user: convertedData,
                AI: audioRes,
                thumbsUp: false,
                thumbsDown: false,
              },
            ]);
            setGenerating(false);

            sectionData = await audioGenerate(responseForAudio);
          }

          if (sectionData !== undefined) {
            let value = {
              email_id: email,
              session_id: sessionName,
              s3_url: sectionData,
              dashboard_name: dashboardid.value,
            };
            let id = await dashboardAudioHistory(value);

            if (currentSession === "") {
              setCurretSession(sessionName);
            }

            setAudio((prev) => [
              ...prev,
              {
                audio: sectionData,
                Message_Id: id,
              },
            ]);
          }
          setGenerating(false);
        } catch (err) {
          toast.error("error on generating prompt");
        }
      }
    } else {
      toast.error("Please Submit the Dashboard Details");
    }
  };

  useEffect(() => {
    if (audioData !== null) {
      handleToStopAudio();
    }
  }, [audioData]);

  //handleToChangeTextArea
  const handleChangeTextArea = (e) => {
    setFeedbackInput(e.target.value);
  };

  const closeShareModal = () => {
    setShareModal(false);
    setShareDetails();
  };

  const closePhoneNumberModal = () => {
    setNumberModal(false);
    setSendNumber();
  };
  //handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputNumberChange = (e) => {
    const { name, value } = e.target;
    setFormNumberData({
      ...formNumberData,
      [name]: value,
    });
  };

  const sentences = result?.split("\n");

  return (
    <div className="dashboardmainContent">
      <div className="formLayout">
        {!expand && (
          <CommonArrowCircle
            className="headingdashboard iconHover"
            expand={!expand}
            onClick={() => setExpand(true)}
          />
        )}
        <form
          onSubmit={handleSubmit(handleSubmit1)}
          className="formDetails collapse show"
          id="collapseExample"
        >
          <div className="d-flex align-items-center justify-content-around">
            <LabelWithInput
              labelClass="col-form-label"
              id="staticEmail"
              labelTitle="Account ID"
              value={accountId ? accountId : ""}
              errors={errors}
              onInput={inputChange}
              register={register}
              accountId="AccountID"
              inputClass="form-control"
            />

            <LabelWithSelect
              labelTitle="Region"
              placeholder="Select Region"
              value={region}
              options={regionOptions}
              onChange={changeRegion}
              onFocus={fetchRegion}
              styles={customStyles}
              noOptionsMessage={() => regionmessage}
              autoFocus
              errors={errors && errors?.Region?.message}
            />

            <LabelWithSelect
              labelTitle="Dashboard Name"
              placeholder="Select a Dashboard"
              value={dashboardid}
              options={dashboardOptions}
              styles={customStyles1}
              onChange={changeDashboardid}
              onFocus={fetchDasboard}
              noOptionsMessage={() => dashboardmessage}
              errors={errors && errors?.DashboardID?.message}
            />
          </div>

          <div className="dashboardSummarize mt-3">
            <CommonBtn
              className="summarize summarizeBtn iconHover"
              title={commonStr.summarize}
              type="submit"
            />
            <CommonBtn
              className="clear ml-3"
              title={commonStr.clear}
              type="button"
              onClick={clearInput}
            />
          </div>
          <div>
            {loading && <CommonLoader customLoaderClass="mt-4" />}
            {test && (
              <div className="result">
                <div className="prompt mt-3">
                  <div className="answers">
                    <div className="ml-1 robot">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="29.178"
                        height="29.179"
                        viewBox="0 0 33.178 33.179"
                      >
                        <g id="user" transform="translate(0 0)">
                          <path
                            id="Path_12221"
                            data-name="Path 12221"
                            d="M15.181,29l-4.95,2.7a4.448,4.448,0,0,0-.794.565,16.579,16.579,0,0,0,21.3.055,4.391,4.391,0,0,0-.873-.585l-5.3-2.65a2.021,2.021,0,0,1-1.117-1.808V25.2a8.005,8.005,0,0,0,.5-.645,12.2,12.2,0,0,0,1.648-3.32,1.665,1.665,0,0,0,1.181-1.584v-2.22a1.657,1.657,0,0,0-.555-1.23V12.995S26.883,8,20.12,8s-6.1,4.994-6.1,4.994V16.2a1.655,1.655,0,0,0-.555,1.23v2.22a1.664,1.664,0,0,0,.768,1.4A11.016,11.016,0,0,0,16.236,25.2v2.028A2.023,2.023,0,0,1,15.181,29Z"
                            transform="translate(-3.529 -2.992)"
                            fill="#e7eced"
                          />
                          <g
                            id="Group_1134"
                            data-name="Group 1134"
                            transform="translate(0 0)"
                          >
                            <path
                              id="Path_12222"
                              data-name="Path 12222"
                              d="M16.873,0A16.579,16.579,0,0,0,5.915,29.273a4.408,4.408,0,0,1,.787-.56l4.95-2.7a2.022,2.022,0,0,0,1.054-1.775V22.21A11.006,11.006,0,0,1,10.7,18.058a1.665,1.665,0,0,1-.768-1.4v-2.22a1.657,1.657,0,0,1,.555-1.23V10s-.659-4.994,6.1-4.994S22.693,10,22.693,10v3.209a1.655,1.655,0,0,1,.555,1.23v2.22a1.665,1.665,0,0,1-1.181,1.584,12.2,12.2,0,0,1-1.648,3.32,8.005,8.005,0,0,1-.5.645v2.08A2.021,2.021,0,0,0,21.035,26.1l5.3,2.65a4.414,4.414,0,0,1,.87.583A16.586,16.586,0,0,0,16.873,0Z"
                              transform="translate(0 0)"
                              fill="#62b5b4"
                            />
                          </g>
                        </g>
                      </svg>
                    </div>
                    &ensp;
                    <div className="sentences">
                      {sentences?.map((sentence, index) => (
                        // Render each bullet point as a list item
                        <p key={index}>{sentence}</p>
                      ))}
                      <div className="audioplayer">
                        {summarizeAudio ? (
                          <>
                            <audio controls style={{ maxWidth: "100%" }}>
                              <source src={summarizeAudio} type="audio/mp3" />
                            </audio>
                            <div className="dropdown">
                              <button
                                type="button"
                                id="dropdownMenu1"
                                data-toggle="dropdown"
                              >
                                <BsShare size={20} color="#62B5B4" />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenu1"
                              >
                                <li>
                                  <p
                                    className="shareOptions"
                                    onClick={() => {
                                      setSendNumber(sentences);
                                      setIsSummarizeClick(true);
                                    }}
                                  >
                                    WhatsApp
                                  </p>
                                </li>
                                <li>
                                  <p
                                    className="shareOptions"
                                    onClick={() => {
                                      setShareDetails(summarizeAudio);
                                      setIsSummarizeClick(true);
                                    }}
                                  >
                                    Outlook
                                  </p>
                                </li>
                                <li>
                                  <p className="shareOptions">Teams</p>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <ThreeDotSpinner />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <CommonArrowCircle
            className="mt-4 postionRelative iconHover d-flex justify-content-center marginLeft4px"
            onClick={() => setExpand((e) => !e)}
          />
        </form>
      </div>
      <div className="mainContent dashboardQueryTop">
        {data?.length === 0 ? (
          <ChatBot userName={filterContent?.userName} />
        ) : (
          <div className="prompt">
            {console.log("audio", audio)}
            {data &&
              data?.length > 0 &&
              data?.map((e, i) => (
                <CommonPromChat
                  indexValue={i}
                  key={i + 1}
                  user={e.user}
                  isDashboardSplit
                  AI={e.AI}
                  audio={audio}
                  thumbsUp={data[i].thumbsUp}
                  thumbsDown={data[i].thumbsDown}
                  onThumbsUpClick={() => handleThumbsUpClick(i)}
                  onThumbsDownClick={() => handleThumbsDownClick(e, i)}
                  onShareClick={() => {
                    setShareDetails(e);
                    setCurrentIdx(i);
                    setIsSummarizeClick(false);
                  }}
                  onShareNumberClick={() => {
                    setSendNumber(e);
                    setIsSummarizeClick(false);
                  }}
                />
              ))}
          </div>
        )}
        {generating && <CommonGenerateBtn />}
        <SearchInputArrowBtn
          value={inputValue}
          placeholder={commonStr.typeYourQuery}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          inputValue={inputValue}
          onClick={generatePrompt}
          handleRecordingData={handleRecordingData}
          handleToStopAudio={handleToStopAudio}
        />
      </div>

      {shareModal && (
        <CommonSendMail
          show={shareModal}
          onSubmit={share}
          closeShareModal={closeShareModal}
          handleInputChange={handleInputChange}
          formData={formData}
        />
      )}
      {numberModal && (
        <CommonShareWhatsApp
          show={numberModal}
          onSubmit={shareToWhatsApp}
          closeShareModal={closePhoneNumberModal}
          handleInputChange={handleInputNumberChange}
          formData={formNumberData}
        />
      )}

      {RejectModal && (
        <CommonFeedback
          show={RejectModal}
          onSubmit={submitRejectModal}
          onchangeTextArea={handleChangeTextArea}
          onchangeTextAreaValue={feedbackInput}
          closeRejectModal={closeRejectModal}
        />
      )}
    </div>
  );
};

export default DashboardQuery;
