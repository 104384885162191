import React, { useContext, useState, useRef, useEffect } from "react";
import { MyContext } from "../../context/ContextApi";
import { uploadImage } from "../../api/Postaction";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { Document, Page, pdfjs } from "react-pdf";
// import PDFViewer from "./pdfViewer";
// import Comments from "./comments";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const UploadLogo = () => {
  const { selectedFileUrls, setLogoDataSrc, setCurrPageLogo, selectedFile, newQuery } =
    useContext(MyContext);
  const [, setSelectedFile] = useState(false);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [, setHighlightedLogoData] = useState([]);
  const [logoData, setLogoData] = useState([]);
  const userName = sessionStorage.getItem("email");
  const fileInputRef = useRef(null);

  // console.info({ selectedFile });

  const [numPages, setNumPages] = useState(null);
  const [pageHeights, setPageHeights] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const handleBrowseFilesClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(true);
    }
    const body = {
      email: userName,
      pdf_path: selectedFileUrls,
    };

    try {
      let response = await uploadImage(body, file);
      // console.log(response, "Image upload");
      const updatedUrl = { ...documentUrl };
      updatedUrl[selectedFile?.id] = response?.logo_highlight;
      // console.info({ updatedUrl });
      setDocumentUrl(updatedUrl);
      setLogoData(response?.logo_analyzed);
      setLogoDataSrc(response?.logo_analyzed);
      handlePageChange(1);
    } catch (error) {
      console.log(error);
    } finally {
      fileInputRef.current.value = null;
    }
  };

  // console.log(logoData, "logoData");

  const handlePageChange = (pageNumber) => {
    // console.log(`Page changed to: ${pageNumber}`);
    const sentences = logoData.filter(
      (item) => parseInt(item.page_number) === pageNumber
    );
    // console.log(`Sentences for page ${pageNumber}:`, sentences);
    setHighlightedLogoData(sentences);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageHeights(Array(numPages).fill(0));
  };

  const onPageRenderSuccess = (pageNumber, { height }) => {
    setPageHeights((prevHeights) => {
      const newHeights = [...prevHeights];
      newHeights[pageNumber - 1] = height;
      return newHeights;
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // console.log("coming");
      const pdfContainer = document.getElementById("pdf-container-logo");
      if (!pdfContainer) return;
      const scrollTop = pdfContainer.scrollTop;
      let currentPage = 1;
      let currentPageTop = 0;

      for (let i = 0; i < pageHeights.length; i++) {
        currentPageTop += pageHeights[i];
        if (currentPageTop > scrollTop) {
          currentPage = i + 1;
          break;
        }
      }

      setCurrentPage(currentPage);
      setCurrPageLogo(currentPage);
    };

    console.log(currentPage, "currentPage");

    const pdfContainer = document.getElementById("pdf-container-logo");
    if (pdfContainer) {
      pdfContainer.addEventListener("scroll", handleScroll);
      return () => pdfContainer.removeEventListener("scroll", handleScroll);
    }
  }, [pageHeights, currentPage]);

  // console.info({ documentUrl });

  return (
    <div className="">
      <div className="">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <h5 className="d-flex mt-3 mb-2">Upload your Image:</h5>
            </div>
            <div className="col-12">
              <p className="d-flex uploadText">Upload your image</p>
              <div className="card contentCardInMlt">
                <div className="col-12">
                  <div className="row">
                    <div className="col-1">
                      <CloudUploadOutlinedIcon
                        fontSize="large"
                        color="primary"
                      />
                    </div>
                    <div className="col-8">
                      <h6 className="dragText">Drag and Drop image here</h6>
                      <p className="limitText">
                        Image should be jpeg, jpg, gif, png, bmp format
                      </p>
                    </div>
                    <div className="col-3">
                      <button
                        className="btn-sm btn-light btnLight"
                        type="button"
                        onClick={handleBrowseFilesClick}
                      >
                        Browse files
                      </button>
                      <input
                        id="fileInput"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept={".jpeg, .jpg, .gif, .png, .bmp"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Object.keys(documentUrl || {}).length !== 0 &&
          documentUrl[selectedFile?.id] && (
            <div>
              <div
                id="pdf-container-logo"
                className="pdf-container"
                style={{ height: "500px", width: "650px" }}
              >
                <Document
                  file={documentUrl[selectedFile?.id]}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={console.error}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      onRenderSuccess={(info) =>
                        onPageRenderSuccess(index + 1, info)
                      }
                    />
                  ))}
                </Document>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default UploadLogo;
