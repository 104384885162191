import React from "react";
import { bot } from "../../utils/images";
import { commonStr } from "../../utils/constants/commonStrings";

function ChatBot({ userName = "", sub = commonStr.howCan }) {
  return (
    <div className="history">
      <div className="defaultPlaceholder">
        <img src={bot} alt="bot" className="chatBotImg" />
        <p className="mt-4 text-capitalize">
          {commonStr.hello} {userName}
        </p>
        {sub}
      </div>
    </div>
  );
}

export default ChatBot;
