// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  display: flex;
  height: 100vh;
  flex-shrink: 0;
}

body,
html {
  color: #000000 !important;
  font-size: 1rem !important;
  font-family: Poppins !important;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,aAAA;EACA,cAAA;AAHF;;AAMA;;EAEE,yBAAA;EACA,0BAAA;EACA,+BAAA;AAHF","sourcesContent":["@import \"scss/variables\";\n@import \"scss/colors\";\n@import \"scss/fonts\";\n\n.App {\n  display: flex;\n  height: 100vh;\n  flex-shrink: 0;\n}\n\nbody,\nhtml {\n  color: $black !important;\n  font-size: $base-font-size !important;\n  font-family: $font-family !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
