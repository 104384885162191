// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchbtn-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px;
}`, "",{"version":3,"sources":["webpack://./src/common/searchInputBtn/styles.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AACA;ECME,aAAA;EAGE,mBDRqB;ECYrB,8BDZ6B;EAC/B,gBAAA;AAEF","sourcesContent":["@import \"src/scss/variables\";\n.searchbtn-input {\n  @include flexContainer(center, space-between);\n  margin: 30px 0px;\n\n  // &__search-input {\n  //   border-radius: 25px;\n  //   border: 1px solid $lightgreen;\n  //   padding: 10px 15px 10px 15px;\n  // }\n}\n","// mixins\n@mixin widthHeight($width, $height) {\n  width: #{$width}px;\n  height: #{$height}px;\n}\n\n@mixin flexContainer($align-items: null, $justify-content: null) {\n  display: flex;\n\n  @if $align-items != null {\n    align-items: $align-items;\n  }\n\n  @if $justify-content != null {\n    justify-content: $justify-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
