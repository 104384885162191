import React, { useEffect, useState } from "react";
import "./Files.css";
import { Table, Pagination } from "rsuite";
import { MdDelete } from "react-icons/md";

const DiseaseTable = ({ tableData, getClickName = "", getClickDelete }) => {
  const [Loading, setLoading] = useState(true);
  const { Column, HeaderCell, Cell } = Table;

  useEffect(() => {
    if (tableData.length > 0) {
      setLoading(false);
    }
  }, [tableData]);

  const handleToClickName = (data) => {
    getClickName(data);
  };

  const handleClickDelete = (data) => {
    getClickDelete(data);
  };

  return (
    <div className="list">
      <div className="adminTable1">
        <div>
          <Table
            loading={Loading ? true : false}
            wordWrap="break-word"
            className="custom-table iconHover"
            virtualized
            hover={false}
            height={414}
            style={{
              scrollbarWidth: "0",
            }}
            data={tableData}
            align="center"
          >
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Sno</HeaderCell>

              <Cell dataKey="file_name" id="content">
                {(_, idx) => <div>{idx + 1}</div>}
              </Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">File Name</HeaderCell>

              <Cell dataKey="file_name" id="content">
                {(rowData) => (
                  <div onClick={() => handleToClickName(rowData)}>
                    {rowData.file_name}
                  </div>
                )}
              </Cell>
            </Column>
            <Column flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Delete</HeaderCell>
              <Cell id="content">
                {(rowData) => (
                  <>
                    <MdDelete onClick={() => handleClickDelete(rowData)} />
                  </>
                )}
              </Cell>
            </Column>
          </Table>
          {/* <div style={{ padding: "20px 0px 10px 45px" }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              limitOptions={[10, 30, 50]}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DiseaseTable;
