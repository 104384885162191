import React from "react";
import "./ToggleIcon.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const ToggleIcon = ({
  isChecked,
  onToggle,
  title1 = "Data Lens",
  title2 = "Dash Vista",
}) => {
  const selectedColor = "#4C85F7";
  const unselectedColor = "#ffffff";
  const selectedTextColor = "#ffffff";
  const unselectedTextColor = "#818181";
  return (
    <Box sx={{ display: "flex" }}>
      <Box className="mask-box">
        <Box
          className="mask"
          style={{
            transform: `translateX(${isChecked ? 0 : "100px"})`,
          }}
        />
        <Button
          disableRipple
          variant="text"
          sx={{
            color: isChecked ? selectedTextColor : unselectedTextColor,
            backgroundColor: isChecked ? selectedColor : unselectedColor,
          }}
          onClick={onToggle}
        >
          {title1}
        </Button>
        <Button
          disableRipple
          variant="text"
          sx={{
            color: !isChecked ? selectedTextColor : unselectedTextColor,
            backgroundColor: !isChecked ? selectedColor : unselectedColor,
          }}
          onClick={onToggle}
        >
          {title2}
        </Button>
      </Box>
    </Box>
  );
};

export default ToggleIcon;
