import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { MdCancel } from "react-icons/md";
import { toast } from "react-toastify";
import "./Navbar.css";
import uuid from "react-uuid";

import { getUrl } from "../../api/Endpoint";

import {
  audioGenerate,
  generateKpi,
  localDocuments,
  sendEmail,
  updating_disease_tagged,
  uploadDocumentGt,
  uploadDocuments,
  uploadLocalDocumetGt,
  upload_doc_pubmed,
  upload_local_document_pub,
  mlrContentDocumentUpload,
  zoomVideoConvert,
} from "../../api/Postaction";

import {
  datasearch_small,
  documentsearch_small,
  dashboardsearch_small,
  pubmedsearch_small,
  menu1,
  view,
  menu,
  langIcon,
  mlrIcon,
} from "../../utils/images";

import { omnilens } from "../../utils/images";

import { MyContext } from "../../context/ContextApi";
import {
  setModuleValue,
  showToast,
  timeStampIso,
} from "../../utils/helpers/helpers";
import { errorStr } from "../../utils/constants/errorStrings";
import { commonStr } from "../../utils/constants/commonStrings";
import { menuItems } from "../../utils/constants/constantData";
import CommonBtn from "../commonBtn";
import CommonInput from "../commonInput";
import ToggleIcon from "../Toggle/ToggleIcon";
import { deleteCollectionCt } from "../../api/DeleteAction";
import { userList } from "../../api/GetActions";
import axiosApi from "../../interceptors/axiosinterceptor";
import ExpandMenuNav from "../expandMenuNav";

const NavbarComponent = () => {
  const {
    setAllvalues,
    allValues,
    filterContent,
    setFilterContent,
    collectionfetch,
    setDropDownPersona,
    setUserDropDownValue,
    setSessionPersonaval,
    isTableVisible,
    setIsTableVisible,
    setIsDiseaseListShow,
    startLoading,
    stopLoading,
    setPersonaValueList,
    setIsFileUploadStatus,
    personaOptions,
    setNewQuery,
    isNavMenuVisible,
    setIsFileStatusSuccess,
    setDocuments,
    setDetectivePersoanVal,
    setDetectivePersonaTab,
    setComPersoanVal,
    setComPersonaTab,
    setZoomCollValue,
    setSummarizeClick,
  } = useContext(MyContext);

  let email = sessionStorage.getItem("email");
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  let activeSession = currentPath.substring(1);
  const currentPage = activeSession.split("/")[0];
  if (activeSession.includes("%20")) {
    activeSession = activeSession.replace("%20", " ");
  }
  const [show, setShow] = useState(false);
  const [expand, setExpand] = useState(false);
  const [modal, setModal] = useState(false);
  const [kpiOptions, setKpiOptions] = useState([]);
  const [personaTables, setPersonaTables] = useState([]);
  const [value, setValue] = useState([]);
  const [recipient, setRecipient] = useState();
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [bucket, setBucket] = useState();
  const [allCollection, setAllCollection] = useState();
  const [collectionValue, setCollectionValue] = useState([]);
  const [documentModal, setDocumentModal] = useState(false);
  const [mlrModal, setMlrModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [fileURLs, setFileURLs] = useState("");
  const [link, setlink] = useState("");
  const [localPath, setLocalpath] = useState();
  const [content, setContent] = useState();

  const [personaDropDownList, setPersonaDropDownList] = useState([]);
  const [getPersonaData, setGetPersonaData] = useState({});
  const [personaDropValue, setPersonaDropValue] = useState("");
  const [personaTableList, setPersonaTableList] = useState(null);
  const [getPersonaDetails, setGetPersonaDetails] = useState([]);
  const [uploadFileName, setUploadFileName] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [isTableauToggle, setIsTableauToggle] = useState(true);

  const [isContentToggle, setIsContentToggle] = useState(true);

  const [uploadedFileList, setUploadedFileList] = useState([]);

  const [navbarMenuItems, setNavbarMenuItems] = useState([]);
  const [detectDropOptions, setDetectDropOptions] = useState([]);
  const [detectDropValue, setDetectDropValue] = useState("");
  const [detectPersonaData, setDetectPersonaData] = useState({});

  const [zoomCollectionOpt, setZoomCollectionOpt] = useState([
    {
      label: "zoompoc",
      value: "zoompoc",
    },
  ]);
  const [zoomCollValue, setZoomCollVal] = useState("");

  const [comPersonaData, setComPersonaData] = useState({});
  const [comDropOptions, seComtDropOptions] = useState([]);

  const [comDropValue, setComDropValue] = useState("");

  useEffect(() => {
    if (expand) {
      setExpand(false);
    }
  }, [currentPage]);

  //tableau toggle
  useEffect(() => {
    const nextPage = isTableauToggle ? "/dashboardquery" : "/tableauquery";
    navigate(nextPage);
  }, [isTableauToggle]);

  useEffect(() => {
    const nextPage = isContentToggle ? "/documentquery" : "/contentChange";
    navigate(nextPage);
  }, [isContentToggle]);

  useEffect(() => {
    const nextPage = isChecked ? "/newquery" : "/textchartquery";
    navigate(nextPage);
  }, [isChecked]);

  useEffect(() => {
    if (currentPage === "dashboardquery") {
      setIsTableauToggle(true);
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === "newquery") {
      setIsChecked(true);
    }
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === "documentquery") {
      setIsContentToggle(true);
    }
  }, [currentPage]);

  const togglePage = () => {
    if (isChecked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };

  const handleToggleClick = () => {
    if (isTableauToggle) {
      setIsTableauToggle(false);
    } else {
      setIsTableauToggle(true);
    }
  };

  const handleClickContentToggle = () => {
    if (isContentToggle) {
      setIsContentToggle(false);
    } else {
      setIsContentToggle(true);
    }
  };

  const handleIconClick = () => {
    setIsTableVisible(!isTableVisible);
    setIsDiseaseListShow(false);
  };

  const handleDocumentUploadClick = () => {
    setIsDiseaseListShow(true);
    setIsTableVisible(false);
  };

  const handleSelectChange = (selectedOption) => {
    setValue(selectedOption);
  };

  const collectionChange = (e) => {
    let a = [e];
    setCollectionValue(a);
    if (currentPage !== "seeFiles") {
      navigate("/documentquery");
    }
  };

  const handleZoomCollection = (val) => {
    setZoomCollVal([val]);
    setZoomCollValue(val.value);
    navigate("/zoom");
  };

  const personaList = useCallback(async () => {
    if (kpiOptions.length === 0) {
      try {
        let kpi = await axiosApi.get(`${getUrl.tableAccess}${email}/`);
        let data = kpi.data.assigned_kpi;
        let personaTables = kpi.data.assigned_tables;
        let options = data?.map((e) => ({
          value: e,
          label: e,
        }));
        let options2 = personaTables.map((e) => ({
          value: e,
          label: e,
        }));

        setKpiOptions(options);
        setPersonaTables(options2);
      } catch (err) {
        console.log("Error in generating kpi", err);
      }
    }
  }, [email, kpiOptions.length]);

  const CollectionList = useCallback(async () => {
    try {
      let result = await axiosApi.get(`${getUrl.listCollection}`);
      setAllCollection(result.data);
    } catch (err) {
      console.log("Error in CollectionList", err);
    }
  }, [setAllCollection]);

  const fetch = useCallback(async () => {
    try {
      let res = await userList();

      let a = res;
      let b = a.filter((e) => e.email === email);
      let userName = b[0].firstname;
      let filteObject = { ...filterContent };
      let mergeUserDatails = { ...filteObject, userName };
      setFilterContent(mergeUserDatails);

      const updatedMenuItems = menuItems?.map((item) => ({
        ...item,
        disabled: b[0].solution_name.includes(item.label),
      }));
      setNavbarMenuItems(updatedMenuItems);

      let filteredOptions = [];
      if (b[0].collection_name !== null) {
        let c = b[0].collection_name;
        filteredOptions = c.map((e) => ({
          value: e,
          label: e,
        }));
      }
      setCollectionOptions(filteredOptions);
    } catch (err) {
      console.log(err);
    }
  }, [email, setCollectionOptions, filterContent, setFilterContent]);

  useEffect(() => {
    fetch();
  }, [isNavMenuVisible]);

  useEffect(() => {
    setModuleValue().then((value) => {
      let initalValue = {
        value: value,
        label: value,
      };

      setUserDropDownValue(initalValue);
    });
  }, []);

  useEffect(() => {
    CollectionList();
  }, [CollectionList, collectionfetch]);

  //getPersonaList
  const getPersonaList = useCallback(async () => {
    try {
      let result = await axiosApi.get(`${getUrl.getPersonaList}${email}/`);

      if (result?.data) {
        setGetPersonaData(result?.data);
        let personaOptions = Object.keys(result.data).map((value, index) => ({
          value: result?.data[value].persona_tables[0],
          label: value,
        }));

        setGetPersonaDetails(personaOptions);
        let mappedData = Object.keys(result?.data).map((category) => ({
          value: category,
          label: category,
        }));

        setPersonaDropDownList(mappedData);
      }
    } catch (err) {
      console.log("Error in CollectionList", err);
    }
  }, [email, setGetPersonaData, setPersonaDropDownList, setGetPersonaDetails]);

  const getDataDetectivePersona = useCallback(async () => {
    try {
      let result = await axiosApi.get(`${getUrl.getPersonaList}${email}/`);

      if (result?.data) {
        setDetectPersonaData(result?.data);
        let mappedData = Object.keys(result?.data).map((category) => ({
          value: category,
          label: category,
        }));

        setDetectDropOptions(mappedData);
      }
    } catch (err) {
      console.log("Error in detective", err);
    }
  }, [email, setDetectPersonaData, setDetectDropOptions]);

  const getComDetectivePersona = useCallback(async () => {
    try {
      let result = await axiosApi.get(`${getUrl.getPersonaList}${email}/`);

      if (result?.data) {
        setComPersonaData(result?.data);
        let mappedData = Object.keys(result?.data).map((category) => ({
          value: category,
          label: category,
        }));

        seComtDropOptions(mappedData);
      }
    } catch (err) {
      console.log("Error in detective", err);
    }
  }, [email, setComPersonaData, seComtDropOptions]);

  //personaOnchange
  const personaOnchange = async (val) => {
    const kpiArray = [];
    const kpiData = getPersonaData[val.value]?.kpi;

    if (kpiData && kpiData.length > 0) {
      kpiData.forEach((value) => {
        kpiArray.push({ value, label: value });
      });
    }

    setPersonaDropValue(val);
    setSessionPersonaval(val.value);
    let reterivedData = getPersonaData[val.value];
    setDropDownPersona(reterivedData?.persona_tables);
    setKpiOptions(kpiArray);
    navigate("/newquery");
  };

  const handleDectivePersona = async (val) => {
    setDetectDropValue(val);
    setDetectivePersoanVal(val.value);
    let reterivedData = detectPersonaData[val.value];
    setDetectivePersonaTab(reterivedData?.persona_tables);
    navigate("/dataDetective");
  };

  const handleComPersona = async (val) => {
    setComDropValue(val);
    setComPersoanVal(val.value);
    let reterivedData = comPersonaData[val.value];
    setComPersonaTab(reterivedData?.persona_tables);
    navigate("/comdataDetective");
  };

  const personaListOnChange = async (val) => {
    setPersonaValueList(val);
    setPersonaTableList(val);
    navigate("/textchartquery");
  };

  //getPersonaList
  useEffect(() => {
    getPersonaList();
  }, [getPersonaList, personaOptions]);

  useEffect(() => {
    if (collectionValue.length > 0) {
      let a = allCollection.filter(
        (e) => e.collection_name === collectionValue[0].value
      );
      if (a.length > 0) {
        setBucket({
          bucket: a[0].bucket,
          folder: a[0].folder === null ? "folder" : a[0].folder,
        });
        setAllvalues({
          collection: collectionValue[0].value,
          bucket: a[0].bucket,
          folder: a[0].folder === null ? "folder" : a[0].folder,
        });
      }
    } else if (zoomCollValue.length > 0) {
      let b = allCollection.filter(
        (e) => e.collection_name === zoomCollValue[0].value
      );
      console.log(b, "b");
    }
  }, [collectionValue, allCollection, setAllvalues, zoomCollValue]);

  useEffect(() => {
    personaList();
  }, [personaList]);

  const userPreference = async () => {
    if (localPath === null) {
      if (currentPage === "pubmedquery") {
        startLoading();
        let payload = {
          file_list: [link],
          firstname: filterContent.userName,
        };
        let uploadRes = await upload_doc_pubmed(payload);
        setIsDiseaseListShow(false);

        if (uploadRes && uploadRes?.status === 200) {
          let { File_name, Disease_Tagged } = uploadRes?.data;
          let tagPayload = {
            UserName: filterContent.userName,
            timestamp: timeStampIso(),
            file_name: File_name,
            list_of_diseases: Disease_Tagged,
          };
          await updating_disease_tagged(tagPayload);

          handleDocumentUploadClick();
          setlink("");
          stopLoading();
        } else {
          stopLoading();
          setlink("");
        }
      } else {
        if (allValues || !isContentToggle) {
          let payload = "";

          if (!isContentToggle) {
            let deletePayload = {
              collection: filterContent.userName + "_ct",
            };
            await deleteCollectionCt(deletePayload);
            payload = {
              file_url: link,
              collection: filterContent.userName + "_ct",
            };
          } else {
            payload = {
              file_list: [link],
              collection_name: allValues.collection,
              bucket_name: allValues.bucket,
            };
          }

          let a = !isContentToggle
            ? await uploadDocumentGt(payload)
            : await uploadDocuments(payload);

          if (a === undefined) {
            setDocumentModal(true);
          } else {
            setIsFileUploadStatus((e) => !e);
            setlink("");
          }
        } else {
          showToast("Please select collection");
          setlink("");
        }
      }
    } else {
      if (currentPage === "pubmedquery") {
        startLoading();

        let payload = {
          encoded_content: content.content,
          filename: localPath.name,
          firstname: filterContent.userName,
        };
        let localDocResult = await upload_local_document_pub(payload);

        if (localDocResult && localDocResult?.status === 200) {
          setIsDiseaseListShow(false);
          let { File_name, Disease_Tagged } = localDocResult?.data;

          let tagPayload = {
            UserName: filterContent.userName,
            timestamp: timeStampIso(),
            file_name: File_name,
            list_of_diseases: Disease_Tagged,
          };
          await updating_disease_tagged(tagPayload);
          handleDocumentUploadClick();
          stopLoading();
          setLocalpath(null);
        } else {
          stopLoading();
          setLocalpath(null);
        }
      } else if (currentPage === "zoom" && zoomCollValue) {
        startLoading();
        let payload = {
          file: localPath,
          collection_name: "zoompoc",
        };
        await zoomVideoConvert(payload);
        stopLoading();
        setLocalpath(null);
      } else {
        if (allValues || !isContentToggle) {
          let body = "";
          if (isContentToggle) {
            body = {
              collection_name: allValues?.collection,
              bucket_name: allValues?.bucket,
              file_datas: [],
            };
          } else {
            let deletePayload = {
              collection: filterContent.userName + "_ct",
            };
            await deleteCollectionCt(deletePayload);
          }

          const isFileSizeValid = uploadedFileList.every(
            (file) => file.encoded_content.length <= 50 * 1024 * 1024
          );
          if (isFileSizeValid) {
            uploadedFileList.forEach((file) => {
              let format = "";

              if (file.name.endsWith(".pdf")) {
                format = "pdf";
              } else if (file.name.endsWith(".docx")) {
                format = "docx";
              } else if (file.name.endsWith(".pptx")) {
                format = "pptx";
              } else if (file.name.endsWith(".png")) {
                format = "png";
              } else if (file.name.endsWith(".jpg")) {
                format = "jpg";
              } else if (file.name.endsWith(".jpeg")) {
                format = "jpeg";
              } else if (file.name.endsWith(".webp")) {
                format = "webp";
              } else if (file.name.endsWith(".gif")) {
                format = "gif";
              } else if (file.name.endsWith(".bmp")) {
                format = "bmp";
              } else {
                format = "unsupported";
              }
              if (isContentToggle) {
                body.file_datas.push({
                  encoded_content: file.encoded_content,
                  name: file.name,
                  format: format,
                });
              } else {
                body = {
                  data: {
                    encoded_content: file.encoded_content,
                    name: file.name,
                    format: format,
                  },
                  collection: filterContent.userName + "_ct",
                };
              }
            });

            try {
              let a = !isContentToggle
                ? await uploadLocalDocumetGt(body)
                : await localDocuments(body);
              if (a === undefined) {
                setDocumentModal(true);
              } else {
                setLocalpath(null);
              }
            } catch (error) {
              console.error("Error in file upload", error);
            }
          } else {
            toast.error("File size exceeds the maximum allowed size (10MB)");
          }
        } else {
          showToast("Please select collection");
          setLocalpath(null);
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDocumentModal(false);
    setMlrModal(false);

    if (!isContentToggle) {
      setNewQuery((e) => !e);
    }
    await userPreference();
  };
  const handleFileRead = (e) => {
    const content = fileReader.result;
    let a;
    if (content.split(",")[0].includes("presentationml.presentation")) {
      a = {
        format: "pptx",
        content: content.split(",")[1],
      };
    }
    if (content.split(",")[0].includes("pdf")) {
      a = {
        format: "pdf",
        content: content.split(",")[1],
      };
    }
    if (content.split(",")[0].includes("wordprocessingml.document")) {
      a = {
        format: "docx",
        content: content.split(",")[1],
      };
    }
    setContent(a);
  };
  let fileReader;

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsDataURL(file);
  };

  const handleChange1 = (e) => {
    let a = e.target.value;
    if (a.endsWith(".ppt")) {
      showToast(errorStr.fileFormat);
    } else {
      setlink(e.target.value);
      if (localPath !== null) {
        setLocalpath(null);
      }
    }
  };

  const handleChange2 = async (e) => {
    const files = e.target.files;
    if (currentPage === "pubmedquery") {
      setLocalpath(e.target.files[0]);
      setUploadFileName(e.target.files[0].lastModified);
      handleFileChosen(e.target.files[0]);
      if (link !== "") {
        setlink("");
      }
    } else if (currentPage === "zoom") {
      setLocalpath(e.target.files[0]);
    } else {
      setLocalpath(e.target.files[0]);
      const readFile = (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              name: file.name,
              format: file.type,
              encoded_content: reader.result.split(",")[1],
            });
          };
          reader.readAsDataURL(file);
        });
      };

      const uploadedFiles = [];
      for (const file of files) {
        const result = await readFile(file);
        uploadedFiles.push(result);
      }
      setUploadedFileList(uploadedFiles);
    }
  };

  const generateKPI = async () => {
    if (value.length === 0) {
      showToast(errorStr.selectKpi, 0);
    } else {
      setModal(false);
      let tables = personaTables.map((e) => e.value);
      let kpi1 = value.map((e) => e.value);
      let body = {
        persona_tables: tables,
        kpi: kpi1,
      };
      let a = await generateKpi(body);
      let b = await audioGenerate(a);

      let emailbody = {
        Note: "KPI Summary",
        recipients: recipient.split(","),
        insight: a,
        file_path: b,
        sender: email,
      };
      let c = await sendEmail(emailbody);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: "200px",
    }),
  };

  const fileUpload = () => {
    if (isContentToggle) {
      allValues === undefined
        ? showToast(errorStr.pleaseChoose)
        : setDocumentModal(true);
    } else {
      setDocumentModal(true);
    }
  };

  // fileUploadZoomModalShow
  const fileUploadZoom = () => {
    if (zoomCollValue === undefined) {
      showToast(errorStr.pleaseChoose);
    } else {
      setDocumentModal(true);
    }
  };

  const diseaseFileUpload = () => {
    setDocumentModal(true);
  };

  const modelFileUpload = () => {
    setMlrModal(true);
  };

  const seeFiles = () => {
    allValues === undefined
      ? showToast(`${errorStr.choose} ${errorStr.collection}`)
      : navigate("/seeFiles");
  };

  //handleGenerateKpi
  const handleGenerateKpi = () => {
    personaDropValue
      ? setModal(true)
      : showToast(`${errorStr.choose} ${errorStr.persona}`);
  };

  const handleSearchCmp = (
    imgUrl,
    title,
    title1 = "",
    title2 = "",
    hideTitle = true,
    handleToggleClick,
    isChecked
  ) => {
    return (
      <>
        <div className="d-flex align-items-center">
          <img src={imgUrl} alt="dashboardsearch_small" />
          {!hideTitle && <p className="currentSearch mt-1">{title}</p>}
        </div>

        <div>
          <ToggleIcon
            isChecked={isChecked}
            onToggle={handleToggleClick}
            title1={title1}
            title2={title2}
          />
        </div>

        <div className="kpi" />
      </>
    );
  };

  //handleCancelModal
  const handleCancelModal = () => {
    setModal(false);
    setRecipient("");
  };

  const handleCancelUpload = () => {
    setDocumentModal(false);
    setlink("");
  };

  const handleCancelUploadFile = () => {
    setMlrModal(false);
    setlink("");
  };

  const handleSubmitFile = async (e) => {
    e.preventDefault();
    setMlrModal(false);

    if (!isContentToggle) {
      setNewQuery((e) => !e);
    }
    startLoading();
    try {
      const resStatus = await mlrContentDocumentUpload(selectedFiles, email);

      if (resStatus && resStatus?.status === 200) {
        console.info(uuid());
        resStatus.data.id = uuid();

        // if Dynamic, need to generate Id for all the response
        const response = resStatus?.data?.[0];

        response["id"] = uuid();

        console.info({ response });

        setDocuments((prev) => [
          ...prev,
          ...(Array.isArray(response) ? response : [response]),
        ]);
        setIsFileStatusSuccess(resStatus);
      } else {
        setIsFileStatusSuccess();
      }
      stopLoading();
    } catch (error) {
      stopLoading(false);
      console.error("Error uploading file:", error);
    }
  };

  const handleReviewFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(files);

    const validFileURLs = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type === "application/pdf") {
        const url = URL.createObjectURL(file);
        validFileURLs.push(url);
      }
    }
    setFileURLs(validFileURLs);
  };

  const handleSummarizeClick = () => {
    if (allValues?.collection) {
      setSummarizeClick(true);
    } else {
      showToast(errorStr.pleaseSelectCollection);
    }
  };

  return (
    <div className="postionRelative">
      <div className="navbar1">
        <div className="content" onMouseLeave={() => setShow(false)}>
          {(currentPage === "newquery" || currentPage === "textchartquery") && (
            <>
              <div className="d-flex align-items-center">
                <img src={datasearch_small} alt="datasearch_small" />
              </div>
              {!expand ? (
                <>
                  <div>
                    <ToggleIcon isChecked={isChecked} onToggle={togglePage} />
                  </div>
                  <div className="kpi d-flex align-items-stretch">
                    <Select
                      className="navbarSelect"
                      placeholder="Choose Persona"
                      options={
                        currentPage === "newquery"
                          ? personaDropDownList
                          : getPersonaDetails
                      }
                      onFocus={getPersonaList}
                      onChange={
                        currentPage === "newquery"
                          ? personaOnchange
                          : personaListOnChange
                      }
                      value={
                        currentPage === "newquery"
                          ? personaDropValue
                          : personaTableList
                      }
                      styles={customStyles}
                      // autoFocus
                    />
                  </div>
                  {currentPage === "newquery" && (
                    <div className="kpiGenerateBtn" onClick={handleGenerateKpi}>
                      {commonStr.generateKpi}
                    </div>
                  )}
                </>
              ) : (
                <div className="kpi" />
              )}
            </>
          )}

          {currentPage === "zoom" && (
            <>
              <div className="d-flex documentQueryContainer ">
                <div className="d-flex align-items-center">
                  <img src={documentsearch_small} alt="datasearch_small" />
                  <p className="currentSearch">Call Insight</p>
                </div>

                <div></div>
              </div>
              {!expand ? (
                <>
                  <div className="kpi d-flex align-items-center">
                    <Select
                      className="navbarSelect"
                      placeholder="Choose Collection"
                      options={zoomCollectionOpt}
                      onChange={handleZoomCollection}
                      value={zoomCollValue}
                      styles={customStyles}
                      // autoFocus
                    />
                  </div>
                  <div>
                    <div
                      className="btn ml-3 mr-2 kpiGenerateBtn"
                      onClick={fileUploadZoom}
                    >
                      {commonStr.uploadNewDoc}
                    </div>
                  </div>
                </>
              ) : (
                <div className="kpi" />
              )}
            </>
          )}
          {(currentPage === "documentquery" ||
            currentPage === "seeFiles" ||
            currentPage === "contentChange") && (
            <>
              <div className="d-flex documentQueryContainer ">
                {/* <div className="d-flex align-items-center">
                  <img src={documentsearch_small} alt="datasearch_small" />
                  <p className="currentSearch">{commonStr.docSonar}</p>
                </div> */}
                {(currentPage === "documentquery" ||
                  currentPage === "contentChange") &&
                  handleSearchCmp(
                    dashboardsearch_small,
                    commonStr.docSonar,
                    commonStr.docSonar,
                    "Content",
                    true,
                    handleClickContentToggle,
                    isContentToggle
                  )}
                <div>
                  {/* <p className="currentSearch">
                    {sessionStorage.getItem("documentDropdown")}
                  </p> */}
                </div>
              </div>
              {!expand ? (
                <>
                  <div className="kpi d-flex align-items-center">
                    {isContentToggle && (
                      <div style={{ display: "flex" }}>
                        <Select
                          className="navbarSelect"
                          placeholder="Choose Collection"
                          options={collectionOptions}
                          // onFocus={fetch}
                          onChange={collectionChange}
                          value={collectionValue}
                          styles={customStyles}
                          // autoFocus
                        />
                        <button
                          onClick={handleSummarizeClick}
                          style={{
                            marginLeft: "10px",
                            fontSize: "0.85rem",
                            padding: "9px 10px",
                            fontWeight: 500,
                            background: "#FFC965",
                            border: "0.5px solid black",
                            opacity: 1,
                            color: "black",
                            borderRadius: '5px'
                          }}
                        >
                          Summarize
                        </button>
                      </div>
                    )}
                    {isContentToggle && (
                      <div
                        className="btn ml-3 mr-2 kpiGenerateBtn"
                        onClick={fileUpload}
                      >
                        {commonStr.uploadNewDoc}
                      </div>
                    )}
                  </div>
                  {isContentToggle && (
                    <img
                      src={view}
                      alt="datasearch_small"
                      onClick={seeFiles}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </>
              ) : (
                <div className="kpi" />
              )}
            </>
          )}

          {currentPage === "dashboardquery" &&
            handleSearchCmp(
              dashboardsearch_small,
              commonStr.dashLens,
              "QuickSight",
              "Tableau",
              true,
              handleToggleClick,
              isTableauToggle
            )}
          {currentPage === "tableauquery" &&
            handleSearchCmp(
              dashboardsearch_small,
              commonStr.dashLens,
              "QuickSight",
              "Tableau",
              true,
              handleToggleClick,
              isTableauToggle
            )}

          {(currentPage === "pubmedquery" ||
            currentPage === "diseaseListFiles") && (
            <>
              <div className="d-flex documentQueryContainer ">
                <div className="d-flex align-items-center">
                  <img src={pubmedsearch_small} alt="pubmedsearch_small" />
                  <p className="currentSearch">{commonStr.literature}</p>
                </div>
              </div>
              {currentPage !== "pubmedquery" && (
                <>
                  {!expand ? (
                    <>
                      <div className="kpi d-flex align-items-center">
                        <div
                          className="btn ml-3 mr-2 kpiGenerateBtn"
                          onClick={diseaseFileUpload}
                        >
                          {commonStr.uploadNewDoc}
                        </div>
                      </div>
                      <img
                        src={view}
                        alt="datasearch_small"
                        onClick={handleIconClick}
                        style={{ cursor: "pointer" }}
                      />
                    </>
                  ) : (
                    <div className="kpi" />
                  )}
                </>
              )}
            </>
          )}

          {currentPage === "contentGeneration" && (
            <>
              <div className="d-flex documentQueryContainer ">
                <div className="d-flex align-items-center">
                  <img src={documentsearch_small} alt="documentsearch_small" />
                  <p className="currentSearch">{commonStr.generator}</p>
                </div>
              </div>
            </>
          )}

          {(currentPage === "fileUploading" ||
            currentPage === "medLingo" ||
            currentPage === "medPulse" ||
            currentPage === "medInsights") && (
            <>
              <div className="d-flex documentQueryContainer ">
                <div className="d-flex align-items-center">
                  <img
                    src={langIcon}
                    alt="pubmedsearch_small"
                    className="transatorimg"
                  />
                  <p className="currentSearch">{commonStr.translator}</p>
                </div>
              </div>
            </>
          )}

          {(currentPage === "mlrReview" ||
            currentPage === "personalInfoIdentity" ||
            currentPage === "waterMarkDetection" ||
            currentPage === "personalInfoIdentityInImage") && (
            <>
              <div className="d-flex documentQueryContainer ">
                <div className="d-flex align-items-center">
                  <img src={mlrIcon} alt="mrlIcon_small" className="h-30" />
                  <p className="currentSearch">{commonStr.medical}</p>
                </div>
              </div>
              {!expand && currentPage === "mlrReview" ? (
                <div className="kpi d-flex align-items-center">
                  <div
                    className="btn ml-3 mr-2 kpiGenerateBtn"
                    onClick={modelFileUpload}
                  >
                    {commonStr.uploadNewDoc}
                  </div>
                </div>
              ) : (
                <div className="kpi" />
              )}
            </>
          )}

          {currentPage === "dataDetective" && (
            <>
              <div className="d-flex documentQueryContainer ">
                <div className="d-flex align-items-center">
                  <img
                    src={mlrIcon}
                    alt="pubmedsearch_small"
                    className="transatorimg"
                    style={{ width: "30px" }}
                  />

                  <p className="currentSearch">{commonStr.dataDetective}</p>
                </div>
                <div>
                  <Select
                    className="navbarSelect"
                    placeholder="Choose Persona"
                    options={detectDropOptions}
                    onFocus={getDataDetectivePersona}
                    onChange={handleDectivePersona}
                    value={detectDropValue}
                    styles={customStyles}
                    // autoFocus
                  />
                </div>
              </div>
            </>
          )}

          {currentPage === "comdataDetective" && (
            <>
              <div className="d-flex documentQueryContainer ">
                <div className="d-flex align-items-center">
                  <img
                    src={mlrIcon}
                    alt="pubmedsearch_small"
                    className="transatorimg"
                    style={{ width: "30px" }}
                  />

                  <p className="currentSearch">
                    {commonStr.commerical + " " + commonStr?.dataDetective}
                  </p>
                </div>
                <div>
                  <Select
                    className="navbarSelect"
                    placeholder="Choose Persona"
                    options={comDropOptions}
                    onFocus={getComDetectivePersona}
                    onChange={handleComPersona}
                    value={comDropValue}
                    styles={customStyles}
                    // autoFocus
                  />
                </div>
              </div>
            </>
          )}

          {currentPage === "omnilens" && (
            <>
              <div className="d-flex documentQueryContainer ">
                <div className="d-flex align-items-center">
                  <img src={omnilens} alt="omnilens" />
                  <p className="currentSearch">{commonStr.omnilens}</p>
                </div>
              </div>
            </>
          )}

          <div className="searchOption">
            <p className="mr-2">{!expand ? commonStr.clickMenu : null}</p>
            <img
              src={expand ? menu1 : menu}
              alt="menu"
              data-toggle="collapse"
              data-target="#collapseExample1"
              onClick={() => {
                setExpand((e) => !e);
                // handleToHideDisease();
              }}
            />
          </div>
        </div>
      </div>

      {modal && (
        <Modal
          show={modal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <div className="modalHeader">
            <div className="d-flex align-items-center justify-content-between w-100">
              <span>{commonStr.generateKpi}</span>&ensp;
              <MdCancel
                className="iconHover"
                color="#A5A5A5"
                onClick={() => setModal(false)}
              />
            </div>
          </div>
          <Modal.Body>
            <Select
              isMulti
              options={kpiOptions}
              onChange={handleSelectChange}
              placeholder={commonStr.selectKpi}
            />
            <CommonInput
              className="mt-3 form-control"
              placeholder={commonStr.enterRecipient}
              onChange={(e) => setRecipient(e.target.value)}
              value={recipient}
            />
          </Modal.Body>
          <div className="modalButton">
            <CommonBtn
              title={commonStr.cancel}
              onClick={handleCancelModal}
              className="mr-1"
            />
            <CommonBtn title={commonStr.sendMail} onClick={generateKPI} />
          </div>
        </Modal>
      )}

      {documentModal && (
        <Modal
          show={documentModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <form onSubmit={handleSubmit}>
            <div className="modalHeader">
              <div className="d-flex align-items-center justify-content-between w-100">
                <span>{commonStr.uploadDoc}</span>&ensp;
                <MdCancel
                  color="#A5A5A5"
                  onClick={handleCancelUpload}
                  className="iconHover"
                />
              </div>
            </div>
            <Modal.Body>
              <div className="modalconent modalUploadBox">
                <CommonInput
                  className="w-100 moadalUploadInput"
                  placeholder={commonStr.link}
                  onChange={handleChange1}
                  value={link}
                  required={localPath === "" ? true : false}
                />

                <div className="mt-3 d-flex">
                  <p>{commonStr.or}</p>
                </div>
                <CommonInput
                  type={`file`}
                  className="w-100 mt-2 iconHover"
                  placeholder={commonStr.localPath}
                  onChange={handleChange2}
                  required={link === "" ? true : false}
                  accept={
                    ".pdf, .pptx, .docx, .jpg, .png, .jpeg, .webp, .gif, .bmp, .mp4"
                  }
                  fileUpload
                />
              </div>

              {!isContentToggle && (
                <div className="context">
                  <div className="text">
                    <span className="red">*</span>Maximum File size allowed is
                    10 MB
                  </div>
                  <div className="text">
                    <span className="red">*</span>Only PDF files are supported
                  </div>
                </div>
              )}
            </Modal.Body>
            <div className="modalButton">
              <CommonBtn
                title={commonStr.cancel}
                onClick={handleCancelUpload}
                className="mr-1"
              />

              <CommonBtn title={commonStr.upload} type="submit" />
            </div>
          </form>
        </Modal>
      )}

      {mlrModal && (
        <Modal
          show={mlrModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <form onSubmit={handleSubmitFile}>
            <div className="modalHeader">
              <div className="d-flex align-items-center justify-content-between w-100">
                <span>{commonStr.uploadDoc}</span>&ensp;
                <MdCancel
                  color="#A5A5A5"
                  onClick={handleCancelUploadFile}
                  className="iconHover"
                />
              </div>
            </div>
            <Modal.Body>
              <div className="modalconent modalUploadBox">
                <CommonInput
                  className="w-100 moadalUploadInput"
                  placeholder={commonStr.link}
                  // onChange={handleChange1}
                  value={link}
                  required={localPath === "" ? true : false}
                />

                <div className="mt-3 d-flex">
                  <p>{commonStr.or}</p>
                </div>
                <CommonInput
                  type={`file`}
                  className="w-100 mt-2 iconHover"
                  placeholder={commonStr.localPath}
                  onChange={handleReviewFileChange}
                  required={link === "" ? true : false}
                  accept={".pdf"}
                  fileUpload
                />
              </div>

              {isContentToggle && (
                <div className="context">
                  {/* <div className="text">
                    <span className="red">*</span>Maximum File size allowed is
                    10 MB
                  </div> */}
                  <div className="text">
                    <span className="red">*</span>Only PDF files are supported
                  </div>
                </div>
              )}
            </Modal.Body>
            <div className="modalButton">
              <CommonBtn
                title={commonStr.cancel}
                onClick={handleCancelUploadFile}
                className="mr-1"
              />

              <CommonBtn title={commonStr.upload} type="submit" />
            </div>
          </form>
        </Modal>
      )}

      {expand && (
        <ExpandMenuNav
          handleToNavigate={(item) => {
            navigate(item.path);
            setExpand((e) => !e);
          }}
          handleToResticed={() => setExpand((e) => !e)}
          handleClickCircleBtn={() => setExpand((e) => !e)}
          menuItems={navbarMenuItems}
        />
      )}
    </div>
  );
};

export default NavbarComponent;
