import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./DiseaseName.css";
import { IoIosArrowBack } from "react-icons/io";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: "200px",
    cursor: "pointer",
    ":hover": {
      cursor: "pointer",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: "pointer", // Set cursor to pointer on hover
  }),
};

const DiseaseNameList = ({
  getData,
  onchangeGetValue,
  handleGoBack,
  isShowGoBack = false,
}) => {
  const [diseaseName, setPersonaValue] = useState("");
  const [diseaseList, setDiseaseList] = useState([]);

  useEffect(() => {
    if (getData?.list_of_diseases) {
      let mappedData = getData?.list_of_diseases.map((item) => ({
        value: item,
        label: item,
      }));
      setPersonaValue("");
      setDiseaseList(mappedData);
    }
  }, [getData?.list_of_diseases]);

  const handleDropdownChange = (selected) => {
    setPersonaValue(selected);
    onchangeGetValue(selected.value);
  };

  return (
    <div className="diseaseContainer">
      <div className="diseaseGoBack">
        {isShowGoBack && (
          <IoIosArrowBack
            className="iconHover"
            size={25}
            onClick={handleGoBack}
          />
        )}
        <h5 className="diseaseTitle">File Name: {getData?.file_name}</h5>
      </div>
      <div className="diseaseDropWrap">
        <h5 className="diseaseTitle">Disease List:</h5>
        <Select
          className="diseaseDropContainer iconHover"
          placeholder="Choose Disease"
          value={diseaseName}
          onChange={handleDropdownChange}
          options={diseaseList}
          styles={customStyles}
          autoFocus
        />
      </div>
    </div>
  );
};

export default DiseaseNameList;
