import React, { useState } from "react";
import "./Admin.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { PiUsers } from "react-icons/pi";
import { FaRegFolderClosed } from "react-icons/fa6";
import activePersona from "../../assets/svg/activePersona.svg";
import inactivePersona from "../../assets/svg/inactivePersona.svg";
import activeSchedule from "../../assets/svg/activeschedule.svg";
import inactiveSchedule from "../../assets/svg/inactiveschedule.svg";
import User from "./userDetails/User";
import Collection from "./Collection";
import PersonaCmp from "./Persona";
import WorkBookCmp from "./WorkBook";

import Schedule from "./schedule";
import ModuleOptions from "./ModuleOptions/moduleOptions";
import { commonStr } from "../../utils/constants/commonStrings";

const Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let path = location.pathname.split("/")[2];
  const [fetch, setFetch] = useState(false);

  //handleGoBack
  const handleToGoBack = () => {
    navigate(-1);
  };

  //activeUrl & activeClass
  const activeClass = "admin-wrap__tab__wrap__active";
  const inactiveClass = "admin-wrap__tab__wrap__inactive";
  const userActivePath = path === "user";
  const collActivePath = path === "collection";
  const perActivePath = path === "persona";
  const workActivePath = path === "workBook";
  const schActivePath = path === "schedule";

  //handleNavigate

  const handleNavigate = (url) => {
    navigate(`/admin/${url}`);
  };

  return (
    <div className="admin-wrap">
      <div className="admin-wrap__back iconHover" onClick={handleToGoBack}>
        <IoIosArrowDropleftCircle
          size={25}
          className="admin-wrap__back__icon"
        />
        &ensp;{commonStr.back}
      </div>

      <div className="admin-wrap__tab">
        <div className="admin-wrap__tab__wrap">
          <div
            className={`${userActivePath ? activeClass : inactiveClass} `}
            onClick={() => handleNavigate("user")}
          >
            <PiUsers size={18} />
            &ensp; {commonStr.user + " " + commonStr.details}
          </div>
          <div
            className={`ml-3 ${perActivePath ? activeClass : inactiveClass} `}
            onClick={() => handleNavigate("persona")}
          >
            <img
              src={perActivePath ? activePersona : inactivePersona}
              alt="inactive"
            />
            &ensp;{commonStr.persona}
          </div>

          <div
            className={`ml-3 ${collActivePath ? activeClass : inactiveClass}`}
            onClick={() => handleNavigate("collection")}
          >
            <FaRegFolderClosed size={16} />
            &ensp;{commonStr.colections}
          </div>

          <div
            className={`ml-3 ${workActivePath ? activeClass : inactiveClass}`}
            onClick={() => handleNavigate("workBook")}
          >
            <img
              src={workActivePath ? activePersona : inactivePersona}
              alt="work"
            />
            &ensp;{commonStr.workbook}
          </div>
          <div
            className={`ml-3 ${schActivePath ? activeClass : inactiveClass}`}
            onClick={() => handleNavigate("schedule")}
          >
            <img
              src={schActivePath ? activeSchedule : inactiveSchedule}
              alt="inactive"
            />
            &ensp;{commonStr.schedule}
          </div>

          <div className={`ml-3`}>
            <ModuleOptions />
          </div>
        </div>

        {userActivePath && <User fetch={fetch} setFetch={setFetch} />}
        {collActivePath && <Collection fetch={fetch} setFetch={setFetch} />}
        {perActivePath && <PersonaCmp fetch={fetch} setFetch={setFetch} />}
        {workActivePath && <WorkBookCmp fetch={fetch} setFetch={setFetch} />}
        {schActivePath && <Schedule fetch={fetch} setFetch={setFetch} />}
      </div>
    </div>
  );
};

export default Admin;
