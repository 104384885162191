import React, { useState, useContext } from "react";
import Select from "react-select";
import "./moduleOptions.css";
import { MyContext } from "../../../context/ContextApi";
import { modalSwapLogs } from "../../../api/Postaction";
import { timeStampIso } from "../../../utils/helpers/helpers";
import { CommonModal } from "../../../common";

const ModuleOptions = () => {
  const { userDropdDownValue, setUserDropDownValue } = useContext(MyContext);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minWidth: "200px",
      cursor: "pointer",
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer", // Add pointer cursor to option elements
    }),
  };

  const [selectedOption, setSelectedOption] = useState(userDropdDownValue);
  const [logoutModal, setLogoutModal] = useState(false);

  const [personaValue, setPersonaValue] = useState("");

  const options = [
    { value: "OpenAI", label: "OpenAI" },
    { value: "Zephyr-7b-beta", label: "Zephyr-7b-beta" },
    { value: "Mistral_7B", label: "Mistral_7B" },
  ];

  const handleSelectChange = (selected) => {
    setLogoutModal(true);
    setPersonaValue(selected);
  };

  const handlePersonaUpdate = () => {
    if (personaValue) {
      setSelectedOption(personaValue);
      let payload = {
        email: sessionStorage.getItem("email"),
        model_type: personaValue?.label,
        time_stamp: timeStampIso(),
      };
      modalSwapLogs(payload);

      sessionStorage.setItem("documentDropdown", personaValue?.label);
      setUserDropDownValue(personaValue);
      setLogoutModal(false);
    }
  };

  return (
    <div>
      <Select
        className="navbarSelect"
        placeholder="Choose Model"
        value={selectedOption}
        onChange={handleSelectChange}
        options={options}
        styles={customStyles}
        autoFocus
      />
      {logoutModal && (
        <CommonModal
          showModal={logoutModal}
          title={"Model Type"}
          content={`Are you sure change model to ${personaValue?.label} `}
          onCancel={() => setLogoutModal(false)}
          onConfirm={handlePersonaUpdate}
        />
      )}
    </div>
  );
};

export default ModuleOptions;
