import axios from "axios";

const dev = process.env.REACT_APP_API_DEV_URL; // Test
const axiosApi = axios.create({
  baseURL: dev, // our axiosApi base URL
});
let lastActivityTimestamp = Date.now();

// Request interceptor for adding the bearer token
axiosApi.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("accessToken"); // Assuming you store the token in localStorage

    lastActivityTimestamp = Date.now();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    lastActivityTimestamp = Date.now();
    return Promise.reject(error);
  }
);

// Check for timeout on every response
axiosApi.interceptors.response.use(
  (response) => {
    // Update the last activity timestamp after receiving a response
    lastActivityTimestamp = Date.now();
    return response;
  },
  (error) => {
    // Update the last activity timestamp after handling response error
    lastActivityTimestamp = Date.now();
    // const status = error.response ? error.response.status : null;

    return Promise.reject(error);
  }
);

// setInterval(() => {
//   const currentTime = Date.now();
//   const timeoutThreshold = 120 * 60 * 1000; // 120 minutes in milliseconds
//   // const timeoutThreshold = 20 * 1000; // 120 minutes in milliseconds
//   //
//   if (currentTime - lastActivityTimestamp > timeoutThreshold) {
//     window.location.assign("/");
//   }
// }, 60 * 1000); // Check every 30 seconds
// }, 10 * 1000); // Check every 30 seconds

// Export the axiosApi instance
export default axiosApi;
