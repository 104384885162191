import React from "react";
import Select from "react-select";

import "./styles.css";
function LabelWithSelect({
  labelFor = "inputPassword",
  labelClass = "col-form-label",
  labelTitle = "",
  placeholder = "",
  value = "",
  options,
  onChange,
  onFocus,
  styles,
  noOptionsMessage,
  errors,
  autoFocus = false,
  labelHide = true,
  customSelectClass = "",
}) {
  return (
    <div className={`${labelHide && "d-flex"} ${customSelectClass} `}>
      {labelHide && (
        <>
          <label for={labelFor} className={labelClass}>
            {labelTitle}
          </label>{" "}
          &ensp;&ensp;
        </>
      )}

      <div>
        <Select
          placeholder={placeholder}
          value={value}
          options={options}
          onChange={onChange}
          onFocus={onFocus}
          styles={styles}
          noOptionsMessage={noOptionsMessage}
          autoFocus={autoFocus}
          maxMenuHeight={100}
        />
        {errors && <div className="marginTop4px colorRed">{errors}</div>}
      </div>
    </div>
  );
}

export default LabelWithSelect;
