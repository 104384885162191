import React from "react";
import { Modal } from "react-bootstrap";
import { commonStr } from "../../utils/constants/commonStrings";
import "./styles.scss";
import CommonBtn from "../commonBtn";

const CommonModal = ({
  showModal,
  closeModal,
  title,
  content,
  onCancel,
  onConfirm,
  onCancelTitle = commonStr.cancel,
  onConfirmTitle = commonStr.confirm,
  listitems = "",
  confirmHide = true,
  evaluateData = [],
  evaluateShow = false,
  dataDetShow = false,
  dataList = [],
}) => {
  return (
    <Modal
      show={showModal}
      size={dataDetShow ? "lg" : "md"}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`modal__list-content ${
          (evaluateShow || dataDetShow) && `modal__list-content--height`
        }`}
      >
        {evaluateShow ? (
          <div>
            <div>
              <h6>maliciousness</h6>

              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Value :</div>
                <div className="ml-1">{evaluateData?.maliciousness?.value}</div>
              </div>
              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Score :</div>
                <div className="ml-1">{evaluateData?.maliciousness?.score}</div>
              </div>

              <div className="mt-2">
                <div className="modal__list-content--heading">Reasoning :</div>
                <div className="modal__list-content__reasoning">
                  {evaluateData?.maliciousness?.reasoning}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <h6>Harmfulness</h6>

              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Value :</div>
                <div className="ml-1">{evaluateData?.harmfulness?.value}</div>
              </div>
              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Score :</div>
                <div className="ml-1">{evaluateData?.harmfulness?.score}</div>
              </div>

              <div className="mt-2">
                <div className="modal__list-content--heading">Reasoning :</div>
                <div className="modal__list-content__reasoning">
                  {evaluateData?.harmfulness?.reasoning}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <h6>Criminality</h6>

              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Value :</div>
                <div className="ml-1">{evaluateData?.criminality?.value}</div>
              </div>
              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Score :</div>
                <div className="ml-1">{evaluateData?.criminality?.score}</div>
              </div>

              <div className="mt-2">
                <div className="modal__list-content--heading">Reasoning :</div>
                <div className="modal__list-content__reasoning">
                  {evaluateData?.criminality?.reasoning}
                </div>
              </div>
            </div>
          </div>
        ) : dataDetShow ? (
          <div>
            {dataList &&
              dataList?.length > 0 &&
              dataList?.map((item, id) => {
                return (
                  <div key={id} className="mb-3">
                    <div className="d-flex mb-1">
                      <p className="font600 width10">Tool :</p>
                      <div className="ml-2 width90">{item?.tool}</div>
                    </div>

                    <div className="d-flex mb-1">
                      <p className="font600 width10">Input :</p>
                      <div className="ml-2 width90">{item?.tool_input}</div>
                    </div>

                    <div className="d-flex mb-1">
                      <p className="font600 width10">Log :</p>
                      <div className="ml-2 width90">{item?.log}</div>
                    </div>

                    <div className="d-flex mb-1">
                      <p className="font600 width10">Type :</p>
                      <div className="ml-2 width90">{item?.type}</div>
                    </div>

                    <div className="d-flex mb-1">
                      <p className="font600 width10">Output :</p>
                      <div className="ml-2 width90">{item?.output}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <p>
            {content}{" "}
            <span className="modal__list-content__title">
              {listitems && listitems}
            </span>
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <CommonBtn title={onCancelTitle} onClick={onCancel} className="mr-2" />
        {confirmHide && (
          <CommonBtn onClick={onConfirm} title={onConfirmTitle} />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CommonModal;
