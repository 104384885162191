// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal__list-content {
  word-wrap: break-word;
}
.modal__list-content--height {
  height: 400px;
  overflow: scroll;
}
.modal__list-content--heading {
  font-weight: 500;
  color: #27437b;
}
.modal__list-content__reasoning {
  margin-top: 5px;
  font-size: 14px;
  line-height: 20px;
}
.modal__list-content__title {
  color: #dc3545;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/common/commonModal/styles.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AACE;EACE,aAAA;EACA,gBAAA;AACJ;AAEE;EACE,gBAAA;EACA,cAAA;AAAJ;AAEE;EACE,eAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AAEE;EACE,cAAA;EACA,gBAAA;AAAJ","sourcesContent":[".modal__list-content {\n  word-wrap: break-word;\n\n  &--height {\n    height: 400px;\n    overflow: scroll;\n  }\n\n  &--heading {\n    font-weight: 500;\n    color: #27437b;\n  }\n  &__reasoning {\n    margin-top: 5px;\n    font-size: 14px;\n    line-height: 20px;\n  }\n  &__title {\n    color: #dc3545;\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
