import React, { useContext, useState, useRef, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { MyContext } from "../../context/ContextApi";
import { claimsTagging } from "../../api/Postaction";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ClaimsTagging = () => {
  const {
    selectedFileUrls,
    setClaimsDataSrc,
    setCurrPageClaims,
    selectedFile,
    newquery,
  } = useContext(MyContext);
  const [, setSelectedFile] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);
  const [, setClaimsData] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageHeights, setPageHeights] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const userName = sessionStorage.getItem("email");
  const fileInputRef = useRef(null);
  // const pdfContainerRef = useRef(null);

  useEffect(() => {
    setDocumentUrl('');
    setClaimsData('');
    setClaimsDataSrc([]);
  }, [newquery])

  const handleBrowseFilesClick = () => {
    fileInputRef.current.click();
  };

  // console.info({ selectedFile });

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
    }
    const body = {
      email: userName,
      pdf_path: selectedFileUrls,
    };

    try {
      let response = await claimsTagging(body, file);
      // console.info({ response });

      // setDocumentUrl((prev) => ({
      //   ...prev, // Spread the previous state to keep all other properties
      //   selectedFileUrls: response?.highlighted_url[0], // Update the selectedFileUrls property
      // }));

      // setDocumentUrl((prev) => {
      //   console.info({
      //     prev,
      //     selectedFileUrls,
      //     res: response?.highlighted_url[0],
      //   });
      //   return {
      //     ...prev, // Keep all other properties in the state unchanged
      //     selectedFileUrls: response?.highlighted_url[0], // Update the selectedFileUrls with the new highlighted URL
      //   };
      // });
      const updatedUrl = { ...documentUrl };

      updatedUrl[selectedFile?.id] = response?.highlighted_url[0];

      // console.info({ updatedUrl });
      setDocumentUrl(updatedUrl);

      setClaimsData(response?.table_tags);
      setClaimsDataSrc(response?.table_tags);
    } catch (error) {
      console.log(error);
    } finally {
      fileInputRef.current.value = null;
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageHeights(Array(numPages).fill(0));
  };

  const onPageRenderSuccess = (pageNumber, { height }) => {
    setPageHeights((prevHeights) => {
      const newHeights = [...prevHeights];
      newHeights[pageNumber - 1] = height;
      return newHeights;
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // console.log('coming');
      const pdfContainer = document.getElementById("pdf-container-claims");
      if (!pdfContainer) return;
      const scrollTop = pdfContainer.scrollTop;
      let currentPage = 1;
      let currentPageTop = 0;

      for (let i = 0; i < pageHeights.length; i++) {
        currentPageTop += pageHeights[i];
        if (currentPageTop > scrollTop) {
          currentPage = i + 1;
          break;
        }
      }

      setCurrentPage(currentPage);
      setCurrPageClaims(currentPage)
    };

    const pdfContainer = document.getElementById("pdf-container-claims");
    if (pdfContainer) {
      pdfContainer.addEventListener("scroll", handleScroll);
      return () => pdfContainer.removeEventListener("scroll", handleScroll);
    }
  }, [pageHeights, currentPage]);

  // console.info({ documentUrl });

  return (
    <div className="">
      <div className="">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <h5 className="d-flex mt-3 mb-2">Upload your Document:</h5>
            </div>
            <div className="col-12">
              <p className="d-flex uploadText">Upload your document</p>
              <div className="card contentCardInMlt">
                <div className="col-12">
                  <div className="row">
                    <div className="col-1">
                      <CloudUploadOutlinedIcon
                        fontSize="large"
                        color="primary"
                      />
                    </div>
                    <div className="col-8">
                      <h6 className="dragText">Drag and Drop document here</h6>
                      <p className="limitText">File should be xlsx or Pdf format</p>
                    </div>
                    <div className="col-3">
                      <button
                        className="btn-sm btn-light btnLight"
                        type="button"
                        onClick={handleBrowseFilesClick}
                      >
                        Browse files
                      </button>
                      <input
                        id="fileInput"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                        accept={".xlsx, .pdf"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Object.keys(documentUrl || {}).length !== 0 &&
          documentUrl[selectedFile?.id] && (
            <div>
              <div
                id="pdf-container-claims"
                // ref={pdfContainerRef}
                className="pdf-container"
                style={{height: '500px', width:'650px'}}
              >
                <Document
                  file={documentUrl[selectedFile?.id]}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={console.error}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      onRenderSuccess={(info) =>
                        onPageRenderSuccess(index + 1, info)
                      }
                    />
                  ))}
                </Document>
              </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimsTagging;
