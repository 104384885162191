// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-input {
  width: 100%;
  border-radius: 25px;
  border: 1px solid #888b8d;
  padding: 10px 15px 10px 15px;
}

.input-search-btn {
  width: 100%;
  position: relative;
}
.input-search-btn__icon {
  position: absolute;
  right: -11px;
  top: 4px;
  background-color: #ea346f;
  color: white;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.width40 {
  width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/common/commonInput/styles.scss","webpack://./src/scss/_colors.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,mBAAA;EACA,yBAAA;EACA,4BAAA;AAFF;;AAIA;EACE,WAAA;EACA,kBAAA;AADF;AAEE;EACE,kBAAA;EACA,YAAA;EACA,QAAA;EACA,yBCFQ;EDGR,YAAA;EACA,aAAA;EACA,kBAAA;EEZF,aAAA;EAGE,mBFUuB;EENvB,uBFM+B;EAC/B,eAAA;AAEJ;;AACA;EACE,UAAA;AAEF","sourcesContent":["@import \"src/scss/colors\";\n@import \"src/scss/variables\";\n\n.search-input {\n  width: 100%;\n  border-radius: 25px;\n  border: 1px solid $lightgreen;\n  padding: 10px 15px 10px 15px;\n}\n.input-search-btn {\n  width: 100%;\n  position: relative;\n  &__icon {\n    position: absolute;\n    right: -11px;\n    top: 4px;\n    background-color: $btnred_6f;\n    color: white;\n    padding: 10px;\n    border-radius: 50%;\n    @include flexContainer(center, center);\n    cursor: pointer;\n  }\n}\n.width40 {\n  width: 40%;\n}\n","$black: #000000;\n$blacshadow: #00000061;\n$lightblack: #f2e0bd26;\n$lightgreen: #888b8d;\n$gray_46: #464646;\n$gray7c: #7c7c7c;\n$graycc: #cccccc;\n$grayc1: #c1c1c1;\n$white: #ffffff;\n$grayf5: #f5f5f5;\n$bgwhite_f8: #f8f8f8;\n$bgwhite_f7: #f7f7f7;\n$primary: #4c85f7;\n$blue_b7: #035eb7;\n$btnred_6f: #ea346f;\n$orange65: #ffc965;\n$orangea8: #feffa8;\n","// mixins\n@mixin widthHeight($width, $height) {\n  width: #{$width}px;\n  height: #{$height}px;\n}\n\n@mixin flexContainer($align-items: null, $justify-content: null) {\n  display: flex;\n\n  @if $align-items != null {\n    align-items: $align-items;\n  }\n\n  @if $justify-content != null {\n    justify-content: $justify-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
