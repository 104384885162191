import React from "react";
import "./Help.css";
import {
  Logo,
  Login,
  Datasearch,
  featureSwitch,
  newsearch,
  Datasearchchat,
  option,
  audioShare,
  send_mail,
  delete_icon,
  session,
  clear_history,
  generate_kpi,
  select_kpi,
  gen_insights_document_search,
  upload_icon,
  upload_window,
  collection_swtich,
  see_files_button,
  see_files_window,
  dashboard_window,
  dropdown_icon,
  admin_option,
  user_details_window,
  add_user_icon,
  add_user_window,
  user_details_action,
  edit_icon,
  persona_details,
  create_persona_icon,
  add_persona_window,
  collection_window,
  create_collection_icon,
  add_collection_window,
  schedule_window,
  logout,
} from "../../utils/images";

function ReadmeViewer() {
  return (
    <div className="list">
      <div className="d-flex align-items-center">
        <img src={Logo} alt="aglilisium logo" />
        <h3 className="bluecolor">Gen_inisights</h3>
      </div>
      <h4 className="bluecolor mt-3">Overview</h4>
      <p>
        Gen-insights is a web application that generates extensive data
        summaries from a variety of data sources. In short, it allows easy
        access to data for those who lack a thorough understanding of coding to
        gain information from the data.
      </p>
      <h4 className="bluecolor mt-3">Aspects Of Application</h4>
      <ul>
        <li>Data Lens</li>
        <li>Document Search</li>
        <li>Dashboard Search</li>
      </ul>
      <h4 className="bluecolor mt-3">User Guide</h4>

      <h4 className="redcolor mt-3">Log-in page</h4>
      <ul className="mt-2">
        <li>
          When you enter the IP address, this is the first page that appears.
          Enter the required information to log into the platform. To save the
          credentials, use the <span className="redcolor">"Remember Me" </span>
          feature. If you were unable to log in, you can reset your credentials
          by selecting the <span className="redcolor">
            "forgot password"
          </span>{" "}
          option.{" "}
        </li>
      </ul>
      <img src={Login} alt="Login" style={{ marginLeft: "20%" }} />
      <h4 className="bluecolor mt-4">Data Lens</h4>
      <img
        src={Datasearch}
        alt="Login"
        style={{ marginLeft: "25%" }}
        className="mt-3"
      />
      <ul className="mt-3">
        <li>
          When logged in, the <span className="redcolor">"Data Lens"</span> page
          opens by default. By clicking the menu in the top right corner, you
          can change from document to{" "}
          <span className="redcolor">“Dashboard search”</span> or{" "}
          <span className="redcolor">“Document Search”</span>.
        </li>
      </ul>
      <img
        src={featureSwitch}
        alt="Login"
        style={{ marginLeft: "45%" }}
        className="mt-4"
      />
      <ul className="mt-3">
        <li>
          Before prompting datasearch, a session must be chosen. Please create a
          session using the left pane if you haven't already (before prompting)
          by clicking on <img src={newsearch} alt="newsearch" />. If you prompt
          without choosing an existing session, you will be requested to start a{" "}
          <span className="redcolor">”new session“</span>. So if you want to
          continue prompting in the{" "}
          <span className="redcolor">”current session“</span>, be sure to choose
          a session beforehand.
        </li>
        <li className="mt-3">
          The output screen will look like the one below when prompted.
        </li>
      </ul>
      <img
        src={Datasearchchat}
        alt="data_Search_chat"
        style={{ marginLeft: "25%" }}
        className="mt-2"
      />
      <ul className="mt-3">
        <li>
          Along with the outcome, the audio for the AI output will be generated.
          By clicking on the kebab menu <img src={option} alt="menu" />, you may
          <span className="bluecolor"> adjust the playing speed</span> and{" "}
          <span className="bluecolor">save </span>
          the audio.
        </li>
        <li className="mt-3">
          By clicking the "<span className="redcolor">share</span>" icon, you
          can optionally share the audio with Outlook and Teams.
        </li>
      </ul>
      <img
        src={audioShare}
        alt="audioShare"
        style={{ marginLeft: "40%" }}
        className="mt-2"
      />
      <h5 className="bluecolor mt-4">Sharing Via Outlook</h5>
      <p>
        The screen will appear as follows if you select the Outlook option to
        share the audio.
      </p>
      <img
        src={send_mail}
        alt="send_mail"
        style={{ marginLeft: "25%" }}
        className="mt-3"
      />
      <ul>
        <li>In the Note textbox, type the email's body.</li>
        <li>
          In the text box labelled{" "}
          <span className="redcolor">"Recipients"</span>, enter the recipient's
          email address (multiple recipients may be added as comma separated
          values).
        </li>
        <li>
          To send an email, click the send button. Once the email has been sent,
          you will be informed.
        </li>
      </ul>
      <h5 className="bluecolor mt-4">
        Switching Sessions and Clearing History:
      </h5>
      <ul>
        <li>
          By selecting a different session from the left pane, you can swap
          between them.
        </li>
        <li>
          Hover your cursor over a session name to reveal the{" "}
          <span className="redcolor">“delete”</span> icon{" "}
          <img src={delete_icon} alt="Delete" />
          .For the session to be deleted, click it.
        </li>
      </ul>
      <img
        src={session}
        alt="Delete"
        style={{ marginLeft: "40%" }}
        className="mt-3 mb-3"
      />
      <ul>
        <li>
          By selecting the <img src={clear_history} alt="Clear History" />
          symbol in the bottom left pane, you can wipe all of your history.
        </li>
      </ul>
      <h5 className="bluecolor mt-4">Generating KPI</h5>
      <ul>
        <li>
          Click on the&ensp;
          <img src={generate_kpi} alt="generate kpi" />
          &ensp;icon in the top right area to generate a KPI.
        </li>
        <li>After clicking, the following screen will appear.</li>
      </ul>
      <img src={select_kpi} alt="select kpi" style={{ marginLeft: "30%" }} />
      <ul>
        <li>From the select KPI(s) option, you may choose the KPIs.</li>
        <li>
          Type the recipients' mail id and press{" "}
          <span className="redcolor">"Send Mail"</span>(You can input
          comma-separated values for multiple recipients' email addresses.)
        </li>
        <li>Once the mail has been sent, a prompt will appear.</li>
      </ul>
      <h4 className="bluecolor mt-4">Document search</h4>
      <img
        src={gen_insights_document_search}
        alt="gen_insights_document_search"
        style={{ marginLeft: "30%" }}
      />
      <ul>
        <li>It helps to look up the solution in the uploaded document.</li>
        <li>
          The uploaded documents are kept in several collections (which have a
          <span className="bluecolor"> folder-like structure</span>).
        </li>
      </ul>
      <h5 className="bluecolor mt-4">Performing a Document Search</h5>
      <ul>
        <li>
          A <span className="redcolor">“collection”</span> must be manually
          selected before uploading a file.
        </li>
        <li>
          Similar to Datasearch, a session must first be “
          <span className="redcolor">created”</span> or “
          <span className="redcolor">selected</span>” in order to ask a
          question.
        </li>
        <li>That particular collection will hold the uploaded file.</li>
      </ul>
      <h5 className="bluecolor mt-4">Uploading A New File</h5>
      <img src={upload_icon} alt="Uploading" style={{ marginLeft: "30%" }} />
      <ul className="mt-3">
        <li>
          For the purpose of adding a new document to the database, click the
          highlighted icon
        </li>
        <li>After clicking, the following screen will appear.</li>
      </ul>
      <img
        src={upload_window}
        alt={upload_window}
        style={{ marginLeft: "30%" }}
      />
      <ul className="mt-3">
        <li>
          You can upload the file from{" "}
          <span className="bluecolor">local storage</span> or input the{" "}
          <span className="bluecolor">Google PDF link.</span>
        </li>
        <li>
          Click the "<span className="redcolor">Save</span>" button after
          uploading.
        </li>
        <li>
          Choose the <span className="bluecolor">collection</span> where you
          want to upload your preferred document.
        </li>
      </ul>
      <img
        src={collection_swtich}
        alt={collection_swtich}
        style={{ marginLeft: "35%" }}
      />
      <h5 className="bluecolor mt-4">Viewing Files</h5>
      <ul>
        <li>
          To view the files stored in the collection, click on the{" "}
          <img src={see_files_button} alt="see_files" />
          icon from the left pane.
        </li>
        <li>After clicking, your screen will appear as follows:</li>
      </ul>
      <img
        src={see_files_window}
        alt={see_files_window}
        style={{ marginLeft: "25%" }}
      />
      <ul className="mt-3">
        <li>
          Start a new session or choose an existing one to conduct your queries.
        </li>
        <li>
          Sessions that are generated{" "}
          <span className="bluecolor">within a collection</span> are{" "}
          <span className="bluecolor">part of that collection.</span>
        </li>
        <li>
          Sessions from that specific collection will always be available in the
          left pane whenever you{" "}
          <span className="bluecolor">switch collections</span>.
        </li>
      </ul>
      <h4 className="bluecolor mt-4">Dashboard Search</h4>
      <p>
        Utilised from the dashboard, it serves as a summary and a place to pose
        interactive queries.
      </p>
      <img
        src={dashboard_window}
        alt="dashboard"
        style={{ marginLeft: "25%" }}
        className="mt-4"
      />
      <ul className="mt-3">
        <li>
          You must first “<span className="redcolor">create</span>” or “
          <span className="redcolor">choose a session”</span> before choosing a
          region or dashboard.
        </li>
        <li>
          Once a selection has been made, click the “
          <span className="redcolor">Summarize</span>” button to get the
          dashboard's summary.
        </li>
        <li>
          Using the <span className="bluecolor">query field</span> below, you
          may also ask specific questions about the dashboard.
        </li>
        <li>
          Before engaging with a dashboard, be{" "}
          <span className="bluecolor">sure to choose one</span>.
        </li>
        <li>
          To clear the region/dashboard selection, click the “
          <span className="redcolor">clear</span>” button.
        </li>
        <li>
          By clicking <img src={dropdown_icon} alt="dropdown_icon" />
          &ensp;he button above, you can minimise the summarise box so that you
          are able to view the dashboard search chats clearly.
        </li>
      </ul>
      <h4 className="redcolor mt-4">Admin Liberties</h4>
      <p>
        Admin has the previlege to add/delete/modify the user details,persona
        and collection and Scheduling KPIs
      </p>
      <img
        src={admin_option}
        alt="admin"
        style={{ marginLeft: "25%" }}
        className="mt-4"
      />
      <h4 className="bluecolor mt-4">User details</h4>
      <ul>
        <li>
          It displays information about people who have permission to use
          gen-insights. From this page, you can{" "}
          <span className="bluecolor">add, update, or delete users.</span>
        </li>
      </ul>
      <img
        src={user_details_window}
        alt="user_details_window"
        style={{ marginLeft: "20%" }}
      />
      <ul className="mt-3">
        <li>
          To add a new user, use the{" "}
          <span className="bluecolor">add user icon</span> in the top right
          corner <img src={add_user_icon} alt="add_user_icon" />
        </li>
        <li>After clicking, the following screen will appear.</li>
      </ul>
      <img
        src={add_user_window}
        alt="add_user_icon"
        style={{ marginLeft: "35%" }}
      />
      <ul className="mt-3">
        {" "}
        <li>
          Add the user by providing the required information and clicking the “
          <span className="redcolor">save</span>” button.(That particular user's
          login information will be their{" "}
          <span className="bluecolor">email</span> and{" "}
          <span className="bluecolor">password</span>).
        </li>
      </ul>
      <h5 className="bluecolor mt-4">Edit and delete user:</h5>
      <img
        src={user_details_action}
        alt="user_details_action"
        style={{ marginLeft: "25%" }}
        className="mt-3"
      />
      <ul className="mt-4">
        <li>
          To modify a user's information, click on the “
          <span className="redcolor">edit</span>” icon{" "}
          <img src={edit_icon} alt="edit_icon" />
        </li>
        <li className="mt-2">
          To remove the user from the database, click the “
          <span className="redcolor">delete</span>” icon{" "}
          <img src={delete_icon} alt="delete_icon" />{" "}
        </li>
      </ul>
      <h4 className="bluecolor mt-4">Persona Specifics:</h4>
      <img
        src={persona_details}
        alt="persona_details"
        style={{ marginLeft: "20%" }}
      />
      <ul className="mt-2">
        <li>
          It is used to establish personas and specify the tables and kpi they
          may access.
        </li>
      </ul>
      <h5 className="bluecolor mt-4">Persona Creation:</h5>
      <ul>
        <li>
          To create a persona, select the “
          <span className="redcolor">create persona</span>”{" "}
          <img src={create_persona_icon} alt="create_persona_icon" /> icon in
          the top right corner, much like with user details.
        </li>
      </ul>
      <img
        src={add_persona_window}
        alt="add_persona_window"
        style={{ marginLeft: "30%" }}
        className="mt-3"
      />
      <ul className="mt-3">
        <li>
          To add the Persona, provide the required information and press the “
          <span className="redcolor">Add</span>” button.
        </li>
      </ul>
      <h5 className="bluecolor mt-4">Editing and Deleting Personas:</h5>
      <ul className="mt-3">
        <li>
          Like <span className="bluecolor">user details</span>, To “
          <span className="redcolor">edit</span>” and “{" "}
          <span className="redcolor">delete</span>” a persona, respectively,
          click on the edit icon <img src={edit_icon} alt="edit_icon" /> and
          delete icons <img src={delete_icon} alt="delete_icon" /> next to each
          persona.
        </li>
      </ul>
      <h4 className="bluecolor mt-4">Collection:</h4>
      <ul className="mt-3">
        <li>
          To store the document for document search, you can establish a
          collection using this screen.
        </li>
        <li>
          A <span className="bluecolor">s3 folder</span> is assigned to each
          collection. So, when building a collection, provide the{" "}
          <span className="bluecolor">s3 bucket</span> and{" "}
          <span className="bluecolor">file key</span>.
        </li>
      </ul>
      <img
        src={collection_window}
        alt="collection_window"
        style={{ marginLeft: "25%" }}
        className="mt-3"
      />
      <h5 className="bluecolor mt-4">Create collection</h5>
      <ul className="mt-3">
        <li>
          To create a collection, click the icon named "
          <span className="redcolor">Create Collection</span>"{" "}
          <img src={create_collection_icon} alt="create_collection_icon" /> in
          the top right corner.
        </li>
        <li>After clicking, the following screen will appear</li>
      </ul>
      <img
        src={add_collection_window}
        alt="add_collection_window"
        style={{ marginLeft: "35%" }}
        className="mt-3"
      />
      <ul className="mt-3">
        <li>
          To add the Collection, provide the required information and click the
          “<span className="redcolor">Add</span>” button.
        </li>
      </ul>
      <h5 className="bluecolor mt-4">Editing and Deleting Collections</h5>
      <ul className="mt-2">
        <li>
          Like <span className="bluecolor">user details</span>, To “
          <span className="redcolor">edit</span>” and “
          <span className="redcolor">delete</span>” a Collection, respectively,
          click on the edit icon <img src={edit_icon} alt="edit_icon" /> and
          delete icons <img src={delete_icon} alt="delete_icon" /> next to each
          collection.
        </li>
      </ul>
      <h4 className="bluecolor mt-4">Scheduling KPIs:</h4>
      <ul>
        <li>
          This screen is used to{" "}
          <span className="bluecolor">schedule a job for a kpi summary</span>{" "}
          (people who are associated with the chosen persona will receive the
          summary at the time scheduled).
        </li>
      </ul>
      <img
        src={schedule_window}
        alt="schedule_window"
        style={{ marginLeft: "35%" }}
        className="mt-3"
      />
      <ul className="mt-4">
        <li>
          Give the required information, then select “
          <span className="redcolor">Assign Cron Job</span>" to create the
          schedule.
        </li>
      </ul>
      <h4 className="redcolor mt-4">Log-out</h4>
      <img
        src={logout}
        alt="logout"
        style={{ marginLeft: "35%" }}
        className="mt-3"
      />
      <ul className="mt-3">
        <li>The dropdown menu next to the username has a logout option.</li>
        <li>For an account logout, click on it.</li>
      </ul>
    </div>
  );
}

export default ReadmeViewer;
