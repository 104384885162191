// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalconent {
  display: flex;
  flex-direction: column;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
}

.viewSource {
  font-size: 0.8rem;
  text-decoration: underline;
  cursor: pointer;
  color: #4c85f7;
}
`, "",{"version":3,"sources":["webpack://./src/components/comDataDetective/ComDataDetective.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,0BAA0B;EAC1B,eAAe;EACf,cAAc;AAChB","sourcesContent":[".modalconent {\n  display: flex;\n  flex-direction: column;\n  /* flex-direction: row; */\n  justify-content: center;\n  align-items: center;\n}\n\n.viewSource {\n  font-size: 0.8rem;\n  text-decoration: underline;\n  cursor: pointer;\n  color: #4c85f7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
