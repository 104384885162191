import React, { useEffect, useState } from "react";
import "./schedule.css";
import axios from "axios";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { getUrl } from "../../api/Endpoint";
import { schedulerday } from "../../validation";
import Select from "react-select";
import { toast } from "react-toastify";
import { personaUser, scheduler } from "../../api/Postaction";
import axiosApi from "../../interceptors/axiosinterceptor";

const Schedule = () => {
  const today = new Date().toISOString().split("T")[0];

  const [recurrence, setRecurrence] = useState(null);

  const [selectedDay, setSelectedDay] = useState(null);

  const [selectedPersona, setSelectedPersona] = useState(null);
  const [noOptionmessage, setNoOptionmessage] = useState("Loaidng...");

  const [tableOptions, setTableOptions] = useState([]);
  const [PersonaOptions, setPersonaOptions] = useState([]);
  // const [kpi]
  const [data, setData] = useState([]);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    unregister,
    setValue,
  } = useForm({
    resolver: zodResolver(schedulerday),
    mode: "all",
  });

  // const PersonaOptions = [
  //   { value: "WLM", label: "WLM" },
  //   { value: "admin", label: "admin" },
  //   { value: "RMG", label: "RMG" },
  // ];

  const recurrencesOptions = [
    { value: "Every Day", label: "Every Day" },
    { value: "Every Hour", label: "Every Hour" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Specific day", label: "Specific day" },
  ];

  const dayOptions = [
    { value: "MON", label: "Monday" },
    { value: "TUE", label: "Tuesday" },
    { value: "WED", label: "Wednesday" },
    { value: "THU", label: "Thursday" },
    { value: "FRI", label: "Friday" },
    { value: "SAT", label: "Saturday" },
    { value: "SUN", label: "Sunday" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await axiosApi.get(`${getUrl.persona}`);
        let a = res.data;
        setData(a);
        let result = a.map((e) => ({
          value: e.persona_name,
          label: e.persona_name,
        }));
        setPersonaOptions(result);
      } catch (err) {
        setNoOptionmessage("No Data");
        console.log("Error in fetching Persona", err);
      }
    };

    fetchData();
  }, []);

  const [selectedDate, setSelectedDate] = useState();
  // new Date().toISOString().substr(0, 10FFFF)
  const [selectedTime, setSelectedTime] = useState();
  const [selectedendTime, setSelectedendTime] = useState();
  // new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })

  const formatDate = (date) => {
    const parsedDate = new Date(date);
    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };
  const handleendTimeChange = (event) => {
    setSelectedendTime(event.target.value);
  };

  const Persona = useController({
    name: "Persona",
    control: control,
  });

  const handlePersonaChange = (event) => {
    setSelectedPersona(event);
    Persona.field.onChange(event.value);
  };

  const Recurrence = useController({
    name: "Recurrence",
    control: control,
  });
  const SelectDay = useController({
    name: "SelectDay",
    control: control,
  });

  const handleDayChange = (event) => {
    SelectDay.field.onChange(event.value);
    setSelectedDay(event);
  };

  const handleRecurrenceChange = (event) => {
    setRecurrence(event);
    Recurrence.field.onChange(event.value);
    if (event.value === "Every Day") {
      setValue("SelectDay", "");
      // unregister("SelectDay");
      unregister("Endtime");
      unregister("Date");
    }
    if (event.value === "Every Hour") {
      setValue("SelectDay", "");
      // unregister("SelectDay");
    }
    if (event.value === "Weekly") {
      SelectDay.field.onChange(null);
      // register("SelectDay");
      unregister("Endtime");
      unregister("Date");
    }
    if (event.value === "Monthly" || event.value === "Specific day") {
      setValue("SelectDay", "");
      // unregister("SelectDay");
      unregister("Endtime");
    }
  };

  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const currentTime = `${hours}:${minutes}`;

  const onSubmit = async (value, e) => {
    console.log("ScheduleForm Submitted:", value);
    let tables = data.filter((e) => e.persona_name === selectedPersona.value);
    let tableList = tables[0].tables;
    let kpiList = tables[0].kpi;
    let recepientsList = await personaUser(selectedPersona.value);
    console.log("recipientsList", recepientsList);
    setSelectedPersona([]);
    setSelectedDay([]);
    setRecurrence([]);
    setSelectedTime();
    setSelectedDate();
    // alert("Schedule");
    let body;
    if (recurrence.value === "Monthly" || recurrence.value === "Specific day") {
      body = {
        persona: selectedPersona.value,
        kpi: kpiList,
        recepients: recepientsList,
        recurrence: recurrence.value,
        tables: tableList,
        date: formatDate(value.Date),
        start_time: value.Time,
      };
    }
    if (recurrence.value === "Every Hour") {
      body = {
        persona: selectedPersona.value,
        kpi: kpiList,
        recepients: recepientsList,
        recurrence: recurrence.value,
        tables: tableList,
        date: formatDate(value.Date),
        start_time: value.Time,
        end_time: value.Endtime,
      };
    }
    if (recurrence.value === "Every Day") {
      body = {
        persona: selectedPersona.value,
        kpi: kpiList,
        recepients: recepientsList,
        recurrence: recurrence.value,
        tables: tableList,
        start_time: value.Time,
      };
    }
    if (recurrence.value === "Weekly") {
      body = {
        persona: selectedPersona.value,
        kpi: kpiList,
        recepients: recepientsList,
        recurrence: recurrence.value,
        tables: tableList,
        day_of_week: value.SelectDay,
        start_time: value.Time,
      };
    }
    const id = toast.warning("Scheduling ....");
    await scheduler(body);
    toast.dismiss(id);
    toast.success("Scheduled !!!");
    reset();
  };

  return (
    <div className="scheduleContent">
      <div className="card">
        <div className="card-content">
          <form onSubmit={handleSubmit(onSubmit)} className="schedule-form">
            {/* <div className="input-field-schedule"> */}
            <div className="input-container-schedule">
              <label>Persona</label>
              <div style={{ width: "100%" }}>
                <Select
                  placeholder="Select Persona"
                  {...register("Persona")}
                  value={selectedPersona}
                  options={PersonaOptions}
                  onChange={handlePersonaChange}
                  noOptionsMessage={() => noOptionmessage}
                  // options={tableOptions}
                />
                {errors.Persona && (
                  <span style={{ color: "red" }}>Select an option</span>
                )}
              </div>
            </div>
            {/* </div> */}
            {/* <div className="input-field-schedule"> */}
            <div className="input-container-schedule">
              <label>Recurrence</label>
              <div style={{ width: "100%" }}>
                <Select
                  placeholder="Select Recurrence"
                  {...register("Recurrence")}
                  value={recurrence}
                  options={recurrencesOptions}
                  onChange={handleRecurrenceChange}
                />
                {errors.Recurrence && (
                  <span style={{ color: "red" }}>Select an option</span>
                )}
              </div>
            </div>
            {/* </div> */}

            {recurrence?.value !== "Every Day" &&
              recurrence?.value !== "Weekly" && (
                <div className="input-field-schedule">
                  <div className="input-container-schedule">
                    <label>Date</label>
                    <div>
                      <input
                        type="date"
                        value={selectedDate}
                        // min={today}
                        {...register("Date", {
                          // validate: (value) => {
                          //   try {
                          //     schedulerday.parse({ Date: value });
                          //     return true;
                          //   } catch (error) {
                          //     return error.message;
                          //   }
                          // },
                        })}
                        onChange={handleDateChange}
                      />
                      <div style={{ color: "red" }}>
                        {errors.Date?.message && "Select Date"}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {recurrence?.value === "Weekly" && (
              <div className="input-field-schedule">
                <div className="input-container-schedule">
                  <div>
                    <label>Select Day:</label>
                    <Select
                      placeholder="Select Day"
                      // {...register("SelectDay")}
                      value={selectedDay}
                      options={dayOptions}
                      onChange={handleDayChange}
                    />
                    {errors.SelectDay && (
                      <span style={{ color: "red" }}>Select an option</span>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="input-field-schedule">
              <div className="input-container-schedule">
                <label>Time</label>
                <div>
                  {" "}
                  <input
                    type="time"
                    step={3600}
                    {...register("Time")}
                    // min={currentTime}
                    value={selectedTime}
                    onChange={handleTimeChange}
                  />
                  <div style={{ color: "red" }}>
                    {errors.Time?.message && "Select Start Time"}
                  </div>
                </div>
              </div>
            </div>
            {recurrence?.value === "Every Hour" && (
              <>
                <div className="input-field-schedule">
                  <div className="input-container-schedule">
                    <label>End Time:</label>
                    <input
                      type="time"
                      step={3600}
                      {...register("Endtime")}
                      value={selectedendTime}
                      onChange={handleendTimeChange}
                    />
                  </div>
                </div>
                <div style={{ color: "red" }}>
                  {errors.Endtime?.message && "Select End Time"}
                </div>
              </>
            )}

            {/* <div className="input-field-schedule">
            <div className="input-container-schedule">
              <label>Data:</label>
              <input
                type="text"
                className="form-control"
                {...register("Data")}
                placeholder="Enter Data"
              />
              <div style={{ color: "red" }}>{errors.Data?.message}</div>
            </div>
          </div> */}
            <div className="signinbutton">
              <button className="Schedulesubmitbutton" type="submit">
                Assign Cron Job
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
