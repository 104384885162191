// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.line-product-expiry{
    width: 97%;
    border: 1px solid black !important;
    margin-left: 10px;
    margin-top: 50px;
    position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/Product Expiry Mitigation/productExpiry.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kCAAkC;IAClC,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".line-product-expiry{\n    width: 97%;\n    border: 1px solid black !important;\n    margin-left: 10px;\n    margin-top: 50px;\n    position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
