import React from "react";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";
import "./styles.css";
function CommonArrowCircle({ onClick, className, expand = false }) {
  let iconColor = expand ? "#EA356F " : "#9D9D9D";
  let ICON = expand ? IoIosArrowDropdownCircle : IoIosArrowDropupCircle;
  return (
    <div
      className={className}
      data-toggle="collapse"
      data-target="#collapseExample"
      onClick={onClick}
    >
      <ICON size={30} color={iconColor} />
    </div>
  );
}

export default CommonArrowCircle;
