import React from "react";
import { messageIcon1 } from "../../utils/images";
import { BiEdit } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import "./styles.scss";
import CommonLoader from "../commonLoader";
import CommonNoRecord from "../commonNoRecord";
import { RiDeleteBin6Line } from "react-icons/ri";

function SidebarSessionHistory({
  firstList,
  dataList,
  currentIndex,
  handleInputChange,
  handleToNavigateId,
  handleToSaveHandler,
  handleToCloseHandler,
  handleSetCurrentInx,
  handleToDeleteSession,
  activeSession = false,
}) {
  return (
    <div className="session-history">
      {firstList ? (
        <CommonLoader />
      ) : dataList?.length === 0 ? (
        <CommonNoRecord />
      ) : (
        dataList?.map((e, i) => (
          <div key={i} className="session-history__history-list mb-2">
            <img src={messageIcon1} alt="sidebar" />
            &ensp;
            <div className="session-history__input">
              {i === currentIndex ? (
                <input
                  type="text"
                  value={activeSession ? e["session name"] : dataList[i]}
                  onChange={handleInputChange}
                  className="session-history__input__border"
                />
              ) : (
                <div onClick={() => handleToNavigateId(e)}>
                  {activeSession ? e["session name"] : e}
                </div>
              )}
            </div>
            <div className="mx-2 session-history__history-list__icon">
              {i === currentIndex ? (
                <>
                  <TiTick
                    size={25}
                    color="white"
                    onClick={handleToSaveHandler}
                  />
                  <IoIosClose
                    size={25}
                    color="white"
                    onClick={handleToCloseHandler}
                  />
                </>
              ) : (
                <>
                  <BiEdit
                    size={20}
                    color="white"
                    onClick={() => handleSetCurrentInx(i, e)}
                  />
                  &ensp;
                </>
              )}

              <RiDeleteBin6Line
                size={18}
                color="white"
                onClick={() => handleToDeleteSession(e)}
              />
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default SidebarSessionHistory;
