// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.loginbutton {
    background: #ea356f;
    border: none;
    color: white;
    padding: 10px 28px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
  }
  
  .bgTransprent
  {
    background-color: transparent;
  }
  button:focus
  {
    outline: none;
  }

  .outlineBtn
  {
    padding: 10px;
    border-radius: 6px;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 0px 2px 2px rgba(228, 227, 227, 0.15);
    font-size: 0.9rem;
    height: 40px;
    background-color: transparent;
    color: #4c85f7;
    border: 1px solid #4c85f7;
    font-weight: 600;
    font-size: 12px;
  }
  .outlineBtn:hover
  {
    cursor: pointer;
  }


  .outlineBtnDisable
  {
    background-color: #eeedede0;
  }
  .outlineBtnDisable:hover
  {
    cursor: not-allowed;
  }`, "",{"version":3,"sources":["webpack://./src/common/threeDotSpinner/styles.css"],"names":[],"mappings":";;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;;IAEE,6BAA6B;EAC/B;EACA;;IAEE,aAAa;EACf;;EAEA;;IAEE,aAAa;IACb,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,qDAAqD;IACrD,iBAAiB;IACjB,YAAY;IACZ,6BAA6B;IAC7B,cAAc;IACd,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;EACjB;EACA;;IAEE,eAAe;EACjB;;;EAGA;;IAEE,2BAA2B;EAC7B;EACA;;IAEE,mBAAmB;EACrB","sourcesContent":["\n\n.loginbutton {\n    background: #ea356f;\n    border: none;\n    color: white;\n    padding: 10px 28px;\n    text-align: center;\n    font-size: 15px;\n    font-weight: 500;\n    border-radius: 5px;\n  }\n  \n  .bgTransprent\n  {\n    background-color: transparent;\n  }\n  button:focus\n  {\n    outline: none;\n  }\n\n  .outlineBtn\n  {\n    padding: 10px;\n    border-radius: 6px;\n    text-align: center;\n    cursor: pointer;\n    box-shadow: 0px 0px 2px 2px rgba(228, 227, 227, 0.15);\n    font-size: 0.9rem;\n    height: 40px;\n    background-color: transparent;\n    color: #4c85f7;\n    border: 1px solid #4c85f7;\n    font-weight: 600;\n    font-size: 12px;\n  }\n  .outlineBtn:hover\n  {\n    cursor: pointer;\n  }\n\n\n  .outlineBtnDisable\n  {\n    background-color: #eeedede0;\n  }\n  .outlineBtnDisable:hover\n  {\n    cursor: not-allowed;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
