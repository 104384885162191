import React from "react";
import CommonInput from "../commonInput";
import CommonBtn from "../commonBtn";
import "./styles.scss";

function SearchInputBtn({
  placeholder,
  value,
  onChange,
  btnTitle,
  btnOnClick,
}) {
  return (
    <div className="searchbtn-input">
      <CommonInput
        type="text"
        className="ml-3 search-input"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        searchInput
        customInputClass="width40"
      />
      <CommonBtn title={btnTitle} onClick={btnOnClick} />
    </div>
  );
}

export default SearchInputBtn;
