import React from "react";
import "./styles.scss";
// import { menuItems } from "../../utils/constants/constantData";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { showToast } from "../../utils/helpers/helpers";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ExpandMenuNav({
  handleToNavigate,
  handleClickCircleBtn,
  menuItems,
  handleToResticed,
}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
  };
  return (
    <div className="expand-menu collapse" id="collapseExample1">
      <div className="expand-menu__menu-items">
        <Slider {...settings} className="mx-1">
          {menuItems.map((item) => (
            <div
              key={item.label}
              className={`expand-menu__menu-items__menubox ${
                !item.disabled && "menu_disable"
              }`}
              data-toggle="collapse"
              data-target="#collapseExample1"
              onClick={() => {
                if (item.disabled) {
                  handleToNavigate(item);
                } else {
                  handleToResticed();
                  showToast("Not authroized");
                }
              }}
            >
              <div className="expand-menu__menu-items__menu-img ">
                <img
                  src={item.imgSrc}
                  alt={item.alt}
                  className={`expand-menu__menu-items__menu-img__img ${item.class}`}
                />
              </div>
              <p className="d-flex justify-content-center">{item.label}</p>
            </div>
          ))}
        </Slider>
      </div>
      <div
        className="mt-4 expand-menu__icon iconHover"
        data-toggle="collapse"
        data-target="#collapseExample1"
        onClick={handleClickCircleBtn}
      >
        <IoIosArrowDropupCircle size={30} color="#D0D0D0" />
      </div>
    </div>
  );
}

export default ExpandMenuNav;
