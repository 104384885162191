import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { EditPersonaaValidation } from "../../validation";
import Select from "react-select";

import { updatePersona } from "../../api/Postaction";
import { CommonBtn } from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";

const EditPersona = ({
  editmodalShow,
  setEditModalShow,
  editData,
  tableOptions,
  setFetch,
}) => {
  let data = editData;
  // console.log("Data: ", data);
  let a = data?.Tables?.split(",");
  let defaultOptions = tableOptions?.filter((item) => a?.includes(item.value));
  useEffect(() => {
    EditPersonaa.setValue("editRole", data ? data.Persona : "");
    EditPersonaa.setValue("editTableAccess", data ? defaultOptions : "");
    EditPersonaa.setValue(
      "kpi",
      data ? (data?.kpi === "Kpi not Assigned" ? "" : data?.kpi) : ""
    );
  }, [editData]);
  const EditPersonaa = useForm({
    resolver: zodResolver(EditPersonaaValidation),
    mode: "all",
  });
  const closeeditModal = () => {
    EditPersonaa.reset();
    setEditModalShow(false);
  };
  const editTableAccess = useController({
    name: "editTableAccess",
    control: EditPersonaa.control,
    defaultValue: data ? data.Tables : "",
  });
  const handletableAccess2 = (option) => {
    editTableAccess.field.onChange(option);
  };

  //   console.log("tables====>", data?.Tables?.split(","));
  const EditPersonaaData = async (formValues) => {
    EditPersonaa.reset();
    formValues.editTableAccess = formValues.editTableAccess.map((e) => e.value);
    formValues.kpi = formValues.kpi === "" ? null : formValues.kpi?.split(",");
    setEditModalShow(false);
    // let kpi =
    //   editData.kpi === "Kpi not Assigned" ? null : formValues.kpi?.split(",");
    await updatePersona(formValues, setFetch, editData.Persona);
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "100px", // Set the desired height here
      overflow: "scroll",
    }),
    // Add more styles for other components if needed
  };
  return (
    <div>
      {" "}
      <Modal
        show={editmodalShow}
        onHide={() => setEditModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        {" "}
        <form onSubmit={EditPersonaa.handleSubmit(EditPersonaaData)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit Persona
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className=" mb-1">
              Persona Name
              <span className="" style={{ color: "red" }}>
                *
              </span>{" "}
              :
            </div>
            <input
              type="text"
              className="w-100"
              {...EditPersonaa.register("editRole")}
              defaultValue={data ? data?.Persona : ""}
              //
              // value={data ? data.Persona : ""}
              style={{
                height: "38px",
                padding: "10px",
                bordserRadius: "5px",
              }}
              placeholder="Persona Name"
            />
            <div style={{ color: "red" }}>
              {EditPersonaa.formState.errors.editRole?.message}
            </div>
            <div className="mt-2 mb-1">
              Table
              <span className="" style={{ color: "red" }}>
                *
              </span>{" "}
              :
            </div>
            <Select
              isMulti
              className=""
              styles={customStyles}
              placeholder="Select Table"
              onChange={handletableAccess2}
              options={tableOptions}
              defaultValue={data ? defaultOptions : ""}
              // noOptionsMessage={() => noOptionsMessage}
            ></Select>
            <div style={{ color: "red" }}>
              {EditPersonaa.formState.errors.editTableAccess?.message ===
              "Required"
                ? "Select Table"
                : EditPersonaa.formState.errors.editTableAccess?.message}
            </div>
            <div className="mt-2 mb-1">Kpi :</div>
            <textarea
              type="text"
              className=" form-control"
              id="exampleFormControlTextarea1"
              rows="5"
              {...EditPersonaa.register("kpi")}
              // style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
              defaultValue={
                data ? (data?.kpi === "Kpi not Assigned" ? "" : data?.kpi) : ""
              }
              placeholder="Add Kpi(separated by comma)"
            />
          </Modal.Body>
          <Modal.Footer>
            <CommonBtn
              title={commonStr.cancel}
              onClick={closeeditModal}
              className="mr-2"
            />
            <CommonBtn type="submit" title={commonStr.update} />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditPersona;
