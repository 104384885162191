import React from "react";
import { arrow } from "../../utils/images";
import AudioRecorder from "../Mic/Mic";
import CommonBtn from "../commonBtn";
import { commonStr } from "../../utils/constants/commonStrings";

function SearchInputArrowBtn({
  type = "text",
  value = "",
  onKeyPress,
  onChange,
  onClick,
  inputValue,
  placeholder,
  handleRecordingData,
  handleToStopAudio,
  audioHide = true,
  handleNewChat,
  isNewChatShow = false,
  customSerchInputWrap = "",
  inputWidth = "",
  iconVisible = true,
}) {
  return (
    <div className={`query ${customSerchInputWrap}`}>
      {isNewChatShow && (
        <CommonBtn
          title={commonStr.newChat}
          className="outlineBtn mr-2 mt-3 iconHover"
          onClick={handleNewChat}
          outlineBtn
        />
      )}

      <div className={`input-container ${inputWidth}`}>
        {audioHide && (
          <div className="mic-option">
            <AudioRecorder
              onRecordingStop={handleRecordingData}
              handleToStopAudio={handleToStopAudio}
            />
          </div>
        )}

        <input
          type={type}
          className={`input-field1 ${audioHide && "paddingLeftInput"}`}
          value={value}
          placeholder={placeholder}
          onKeyPress={onKeyPress}
          onChange={onChange}
          autoFocus
        />
        {iconVisible && (
          <span
            className="icon1"
            style={{
              cursor: inputValue !== "" ? "pointer" : "not-allowed",
            }}
            onClick={onClick}
          >
            <img src={arrow} alt="searchInput" />
          </span>
        )}
      </div>
    </div>
  );
}

export default SearchInputArrowBtn;
