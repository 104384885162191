// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.loginbutton {
    background: #ea356f;
    border: none;
    color: white;
    padding: 10px 28px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    border-radius: 5px;
  }
  
  .bgTransprent
  {
    background-color: transparent;
  }
  button:focus
  {
    outline: none;
  }`, "",{"version":3,"sources":["webpack://./src/common/commonFeedback/styles.css"],"names":[],"mappings":";;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;;IAEE,6BAA6B;EAC/B;EACA;;IAEE,aAAa;EACf","sourcesContent":["\n\n.loginbutton {\n    background: #ea356f;\n    border: none;\n    color: white;\n    padding: 10px 28px;\n    text-align: center;\n    font-size: 15px;\n    font-weight: 500;\n    border-radius: 5px;\n  }\n  \n  .bgTransprent\n  {\n    background-color: transparent;\n  }\n  button:focus\n  {\n    outline: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
