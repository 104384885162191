// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video__modal-title {
  font-size: 14px;
  color: #4c85f7;
}
.video__modal-content {
  word-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/common/videoModal/styles.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,cAAA;AAAJ;AAEE;EACE,qBAAA;AAAJ","sourcesContent":[".video {\n  &__modal-title {\n    font-size: 14px;\n    color: #4c85f7;\n  }\n  &__modal-content {\n    word-wrap: break-word;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
